import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { IconButton, IconButtonProps, Theme, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props extends Omit<IconButtonProps, 'component' | 'className' | 'children'> {
  expanded: boolean;
  handleChange: (value: boolean) => void;
  tooltipText?: string;
}
const useStyles = makeStyles()((theme: Theme) => ({
  dropdownButton: {
    color: 'white',
    background: '#33444E',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '35px',
    width: '35px',
    padding: theme.spacing(0.5),
    marginTop: 'auto',
    '&:hover': {
      background: theme.palette.info.light,
    },
  },
  icon: {
    fontSize: '40px',
    '&:hover': {
      //border: "3px solid",
    },
  },
}));

const DropdownArrow = (props: Props) => {
  const { expanded, handleChange, tooltipText = '', ...rest } = props;

  const { classes } = useStyles();

  const onClick = (): void => {
    handleChange(!expanded);
  };

  return (
    <Tooltip title={`${expanded ? 'Collapse' : 'Expand'} ${tooltipText}`}>
      <IconButton
        aria-label={`${expanded ? 'Collapse' : 'Expand'} ${tooltipText}`}
        className={classes.dropdownButton}
        onClick={onClick}
        {...rest}
      >
        {expanded ? (
          <ArrowDropDownIcon className={classes.icon} />
        ) : (
          <ArrowRightIcon className={classes.icon} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default DropdownArrow;
