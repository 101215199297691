import { AccessDenied, AuthorisedRoute, ErrorRoutes } from '@airelogic/form-management/common';
import { Route } from 'react-router-dom';
import AuthRoutes from './Account/Routing';
import Switch from './ErrorHandlingSwitch';
import FormBuildingRoutes from './FormBuilding/Routing';
import { ClinicianPreview, SinglePassPreview } from './FormPreviews/Routing';
import Layout from './Layout/Layout';

const Routing = () => {
  return (
    <Switch>
      <AuthorisedRoute
        path="/ClinicianPreview/:form/:version/:viewMode?"
        can={{ I: 'preview', a: 'Form' }}
      >
        <ClinicianPreview />
      </AuthorisedRoute>
      <AuthorisedRoute path="/singlepassdemo/:form/:version" can={{ I: 'preview', a: 'Form' }}>
        <SinglePassPreview />
      </AuthorisedRoute>
      <Route>
        <Layout>
          <Switch>
            <Route path="/auth">
              <AuthRoutes />
            </Route>
            <Route path="/error">
              <ErrorRoutes />
            </Route>
            <Route path="/formbuilding">
              <FormBuildingRoutes />
            </Route>
            <Route path="/accessdenied">
              <AccessDenied />
            </Route>
            <Route path="/">{/* Just so we dont flash page not found */}</Route>
          </Switch>
        </Layout>
      </Route>
    </Switch>
  );
};

export default Routing;
