import { z } from 'zod';
import { TextInputIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = ['string'];

export const validationSchema = z.object({ translatable: z.boolean() });

export type AdditionalSettings = z.infer<typeof validationSchema>;

export const textControl: ControlDefinition<AdditionalSettings> = {
  displayName: 'Text Field',
  icon: TextInputIcon,
  type: 'TEXT_FIELD',
  supportedDataTypes: dataTypes,
  supportsInitialValue: true,
  additionalSettings: {
    validationSchema,
    defaults: {
      translatable: false,
    },
  },
};
