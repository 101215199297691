import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton, { IconButtonProps } from './IconButton';

const SmallMenuIcon = (props: Omit<IconButtonProps, 'component' | 'className' | 'children'>) => {
  return (
    <IconButton {...props}>
      <MoreVertIcon />
    </IconButton>
  );
};

export default SmallMenuIcon;
