import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

const ScrollableContent = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
  return (
    <Box className={className} position={'relative'} height="100%">
      <Box
        position={'absolute'}
        height="100%"
        top={0}
        bottom={0}
        left={0}
        right={0}
        overflow={'auto'}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ScrollableContent;
