import { useEffect, useMemo, useState } from 'react';
import { FieldArrayWithId, useFormContext, useFormState } from 'react-hook-form';
import { useControlSettingsContext } from '../../ControlSettingsContext';
import { getControlDefinitionByType } from '../../Controls/Controls';
import { FormValues } from '../../Form';
import { selectSelectedControl } from '../../StateManagement/Selectors/ControlSelector';
import { useAppDispatch, useAppSelector } from '../../StateManagement/hooks';
import { clearNavigateToErrors } from '../../StateManagement/layoutSlice';
import HeaderTabs, { TabKeys } from '../HeaderTabs';
import ControlName from './ControlName';
import TabResolver from './TabResolver';

const getKeyAndIndex = (
  fields: FieldArrayWithId<FormValues, 'controlSettings', 'key'>[],
  controlId: string,
) => {
  for (let index = 0; index < fields.length; index++) {
    if (fields[index].id === controlId) {
      return {
        index,
        key: fields[index].key,
      };
    }
  }

  return undefined;
};

interface Props {
  fieldIndex: number;
  navigateToError: boolean;
  selectedTab: TabKeys;
  updateSelectedTab: (tab: TabKeys) => void;
}

const Settings = ({ fieldIndex, navigateToError, selectedTab, updateSelectedTab }: Props) => {
  const { control, getValues } = useFormContext<FormValues>();
  const { errors } = useFormState({ control });
  const dispatch = useAppDispatch();

  const controlDefinition = getControlDefinitionByType(
    getValues(`controlSettings.${fieldIndex}.type`),
  );
  const hasAdditionalSettings = !!controlDefinition.additionalSettings;

  const controlErrors = errors?.controlSettings?.[fieldIndex];

  const tabErrors = useMemo(() => new Map<TabKeys, boolean>(), []);
  tabErrors.set('basicSettings', controlErrors?.basicSettings !== undefined);
  tabErrors.set('validation', controlErrors?.validationSettings !== undefined);
  tabErrors.set('formula', controlErrors?.formulaSettings !== undefined);
  tabErrors.set('additionalSettings', controlErrors?.additionalSettings !== undefined);

  useEffect(() => {
    if (navigateToError) {
      for (const [key, value] of tabErrors) {
        if (value) {
          updateSelectedTab(key);
          break;
        }
      }
      dispatch(clearNavigateToErrors());
    }
  }, [dispatch, navigateToError, tabErrors, updateSelectedTab]);

  return (
    <>
      <HeaderTabs
        onTabChange={updateSelectedTab}
        selectedTab={selectedTab}
        displayValidationTab={true}
        displayAdditionalSettingsTab={hasAdditionalSettings}
        tabErrors={tabErrors}
      />
      <div>
        <ControlName fieldIndex={fieldIndex} />
        <TabResolver fieldIndex={fieldIndex} tab={selectedTab} />
      </div>
    </>
  );
};

const ControlSettingsPane = () => {
  const selectedControl = useAppSelector(selectSelectedControl);
  const controlSettings = useControlSettingsContext();
  const [selectedTab, setSelectedTab] = useState<TabKeys>('basicSettings');

  if (selectedControl === undefined) {
    return null;
  }

  const keyAndIndex = getKeyAndIndex(controlSettings, selectedControl.id);

  if (keyAndIndex === undefined) {
    return null;
  }

  const updateSelectedTab = (key: TabKeys) => setSelectedTab(key);

  return (
    <Settings
      key={keyAndIndex.key}
      fieldIndex={keyAndIndex.index}
      {...selectedControl}
      selectedTab={selectedTab}
      updateSelectedTab={updateSelectedTab}
    />
  );
};

export default ControlSettingsPane;
