import { DateTimeIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = ['time'];

export const timeControl: ControlDefinition = {
  displayName: 'Time',
  icon: DateTimeIcon,
  type: 'TIME_FIELD',
  supportedDataTypes: dataTypes,
  supportsInitialValue: true,
};
