import { ConfirmationDialog, StyledButton, When } from '@airelogic/form-management/components';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DataType, FormulaConstraint } from '../../../Controls/DefaultSettings';
import { IConstraintOption } from '../../../Controls/types';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';
import Constraint from './Constraint';
import ConstraintOptionsResolver from './ConstraintOptionsResolver';

interface Props {
  fieldIndex: number;
}

const Constraints = ({ fieldIndex }: Props) => {
  const { watch, control, setValue } = useFormContext<FormValues>();

  const dataType: DataType = watch(
    `controlSettings.${fieldIndex}.validationSettings.dataTypeSettings.type`,
  );
  const constraints = watch(`controlSettings.${fieldIndex}.validationSettings.constraints`);

  const [previousDataType, setPreviousDataType] = useState(dataType);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [removeIndex, setRemoveIndex] = useState<number[]>([]);
  const [constraintOptions, setConstraintOptions] = useState<IConstraintOption[]>([]);
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: `controlSettings.${fieldIndex}.validationSettings.constraints`,
  });

  useEffect(() => {
    if (dataType !== previousDataType) {
      setRemoveIndex([]);
      const selectedConstraintOptions = ConstraintOptionsResolver(dataType);
      let revert = false;
      if (selectedConstraintOptions.length === 0) {
        constraints.forEach((field: any, index) => {
          setRemoveIndex((removeIndex) => [...removeIndex, index]);
          revert = true;
        });
      } else {
        constraints.forEach((field: any, index) => {
          const currentOption = selectedConstraintOptions.filter(
            (o: IConstraintOption) => o.type === field.type,
          );
          if (currentOption.length === 0) {
            setRemoveIndex((removeIndex) => [...removeIndex, index]);
            revert = true;
          }
        });
      }
      if (revert) {
        setShowConfirmDelete(true);
      } else {
        setPreviousDataType(dataType);
        setConstraintOptions(ConstraintOptionsResolver(dataType));
      }
    } else {
      setPreviousDataType(dataType);
      setConstraintOptions(ConstraintOptionsResolver(dataType));
    }
  }, [dataType, previousDataType, constraints, setRemoveIndex, setConstraintOptions]);

  const addConstraint = () => {
    const constraint: FormulaConstraint = {
      kind: 'custom',
      xpath: '',
      alert: '',
      useDefaultAlert: true,
    };
    append(constraint);
  };

  const deleteConstraint = (index: number) => {
    remove(index);
  };

  const confirmDelete = () => {
    remove(removeIndex);
    setShowConfirmDelete(false);
  };

  const cancelDelete = () => {
    revertFields();
    setShowConfirmDelete(false);
  };

  const revertFields = () => {
    setValue(
      `controlSettings.${fieldIndex}.validationSettings.dataTypeSettings.type`,
      previousDataType as DataType,
    );
  };

  return (
    <>
      <ConfirmationDialog
        confirmationText={'This will remove existing constraints, are you sure?'}
        open={showConfirmDelete}
        handleCancel={cancelDelete}
        handleConfirm={confirmDelete}
      />
      <When condition={constraintOptions.length > 0}>
        <PanelWithHeader title="Constraints">
          {fields.map((field, index) => {
            return (
              <div key={field.id}>
                <Constraint
                  fieldIndex={fieldIndex}
                  constraintIndex={index}
                  constraintsLength={fields.length}
                  options={constraintOptions}
                  move={move}
                  deleteConstraint={deleteConstraint}
                />
                <hr></hr>
              </div>
            );
          })}

          <StyledButton color="primary" startIcon={<AddIcon />} onClick={addConstraint}>
            Add constraint
          </StyledButton>
        </PanelWithHeader>
      </When>
    </>
  );
};

export default Constraints;
