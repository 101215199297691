import {
  MustBePositive,
  MustNotBeEmptyText,
  optionalXPathExpressionSchema,
  requiredXPathExpressionSchema,
  xmlNameSchema,
} from '@airelogic/form-management/common/validations';
import cloneDeep from 'lodash/cloneDeep';
import { z } from 'zod';
import { getControlDefinitionByType } from './Controls';
import { ControlType } from './types';

const formulaConstraint = z.object({
  kind: z.literal('custom'),
  xpath: requiredXPathExpressionSchema,
  alert: z.string(),
  useDefaultAlert: z.boolean(),
});

const limitConstraint = z.object({
  kind: z.literal('limit'),
  type: z.string(),
  alert: z.string(),
  useDefaultAlert: z.boolean(),
  limit: z.number({ invalid_type_error: MustNotBeEmptyText }).positive(MustBePositive),
});

const predefinedConstraint = z.object({
  kind: z.literal('predefined'),
  type: z.string(),
  alert: z.string(),
  useDefaultAlert: z.boolean(),
});

const constraintValidationSchema = z.discriminatedUnion('kind', [
  formulaConstraint,
  limitConstraint,
  predefinedConstraint,
]);

export const repeatedDefaultValue = z.object({
  iterationId: z.string(),
  defaultValue: z.string(),
});

const dataTypes = z.enum([
  'string',
  'boolean',
  'email',
  'integer',
  'decimal',
  'date',
  'time',
  'datetime',
  'base64Binary',
]);

export const validationSchema = z.object({
  basicSettings: z.object({
    name: xmlNameSchema(),
    originalName: xmlNameSchema(), //used for translations
    label: z.string(),
    hint: z.string(),
    showInMasterChildSummary: z.boolean(),
    helpMessage: z.string(),
    includeInExternalNotifications: z.boolean(),
    visibilityOptions: z.object({
      hideFromPdf: z.boolean(),
      hideFromRead: z.boolean(),
      hideFromEdit: z.boolean(),
    }),
    repeatableDefaultValues: z.array(repeatedDefaultValue).optional(),
    supportsInitialValue: z.boolean().optional(),
  }),
  validationSettings: z
    .object({
      defaultAlertMessage: z.object({
        useGlobalAlert: z.boolean(),
        alertMessage: z.string(),
      }),
      requiredFieldSettings: z.object({
        required: z.enum(['true()', 'false()', 'formula']),
        requiredXpath: optionalXPathExpressionSchema,
        useDefaultAlert: z.boolean(),
        alertMessage: z.string(),
      }),
      dataTypeSettings: z.object({
        type: dataTypes,
        useDefaultAlert: z.boolean(),
        alertMessage: z.string(),
      }),
      constraints: z.array(constraintValidationSchema),
    })
    .superRefine((val, ctx) => {
      if (
        val.requiredFieldSettings.required === 'formula' &&
        val.requiredFieldSettings.requiredXpath.trim() === ''
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['requiredFieldSettings', 'requiredXpath'],
          message: MustNotBeEmptyText,
        });
      }
    }),
  formulaSettings: z.object({
    calculatedValue: optionalXPathExpressionSchema,
    initialValue: optionalXPathExpressionSchema,
    visibility: optionalXPathExpressionSchema,
    readOnly: optionalXPathExpressionSchema,
  }),
});

export type DataType = z.infer<typeof dataTypes>;

export type ControlCommonSettings = z.infer<typeof validationSchema>;

export type FormulaConstraint = z.infer<typeof formulaConstraint>;
export type LimitConstraint = z.infer<typeof limitConstraint>;
export type PredefinedConstraint = z.infer<typeof predefinedConstraint>;

export type Constraints = FormulaConstraint | LimitConstraint | PredefinedConstraint;

export const controlSettingsGenerator = (type: ControlType, defaultName: string) => {
  const controlDefinition = getControlDefinitionByType(type);

  const commonOptions: ControlCommonSettings = {
    basicSettings: {
      label: '',
      hint: '',
      showInMasterChildSummary: false,
      name: defaultName,
      originalName: defaultName,
      visibilityOptions: {
        hideFromPdf: false,
        hideFromRead: false,
        hideFromEdit: false,
      },
      helpMessage: '',
      includeInExternalNotifications: false,
      repeatableDefaultValues: [],
      supportsInitialValue: controlDefinition.supportsInitialValue,
    },
    validationSettings: {
      dataTypeSettings: {
        type: controlDefinition.supportedDataTypes[0],
        useDefaultAlert: true,
        alertMessage: '',
      },
      defaultAlertMessage: { useGlobalAlert: true, alertMessage: 'Missing or incorrect value' },
      requiredFieldSettings: {
        required: 'false()',
        requiredXpath: '',
        useDefaultAlert: true,
        alertMessage: '',
      },
      constraints: [],
    },
    formulaSettings: {
      calculatedValue: '',
      initialValue: '',
      visibility: '',
      readOnly: '',
    },
  };

  return {
    ...commonOptions,
    additionalSettings: cloneDeep(controlDefinition.additionalSettings?.defaults),
    type,
  };
};
