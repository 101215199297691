import { SingleCheckbox, TextInput, When } from '@airelogic/form-management/components';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';

const options = [
  {
    value: 'false()',
    label: 'No',
  },
  {
    value: 'true()',
    label: 'Yes',
  },
  {
    value: 'formula',
    label: 'Formula',
  },
];

interface Props {
  fieldIndex: number;
}

const Required = ({ fieldIndex }: Props) => {
  const {
    register,
    formState: { errors },
    watch,
    control,
  } = useFormContext<FormValues>();

  const requiredWatch = watch(
    `controlSettings.${fieldIndex}.validationSettings.requiredFieldSettings.required`,
  );

  const useDefaultAlertWatch = watch(
    `controlSettings.${fieldIndex}.validationSettings.requiredFieldSettings.useDefaultAlert`,
  );

  return (
    <PanelWithHeader title="Required">
      <Controller
        name={`controlSettings.${fieldIndex}.validationSettings.requiredFieldSettings.required`}
        render={({ field }) => (
          <FormControl fullWidth component="fieldset">
            <FormLabel component="legend">Required</FormLabel>
            <RadioGroup row aria-label="Required" value={field.value} onChange={field.onChange}>
              {options.map((opt) => (
                <FormControlLabel
                  key={opt.value}
                  label={opt.label}
                  value={opt.value}
                  control={<Radio color="primary" />}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />
      <When condition={requiredWatch === 'formula'}>
        <TextInput
          type="text"
          label="Required XPath Formula"
          required
          helperText={
            errors?.controlSettings?.[fieldIndex]?.validationSettings?.requiredFieldSettings
              ?.requiredXpath?.message
          }
          error={
            errors?.controlSettings?.[fieldIndex]?.validationSettings?.requiredFieldSettings
              ?.requiredXpath !== undefined
          }
          {...register(
            `controlSettings.${fieldIndex}.validationSettings.requiredFieldSettings.requiredXpath`,
          )}
        />
      </When>
      <SingleCheckbox
        label="Use default alert"
        control={control}
        name={`controlSettings.${fieldIndex}.validationSettings.requiredFieldSettings.useDefaultAlert`}
      />
      <When condition={!useDefaultAlertWatch}>
        <TextInput
          id="alertMsg"
          type="text"
          label="Custom Alert Message"
          {...register(
            `controlSettings.${fieldIndex}.validationSettings.requiredFieldSettings.alertMessage`,
          )}
        />
      </When>
    </PanelWithHeader>
  );
};

export default Required;
