import { FormLayout, createPreviewUrl } from '@airelogic/form-management/common';
import { ConfirmationDialog, EditIcon, Toast } from '@airelogic/form-management/components';

import { Box } from '@mui/material';
import * as React from 'react';
import { useHistory } from 'react-router';
import { BuildingBlock } from '../BuildingBlock';
import { useDownloadBuildingBlock, usePostImport, usePostPreview } from '../Service';
import ActionMenu, { Action } from './ActionMenu';
import {
  useImportOverwriteConfirmation,
  useImportOverwriteConfirmationStore,
} from './UseImportOverwriteConfirmation';
import { useRollbackConfirmation } from './UseRollbackConfirmation';

interface Props {
  buildingBlock: BuildingBlock;
}

const BuildingBlockActions = ({ buildingBlock }: Props) => {
  const { handleRollbackClick, rollbackConfirmationDialogProps } =
    useRollbackConfirmation(buildingBlock);

  const { handleImportOverwriteClick, importOverwriteConfirmationDialogProps } =
    useImportOverwriteConfirmation(buildingBlock, async () => await handleImport(false));

  const { addToConfirmations } = useImportOverwriteConfirmationStore();

  const [successBarOpen, setSuccessBarOpen] = React.useState(false);
  const history = useHistory();
  const download = useDownloadBuildingBlock();
  const { mutate: postImport } = usePostImport();
  const { mutate: postPreview } = usePostPreview();

  const editBtnTooltipText = `Edit ${buildingBlock.title} ${
    buildingBlock.latestVersion ? `at version ${buildingBlock.latestVersion}` : ''
  }`;

  const handleEditClick = async () => {
    history.push(
      '/formbuilding/buildingblocks/designer/edit/' + encodeURIComponent(buildingBlock.orbeonId),
    );
  };

  const handleActionMenuClick = (action: Action) => {
    switch (action) {
      case 'rollback':
        handleRollbackClick();
        break;
      case 'download':
        handleDownloadClick();
        break;
      case 'import':
      case 'importNewVersion':
        handleImportClick();
        break;
      case 'importOverwrite':
        handleImportOverwriteClick();
        break;
      case 'previewDocument':
        handlePreviewClick('document');
        break;
      case 'previewWizard':
        handlePreviewClick('wizard');
        break;
    }
  };
  const handleDownloadClick = async () => {
    download({ orbeonId: buildingBlock.orbeonId, key: buildingBlock.blockKey });
  };

  const handleImportClick = async () => {
    await handleImport(true);
  };

  const handleImport = async (isNewVersion: boolean) => {
    postImport(
      {
        orbeonId: buildingBlock.orbeonId,
        newVersion: isNewVersion,
      },
      {
        onSuccess: () => {
          if (isNewVersion) {
            addToConfirmations(buildingBlock);
          }
          setSuccessBarOpen(true);
        },
      },
    );
  };

  const successBarClose = () => {
    setSuccessBarOpen(false);
  };

  const handlePreviewClick = async (previewType: FormLayout) => {
    postPreview(
      {
        orbeonId: buildingBlock.orbeonId,
      },
      {
        onSuccess: () => {
          const previewUrl = createPreviewUrl(previewType, 'preview-form', 1);
          window.open(previewUrl, '_blank');
        },
      },
    );
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
        <EditIcon
          tooltipText={editBtnTooltipText}
          aria-label={editBtnTooltipText}
          onClick={handleEditClick}
        />
        <ActionMenu buildingBlock={buildingBlock} onClick={handleActionMenuClick} />
      </Box>
      <ConfirmationDialog {...rollbackConfirmationDialogProps} />
      <ConfirmationDialog {...importOverwriteConfirmationDialogProps} />
      <Toast severity="success" open={successBarOpen} onClose={successBarClose}>
        Import of <b>{buildingBlock.blockKey}</b> was successful
      </Toast>
    </>
  );
};

export default BuildingBlockActions;
