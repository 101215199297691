import { PropsWithChildren, useEffect } from 'react';
import { useFormState } from 'react-hook-form';
import { FormValues } from './Form';
import { useAppDispatch } from './StateManagement/hooks';
import { updateSelectedComponent } from './StateManagement/layoutSlice';
import { ComponentType } from './StateManagement/types';

const ScrollErrorIntoView = ({ children }: PropsWithChildren<unknown>) => {
  const { errors } = useFormState<FormValues>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      /*
      This setTimeout is hacky, but the child elements have not been updated yet
      so you have to click submit twice for it to scroll - even though the test passed.
      */
      setTimeout(() => {
        const firstError = document.querySelector("*[data-haserror='true']");

        if (firstError) {
          firstError.scrollIntoView({ behavior: 'smooth', block: 'start' });

          const componentId = firstError.getAttribute('data-componentid');
          const componentType = firstError.getAttribute('data-componenttype');
          if (componentId && componentType) {
            dispatch(
              updateSelectedComponent({
                id: componentId,
                type: componentType as ComponentType,
                navigateToError: true,
              }),
            );
          }
        }
      });
    }
  }, [dispatch, errors]);

  return <>{children}</>;
};

export default ScrollErrorIntoView;
