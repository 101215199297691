import { z } from 'zod';
import { RichTextSelectIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import {
  validationSchema as baseReferenceDataSchema,
  itemsSuperRefine,
  superRefine,
} from './InlineReferenceData';
import { ControlDefinition } from './types';

const referenceDataSchema = baseReferenceDataSchema.extend({
  description: z.string().min(1, { message: 'Description is required' }),
});

const dataTypes: DataType[] = [
  'string',
  'boolean',
  'email',
  'integer',
  'decimal',
  'date',
  'time',
  'datetime',
];

export const refinedReferenceDataSchema = referenceDataSchema.superRefine(superRefine);

export const validationSchema = z.object({
  display: z.enum(['full-height', 'collapsible']),
  items: refinedReferenceDataSchema.array().min(1).superRefine(itemsSuperRefine),
});

export type AdditionalSettings = z.infer<typeof validationSchema>;

export type RichTextSelectInlineReferenceData = z.infer<typeof refinedReferenceDataSchema>;

export const presetItems: RichTextSelectInlineReferenceData[] = [
  {
    label: 'First choice',
    value: 'first-choice',
    description: 'Default description',
    stopCondition: false,
    stopConditionText: '',
    filterGroups: '',
    optionIsExclusive: false,
    deprecated: false,
    highlightAnswerInPDF: false,
  },
  {
    label: 'Second choice',
    value: 'second-choice',
    description: 'Default description',
    stopCondition: false,
    stopConditionText: '',
    filterGroups: '',
    optionIsExclusive: false,
    deprecated: false,
    highlightAnswerInPDF: false,
  },
  {
    label: 'Third choice',
    value: 'third-choice',
    description: 'Default description',
    stopCondition: false,
    stopConditionText: '',
    filterGroups: '',
    optionIsExclusive: false,
    deprecated: false,
    highlightAnswerInPDF: false,
  },
];

export const richTextSelectControl: ControlDefinition<AdditionalSettings> = {
  displayName: 'Rich Text Select',
  icon: RichTextSelectIcon,
  type: 'RICH_TEXT_SELECT',
  supportedDataTypes: dataTypes,
  additionalSettings: {
    validationSchema,
    defaults: {
      display: 'full-height',
      items: presetItems,
    },
  },
  supportsInitialValue: false,
};
