import { v4 as uuidv4 } from 'uuid';
import Page from './Page';
import { useAppDispatch } from './StateManagement/hooks';
import {
  defaultControlSettings,
  defaultSectionSettings,
  initialState,
} from './StateManagement/init-data';
import { rehydrate } from './StateManagement/layoutSlice';

const defaultSettings = {
  controlSettings: defaultControlSettings(),
  sectionSettings: defaultSectionSettings(),
  repeatSettings: [],
  properties: {
    applicationName: '',
    blockName: '',
    blockDescription: '',
    blockTitle: 'Untitled building block',
    hasTranslations: false,
  },
};

const CreateNew = () => {
  const dispatch = useAppDispatch();

  dispatch(rehydrate({ existingState: initialState }));

  return <Page id={uuidv4()} settings={defaultSettings} />;
};

export default CreateNew;
