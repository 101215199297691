import { useEffect, useMemo, useState } from 'react';
import { FieldArrayWithId, useFormContext, useFormState } from 'react-hook-form';
import { FormValues } from '../../Form';
import { useSectionSettingsContext } from '../../SectionSettingsContext';
import { selectSelectedSection } from '../../StateManagement/Selectors/SectionSelector';
import { useAppDispatch, useAppSelector } from '../../StateManagement/hooks';
import { clearNavigateToErrors } from '../../StateManagement/layoutSlice';
import HeaderTabs, { TabKeys } from '../HeaderTabs';
import OptionsResolver from './OptionsResolver';
import SectionName from './SectionName';

const getKeyAndIndex = (
  fields: FieldArrayWithId<FormValues, 'sectionSettings', 'key'>[],
  sectionId: string,
) => {
  for (let index = 0; index < fields.length; index++) {
    if (fields[index].id === sectionId) {
      return {
        index,
        key: fields[index].key,
      };
    }
  }

  return undefined;
};

interface Props {
  fieldIndex: number;
  navigateToError: boolean;
  selectedTab: TabKeys;
  updateSelectedTab: (tab: TabKeys) => void;
}

const Settings = ({ fieldIndex, navigateToError, updateSelectedTab, selectedTab }: Props) => {
  const { control } = useFormContext<FormValues>();
  const { errors } = useFormState({ control });
  const dispatch = useAppDispatch();

  const sectionErrors = errors?.sectionSettings?.[fieldIndex];

  const tabErrors = useMemo(() => new Map<TabKeys, boolean>(), []);
  tabErrors.set('basicSettings', sectionErrors?.basicSettings !== undefined);
  tabErrors.set('formula', sectionErrors?.formulaSettings !== undefined);

  useEffect(() => {
    if (navigateToError) {
      for (const [key, value] of tabErrors) {
        if (value) {
          updateSelectedTab(key);
          break;
        }
      }
      dispatch(clearNavigateToErrors());
    }
  }, [dispatch, navigateToError, tabErrors, updateSelectedTab]);

  return (
    <>
      <HeaderTabs onTabChange={updateSelectedTab} selectedTab={selectedTab} tabErrors={tabErrors} />
      <div>
        <SectionName fieldIndex={fieldIndex} />
        <OptionsResolver fieldIndex={fieldIndex} tab={selectedTab} />
      </div>
    </>
  );
};

const SectionSettingsPane = () => {
  const sectionSettings = useSectionSettingsContext();
  const [selectedTab, setSelectedTab] = useState<TabKeys>('basicSettings');
  const selectedSection = useAppSelector(selectSelectedSection);

  if (selectedSection === undefined) {
    return null;
  }

  const keyAndIndex = getKeyAndIndex(sectionSettings, selectedSection.id);
  if (keyAndIndex === undefined) {
    return null;
  }

  const updateSelectedTab = (key: TabKeys) => setSelectedTab(key);

  return (
    <Settings
      key={keyAndIndex.key}
      fieldIndex={keyAndIndex.index}
      {...selectedSection}
      selectedTab={selectedTab}
      updateSelectedTab={updateSelectedTab}
    />
  );
};

export default SectionSettingsPane;
