import { Document } from 'slimdom';
import { createXPathEvaluator } from '../..';
import { Instance } from '../Instance';
import { validationDomFacade } from './ValidationDomFacade';

export type ValidationResult = { isValid: true } | { isValid: false; message: string };

const createDummyInstance = () => {
  const document = new Document();
  document.append(document.createElement('root'));
  return document;
};

export const validateXPath = (xpath: string): ValidationResult => {
  const xPathEvaluator = createXPathEvaluator((id: string) => {
    return new Instance(id, createDummyInstance());
  });

  const nonDollaredXPath = xpath.replaceAll('$', '');
  try {
    xPathEvaluator.evaluateXPath(nonDollaredXPath, createDummyInstance(), validationDomFacade);
  } catch (error) {
    if (error instanceof Error) {
      //Ignore casting errors as the fields are empty
      if (error.message.startsWith('FORG0001')) {
        return {
          isValid: true,
        };
      }
      return {
        isValid: false,
        message: error.message,
      };
    }
    return {
      isValid: false,
      message: 'An unexpected error has occured',
    };
  }

  return {
    isValid: true,
  };
};
