import { SelectInput } from '@airelogic/form-management/components';
import { Grid2 as Grid } from '@mui/material';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { FormValues } from '../../../Form';

const refDataSourceOptions: { value: string; label: string }[] = [
  { value: 'aireforms', label: 'Forms4Health' },
  { value: 'aireglu', label: 'AireGlu' },
  { value: 'custom-plugin', label: 'Custom plugin' },
];

interface Props {
  fieldIndex: number;
  tooltip: string;
}

const DataSource = ({ fieldIndex, tooltip }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormValues>();

  const getErrors = () => {
    return errors?.controlSettings?.[fieldIndex]?.additionalSettings as FieldErrors<{
      dataSource: string;
    }>;
  };

  return (
    <Grid size={12}>
      <SelectInput
        label={'Source'}
        error={!!getErrors()?.dataSource}
        helperText={getErrors()?.dataSource?.message}
        tooltip={tooltip}
        {...register(`controlSettings.${fieldIndex}.additionalSettings.dataSource`)}
        required
      >
        {refDataSourceOptions.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </SelectInput>
    </Grid>
  );
};

export default DataSource;
