import { IConstraintOption } from '../../../../Controls/types';

export const DateOps: IConstraintOption[] = [
  {
    label: 'Formula',
    kind: 'custom',
    type: '',
    fieldType: 'text',
  },
  {
    label: 'Must be before today',
    kind: 'predefined',
    type: 'date-must-be-before-today',
    fieldType: null,
  },
  {
    label: 'Must be before today or today',
    kind: 'predefined',
    type: 'date-must-be-before-today-or-today',
    fieldType: null,
  },
  {
    label: 'Must be after today',
    kind: 'predefined',
    type: 'date-must-be-after-today',
    fieldType: null,
  },
  {
    label: 'Must be after today or today',
    kind: 'predefined',
    type: 'date-must-be-after-today-or-today',
    fieldType: null,
  },
];
