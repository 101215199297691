import { requiredXPathExpressionSchema } from '@airelogic/form-management/common';
import _ from 'lodash';
import { z } from 'zod';
import { ServersideCalcIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = [
  'string',
  'boolean',
  'email',
  'integer',
  'decimal',
  'date',
  'time',
  'datetime',
];

export const validationSchema = z.object({
  key: z.string().min(1),
  version: z.number().int().min(1),
  valuesOfInterest: z
    .object({
      key: z.string().min(1),
      xpath: requiredXPathExpressionSchema,
    })
    .array()
    .min(1)
    .superRefine((val, ctx) => {
      const elements = val.map((element, index) => ({
        key: element.key,
        path: `${index}.key`,
      }));

      const duplicates = _(elements)
        .groupBy((x) => x.key)
        .pickBy((x) => x.length > 1)
        .values()
        .flatten();

      if (duplicates.size() > 0) {
        duplicates.forEach((dupe) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [dupe.path],
            message: 'Multiple keys must not share the same name.',
          });
        });
      }
    }),
  dataSource: z.enum(['aireforms', 'custom-plugin', 'aireglu']),
  fireImmediately: z.boolean(),
});

export type AdditionalSettings = z.infer<typeof validationSchema>;

export const serverSideCalculationControl: ControlDefinition<AdditionalSettings> = {
  displayName: 'Server Side Calculation',
  icon: ServersideCalcIcon,
  type: 'SERVER_SIDE_CALCULATION',
  supportedDataTypes: dataTypes,
  additionalSettings: {
    validationSchema,
    defaults: {
      key: '',
      version: 1,
      valuesOfInterest: [
        {
          key: '',
          xpath: '',
        },
      ],
      dataSource: 'aireforms',
      fireImmediately: false,
    },
  },
  formulaOverrides: {
    allowCalculatedValue: false,
    allowInitialValue: false,
  },
  supportsInitialValue: false,
};
