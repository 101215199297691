import { ConfirmationDialog, IconButton, When } from '@airelogic/form-management/components';
import DeleteIcon from '@mui/icons-material/Delete';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Divider, FormHelperText, Typography } from '@mui/material';

import { useState } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useControlSettingsContext } from '../../ControlSettingsContext';
import { getControlDefinitionByType } from '../../Controls/Controls';
import { ControlType } from '../../Controls/types';
import { FormValues } from '../../Form';
import { useAppDispatch } from '../../StateManagement/hooks';
import {
  decreaseCellSpan,
  deleteControl,
  increaseCellSpan,
} from '../../StateManagement/layoutSlice';
import { useStyles } from './Component.styles';
import Resolver from './ControlComponents/Resolver';

interface Props {
  id: string;
  type: ControlType;
  cellSpan: number;
  canIncreaseCellSpan: boolean;
  repeatedIterationId?: string /*Used only for repeated grids*/;
}

const Component = ({ id, type, cellSpan, canIncreaseCellSpan, repeatedIterationId }: Props) => {
  const { classes, cx } = useStyles();
  const dispatch = useAppDispatch();

  const [showActions, setShowActions] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const controlSettings = useControlSettingsContext();
  const index = controlSettings.findIndex((f) => f.id === id);

  const { control } = useFormContext<FormValues>();
  const { errors } = useFormState({ control });

  const label = useWatch({ control, name: `controlSettings.${index}.basicSettings.label` });
  const hint = useWatch({ control, name: `controlSettings.${index}.basicSettings.hint` });

  const repeatableDefaultValues = useWatch({
    control,
    name: `controlSettings.${index}.basicSettings.repeatableDefaultValues`,
  });

  const repeatableDefaultValue =
    repeatableDefaultValues && repeatedIterationId
      ? repeatableDefaultValues.filter((x) => x.iterationId === repeatedIterationId)[0]
      : undefined;

  const required = useWatch({
    control,
    name: `controlSettings.${index}.validationSettings.requiredFieldSettings.required`,
    defaultValue: 'false()',
  });

  const controlDefintion = getControlDefinitionByType(type);

  const hasErrors = errors?.controlSettings?.[index] !== undefined;

  const handleHover = () => {
    setShowActions(true);
  };

  const handleUnhover = () => {
    setShowActions(false);
  };

  const onDeleteControl = () => {
    dispatch(deleteControl({ controlId: id }));
  };

  const confirmDelete = () => {
    setShowConfirmDelete(false);
    onDeleteControl();
  };

  const hideConfirmation = () => {
    setShowConfirmDelete(false);
  };

  const handleIncreaseCellSpan = () => {
    dispatch(increaseCellSpan({ controlId: id }));
  };
  const handleDecreaseCellSpan = () => {
    dispatch(decreaseCellSpan({ controlId: id }));
  };

  return (
    <div
      className={cx(classes.container, { [classes.error]: hasErrors })}
      onMouseEnter={handleHover}
      onMouseLeave={handleUnhover}
      data-testid={`component-${id}`}
      data-haserror={hasErrors}
      data-componenttype="control"
      data-componentid={id}
    >
      <div className={classes.innerContainer}>
        <div className={classes.headerTitle}>
          <Typography
            variant={'subtitle1'}
            className={classes.label}
            data-testid={`component-${id}-label`}
          >
            <When condition={required !== 'false()'}>
              <span
                className={cx(classes.required, {
                  [classes.requiredFormula]: required === 'formula',
                })}
                data-testid="req-indicator"
              >
                *{' '}
              </span>
            </When>
            {label && label !== '' ? label : '\u00A0'}
          </Typography>
        </div>
        <div className={classes.actionButtons}>
          {showActions ? (
            <IconButton tooltipText="Delete control" onClick={() => setShowConfirmDelete(true)}>
              <DeleteIcon />
            </IconButton>
          ) : null}
        </div>
      </div>
      <Divider />
      <div className={classes.innerContainer}>
        <Typography variant={'subtitle2'}>{controlDefintion.displayName}</Typography>
        <Resolver settingsIndex={index} />
      </div>
      <When condition={repeatableDefaultValue !== undefined}>
        <div className={classes.innerContainer}>
          <Typography className={classes.defaultValue}>
            {repeatableDefaultValue?.defaultValue}
          </Typography>
        </div>
      </When>
      <div className={classes.innerContainer}>
        <div className={classes.hintContainer}>
          <FormHelperText className={classes.hint} data-testid={`component-${id}-hint`}>
            {hint && hint !== '' ? hint : '\u00A0'}
          </FormHelperText>
        </div>
        <div className={classes.actionButtons}>
          {showActions ? (
            <>
              {cellSpan > 1 ? (
                <IconButton tooltipText="Decrease column span" onClick={handleDecreaseCellSpan}>
                  <NavigateBeforeIcon />
                </IconButton>
              ) : null}
              {canIncreaseCellSpan ? (
                <IconButton tooltipText="Increase column span" onClick={handleIncreaseCellSpan}>
                  <NavigateNextIcon />
                </IconButton>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
      <ConfirmationDialog
        confirmationText={
          'Are you sure you want to delete this control? All the options and configurations of this control will be lost.'
        }
        open={showConfirmDelete}
        handleCancel={hideConfirmation}
        handleConfirm={confirmDelete}
      ></ConfirmationDialog>
    </div>
  );
};
export default Component;
