import { PayloadAction } from '@reduxjs/toolkit';
import { ControlSettings } from '../../Controls/types';
import {
  getControl,
  getControlForCell,
  getControlsForGrid,
  getGridById,
  getSelectedComponent,
  getSelections,
} from '../localSelectors';
import { Cell, ClipboardOriginControl, IState, LayoutClipboard, SelectedComponent } from '../types';
import { deleteControl } from './controlReducers';

export type ClipboardAction = 'copy' | 'cut';

interface ClipboardPayload {
  action: ClipboardAction;
  originControlSettings: ControlSettings;
  originControlId: string;
}

export const updateCellSelection = (state: IState, cell?: Cell) => {
  const selections = getSelections(state);

  selections.selectedCell = cell;

  if (cell) {
    const selectedComponent = getControlForCell(state, cell);
    if (selectedComponent) {
      selections.selectedComponent = {
        id: selectedComponent.id,
        type: 'control',
        navigateToError: false,
      };
    } else {
      clearSelectedComponent(state);
    }
  }
};

export const updateClipboardAction = (state: IState, payload: ClipboardPayload) => {
  const originControl = getControl(state, payload.originControlId);

  const clipboardControl: ClipboardOriginControl = {
    id: originControl.id,
    type: originControl.type,
    settings: payload.originControlSettings,
  };

  const clipboard: LayoutClipboard = {
    originControl: clipboardControl,
    action: payload.action,
  };

  if (payload.action === 'cut') {
    deleteControl(state, originControl.id);
    clearSelectedComponent(state);
  }

  state.layoutSelections.clipboard = clipboard;
};

export const clearSelectedComponent = (state: IState) => {
  const selections = getSelections(state);
  selections.selectedComponent = undefined;
};

export const getOverlappingControlsForColumn = (
  state: IState,
  gridId: string,
  columnId: string,
) => {
  const grid = getGridById(state, gridId);
  const controlsInGrid = getControlsForGrid(state, gridId);

  const controls = controlsInGrid.filter((control) => {
    const controlIndex = grid.columnIds.indexOf(control.cell.columnId);
    const columnIndex = grid.columnIds.indexOf(columnId);
    return columnIndex >= controlIndex && columnIndex < controlIndex + control.cellSpan;
  });

  return controls;
};

export const selectionReducers = {
  updateCellSelection: (state: IState, action: PayloadAction<Cell | undefined>) => {
    updateCellSelection(state, action.payload);
  },
  updateHoveredCell: (state: IState, action: PayloadAction<Cell | undefined>) => {
    getSelections(state).hoveredCell = action.payload;
  },
  updateSelectedComponent: (state: IState, action: PayloadAction<SelectedComponent>) => {
    getSelections(state).selectedComponent = {
      navigateToError: false,
      ...action.payload,
    };
  },
  deselectComponent: (state: IState) => {
    clearSelectedComponent(state);
  },
  clearNavigateToErrors: (state: IState) => {
    const selectedComponent = getSelectedComponent(state);
    if (selectedComponent) {
      selectedComponent.navigateToError = false;
    }
  },
  updateClipboard: (state: IState, action: PayloadAction<ClipboardPayload>) => {
    updateClipboardAction(state, action.payload);
  },
};

export const getNextSelectionIndex = (values: string[], currentValue: string) => {
  const currentIndex = values.findIndex((x) => x === currentValue);
  if (currentIndex === 0) {
    return {
      currentIndex,
      nextSelectionIndex: 1,
    };
  }
  if (currentIndex === values.length - 1) {
    return {
      currentIndex,
      nextSelectionIndex: values.length - 2,
    };
  }
  return {
    currentIndex,
    nextSelectionIndex: currentIndex + 1,
  };
};
