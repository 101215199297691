import { StyledButton } from '@airelogic/form-management/components';
import { Grid2 as Grid } from '@mui/material';
import { useState } from 'react';
import PanelWithHeader from '../../PanelWithHeader';
import InlineReferenceDataDialog from '../InlineReferenceData/InlineReferenceDataDialog';
import { blankChoice } from '../InlineReferenceData/Properties/Properties';
import DefaultSelectOptions from './DefaultSelectOptions';

interface Props {
  fieldIndex: number;
}

const CheckboxesAdditionalSettings = ({ fieldIndex }: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <>
      <PanelWithHeader title="Choices">
        <Grid container>
          <Grid size={12}>
            <StyledButton onClick={() => setDialogOpen(true)}>Configure</StyledButton>
          </Grid>
        </Grid>
      </PanelWithHeader>
      <PanelWithHeader title="Additional settings">
        <Grid container>
          <DefaultSelectOptions fieldIndex={fieldIndex} supportsMultiSelect={false} />
        </Grid>
      </PanelWithHeader>
      <InlineReferenceDataDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fieldIndex={fieldIndex}
        emptyItem={blankChoice}
      />
    </>
  );
};

export default CheckboxesAdditionalSettings;
