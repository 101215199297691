import { isValidYoutubeVideo } from '@airelogic/form-management/common/validations';
import { z } from 'zod';
import { VideoIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

export const validationSchema = z.object({
  videoUrl: z.string().refine(isValidYoutubeVideo, { message: 'Must be a valid youtube URL' }),
  autoplay: z.boolean(),
  omitFromSubmission: z.boolean(),
});

const dataTypes: DataType[] = ['string'];

export type AdditionalSettings = z.infer<typeof validationSchema>;

export const youtubeControl: ControlDefinition<AdditionalSettings> = {
  displayName: 'Youtube Video',
  icon: VideoIcon,
  type: 'YOUTUBE',
  supportedDataTypes: dataTypes,
  formulaOverrides: {
    allowCalculatedValue: false,
    allowInitialValue: false,
    allowReadOnly: false,
  },
  additionalSettings: {
    defaults: {
      videoUrl: '',
      autoplay: false,
      omitFromSubmission: false,
    },
    validationSchema,
  },
  supportsInitialValue: false,
};
