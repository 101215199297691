import { AuthorisedRoute } from '@airelogic/form-management/common';
import { CreateFormPage, EditFormPage } from '@airelogic/form-management/formbuilder';
import { useRouteMatch } from 'react-router';
import Switch from '../../ErrorHandlingSwitch';
import Forms from './Page';

const Routing = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <AuthorisedRoute path={`${match.path}`} exact can={{ I: 'preview', a: 'Form' }}>
        <Forms />
      </AuthorisedRoute>

      <AuthorisedRoute path={`${match.path}/new`} can={{ I: 'create', a: 'Form' }}>
        <CreateFormPage />
      </AuthorisedRoute>

      <AuthorisedRoute path={`${match.path}/newversion/:key`} can={{ I: 'create', a: 'Form' }}>
        <EditFormPage />
      </AuthorisedRoute>

      <AuthorisedRoute path={`${match.path}/edit/:key/:version`} can={{ I: 'create', a: 'Form' }}>
        <EditFormPage />
      </AuthorisedRoute>
    </Switch>
  );
};

export default Routing;
