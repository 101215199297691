import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  metadataError: {
    borderLeftColor: theme.palette.error.main,
  },

  paper: {
    padding: theme.spacing(2),
    borderLeft: '7px solid #ffffff00',
  },

  headerText: {
    marginRight: 'auto',
  },

  versionText: {
    color: '#889AA5',
    paddingRight: 10,
  },

  versionTextBold: {
    fontWeight: 'bold',
    color: '#33444E',
  },

  autoCompleteTooltip: {
    marginLeft: '8px',
  },
}));
