import { SanitizedHTML } from '@airelogic/common';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { FormValues } from '../../../Form';

interface Props {
  settingsIndex: number;
}

const useStyles = makeStyles()({
  root: {
    '& p,dl,ul,ol': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
});

const ExplanatoryText = ({ settingsIndex }: Props) => {
  const { watch } = useFormContext<FormValues>();
  const explanatoryText = watch(`controlSettings.${settingsIndex}.additionalSettings.text`);
  const { classes: styles } = useStyles();

  return <SanitizedHTML html={explanatoryText} className={styles.root} />;
};

export default ExplanatoryText;
