import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },

  formInput: {
    margin: theme.spacing(2),
    width: '40%',
  },

  addButton: {
    color: '#3463FD',
  },

  actionButton: {
    color: 'white',
    background: '#3463FD',
    margin: '3px',
  },

  deleteButton: {
    color: 'black',
    margin: '3px',
  },

  scrollingPaper: {
    flex: 1,
    background: '#EDF0F2',
    borderColor: '#EDF0F2',
    border: '10px solid',
  },

  paperHeader: {
    borderBottom: 'solid 1px #33444E1A',
  },

  headerText: {
    marginRight: 'auto',
  },

  referenceList: {
    listStyle: 'none',
    paddingLeft: 0,
    height: '100%',
  },

  referenceDataItem: {
    marginBottom: theme.spacing(2),
  },
}));
