import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  header: {
    paddingTop: 10,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 15,
    backgroundColor: '#DBE0E4',
  },
}));
