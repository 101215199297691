import { Toast } from '@airelogic/form-management/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { PropsWithChildren, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import CloseFormHandler from './CloseFormHandler';
import { ControlSettingsProvider } from './ControlSettingsContext';
import { ControlSettings as BaseControlSettings, ControlType } from './Controls/types';
import { SaveFailed, ValidationFailed } from './ErrorMessages';
import { RepeatSettings as BaseRepeatSettings } from './FormStructures/RepeatingGrid';
import { SectionSettings as BaseSectionSettings } from './FormStructures/Section';
import { validationSchema } from './FormValidationSchema';
import { Properties } from './PropertiesBar/Properties';
import { RepeatSettingsProvider } from './RepeatSettingsContext';
import ScrollErrorIntoView from './ScrollErrorIntoView';
import { SectionSettingsProvider } from './SectionSettingsContext';

export type SectionSettings = BaseSectionSettings & { id: string };
export type RepeatSettings = BaseRepeatSettings & { id: string };
export type ControlSettings = BaseControlSettings & { id: string; type: ControlType };

export type FormValues = {
  sectionSettings: SectionSettings[];
  controlSettings: ControlSettings[];
  repeatSettings: RepeatSettings[];
  properties: Properties;
};

export type Submit = (data: FormValues) => Promise<{ success: boolean }>;
interface Props {
  onSubmit: Submit;
  initialValues: FormValues;
}

const Form = ({ initialValues, children, onSubmit }: PropsWithChildren<Props>) => {
  const formMethods = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: zodResolver(validationSchema),
  });

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { isSubmitSuccessful },
  } = formMethods;

  const controlSettings = useFieldArray({
    name: 'controlSettings',
    control,
    keyName: 'key',
  });

  const sectionSettings = useFieldArray({
    name: 'sectionSettings',
    control,
    keyName: 'key',
  });

  const repeatSettings = useFieldArray({
    name: 'repeatSettings',
    control,
    keyName: 'key',
  });

  const [successBarOpen, setSuccessBarOpen] = useState<boolean>(false);
  const successBarClose = () => setSuccessBarOpen(false);
  const [failedBar, setFailedBar] = useState({ open: false, message: '' });
  const failedBarClose = () => setFailedBar({ open: false, message: '' });

  //This is to work around some current issues with resetting/isDirty etc
  const [submittedData, setSubmittedData] = useState<FormValues | undefined>(undefined);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(submittedData, { keepDirty: false });
    }
  }, [isSubmitSuccessful, submittedData, reset]);

  const submit = async (data: FormValues) => {
    setSubmittedData(data);
    const result = await onSubmit(data);

    if (result.success) {
      setSuccessBarOpen(true);
    } else {
      setFailedBar({ open: true, message: SaveFailed });
      setError('root.server', {
        type: 'server',
        message: 'Failed to submit',
      });
    }
  };

  const onValidationError = () => {
    setFailedBar({ open: true, message: ValidationFailed });
  };

  return (
    <FormProvider {...formMethods}>
      <CloseFormHandler>
        <ScrollErrorIntoView>
          <ControlSettingsProvider {...controlSettings}>
            <SectionSettingsProvider {...sectionSettings}>
              <RepeatSettingsProvider {...repeatSettings}>
                <form
                  aria-label="Building block designer"
                  style={{ height: '100%' }}
                  autoComplete="off"
                  noValidate
                  onSubmit={handleSubmit(submit, onValidationError)}
                >
                  {children}
                </form>
                <Toast severity="success" open={successBarOpen} onClose={successBarClose}>
                  Saved successfully!
                </Toast>
                <Toast severity="error" open={failedBar.open} onClose={failedBarClose}>
                  {failedBar.message}
                </Toast>
              </RepeatSettingsProvider>
            </SectionSettingsProvider>
          </ControlSettingsProvider>
        </ScrollErrorIntoView>
      </CloseFormHandler>
    </FormProvider>
  );
};

export default Form;
