import { FormTheme } from '../../Themes/FormTheme';
import { makeStyles } from '../../Themes/ThemeProvider';
import Container from './Container';

interface IProps {
  siteTitle: string;
  siteLogoUri: string;
  hideSiteTitle: boolean;
}

const useHeaderStyles = makeStyles()((style: FormTheme) => ({
  header: {
    padding: 0,
    backgroundColor: style.headerBackgroundColor,
    flexDirection: style.headerFlexDirection,
    display: 'flex',
    minHeight: '5em',
  },
  headerLogo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '5em',
    margin: style.headerMargin,
    color: style.headerTitleTextColor,
  },
  headerBar: {
    height: '0.5rem',
    maxHeight: '0.5rem',
    backgroundColor: style.primaryColor,
  },
  siteTitle: {
    color: style.headerTitleTextColor,
    margin: 0,
    fontSize: '2rem',
    fontFamily: "'Hind', sans-serif",
    '@media screen and (max-width:30em)': {
      fontSize: '1.6rem',
    },
  },
  logoImage: {
    maxHeight: '4em',
    marginTop: '1em',
    marginBottom: '1em',
  },
  logoImageDisplayRight: {
    marginLeft: 'auto',
  },
}));

const Header = ({ siteTitle, siteLogoUri, hideSiteTitle }: IProps) => {
  const {
    classes: {
      header,
      headerBar,
      headerLogo,
      siteTitle: siteTitleClass,
      logoImage,
      logoImageDisplayRight,
    },
    cx,
  } = useHeaderStyles();

  let logo = undefined;

  if (siteLogoUri && siteLogoUri !== '') {
    logo = (
      <img
        className={cx('img-fluid', logoImage, { [logoImageDisplayRight]: !hideSiteTitle })}
        src={siteLogoUri}
        alt={siteTitle}
      />
    );
  }

  return (
    <header className={header} data-testid="header">
      <div className={headerBar} />
      <Container>
        <div className={headerLogo}>
          {!hideSiteTitle && (
            <h1
              className={cx(siteTitleClass, { 'd-none d-sm-block': !!siteLogoUri })}
              data-testid="site-title"
            >
              {siteTitle}
            </h1>
          )}
          {logo}
        </div>
      </Container>
    </header>
  );
};

export default Header;
