import { SingleCheckbox } from '@airelogic/form-management/components';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '../../Form';
import ExtendableContent from '../PanelWithHeader';

interface Props {
  fieldIndex: number;
}

const AdditionalSettings = ({ fieldIndex }: Props) => {
  const { control } = useFormContext<FormValues>();

  return (
    <ExtendableContent title="Additional Settings">
      <SingleCheckbox
        label="Disable delete row button"
        control={control}
        name={`repeatSettings.${fieldIndex}.additionalSettings.disableDeleteRow`}
      />
      <SingleCheckbox
        label="Disable add row button"
        control={control}
        name={`repeatSettings.${fieldIndex}.additionalSettings.disableAddRow`}
      />
      <SingleCheckbox
        label="Display as master child"
        control={control}
        name={`repeatSettings.${fieldIndex}.additionalSettings.displayAsMasterChild`}
      />
    </ExtendableContent>
  );
};

export default AdditionalSettings;
