import { LoadingSpinner } from '@airelogic/common';
import { createEmptyForm } from '../FormBuildingInterfaces';
import { FormEdit } from '../FormEdit/FormEdit';
import { useGetBuildingBlocks, useGetDocumentClassifications } from '../Service';

const CreateNewPage = () => {
  const {
    data: blockData,
    status: blockStatus,
    error: blockError,
    isRefetching: isRefetchingBlocks,
    refetch,
  } = useGetBuildingBlocks();
  const { data: classifications, status: classificationStatus } = useGetDocumentClassifications();

  if (classificationStatus === 'pending' || blockStatus === 'pending') {
    return <LoadingSpinner fullscreen={true} />;
  }

  if (blockStatus === 'error') {
    throw blockError;
  }

  const refetchBlocks = async () => {
    await refetch();
  };

  return (
    <FormEdit
      refreshBlocks={refetchBlocks}
      blockVersions={blockData}
      classifications={classifications ?? []}
      editorMode={'new'}
      isRefreshingBlocks={isRefetchingBlocks}
      currentValues={createEmptyForm()}
    />
  );
};

export default CreateNewPage;
