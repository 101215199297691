import { z } from 'zod';
import { CheckboxIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import {
  validationSchema as ReferenceDataSchema,
  itemsSuperRefine,
  presetInlineReferenceDataItems,
  superRefine,
} from './InlineReferenceData';
import { schema as selectDefaultsSchema } from './SelectDefaults';
import { ControlDefinition } from './types';

export const refinedReferenceDataSchema = ReferenceDataSchema.superRefine(superRefine);

export const validationSchema = z
  .object({
    items: refinedReferenceDataSchema.array().min(1).superRefine(itemsSuperRefine),
  })
  .merge(selectDefaultsSchema);

export const dataTypes: DataType[] = [
  'string',
  'boolean',
  'email',
  'integer',
  'decimal',
  'date',
  'time',
  'datetime',
];

export type AdditionalSettings = z.infer<typeof validationSchema>;

export const checkBoxesControl: ControlDefinition<AdditionalSettings> = {
  displayName: 'Checkboxes',
  icon: CheckboxIcon,
  type: 'CHECKBOX',
  supportedDataTypes: dataTypes,
  additionalSettings: {
    validationSchema,
    defaults: {
      style: 'default',
      withOther: false,
      items: presetInlineReferenceDataItems,
      choiceFilterXPath: '',
    },
  },
  supportsInitialValue: true,
};
