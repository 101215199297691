import {
  ActionMenu,
  ActionMenuItem,
  ConfirmationDialog,
  SelectInput,
  SingleCheckbox,
  SmallMenuIcon,
  TextInput,
  When,
} from '@airelogic/form-management/components';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { Constraints, FormulaConstraint, LimitConstraint } from '../../../Controls/DefaultSettings';
import { IConstraintOption } from '../../../Controls/types';
import { FormValues } from '../../../Form';

const ValidationFormula = ({
  fieldIndex,
  constraintIndex,
  deleteConstraint,
  options,
  constraintsLength,
  move,
}: {
  fieldIndex: number;
  constraintIndex: number;
  constraintsLength: number;
  move: (currentIndex: number, moveToIndex: number) => void;
  deleteConstraint: (index: number) => void;
  options: IConstraintOption[];
}) => {
  const { classes } = useStyles();

  const {
    register,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useFormContext<FormValues>();

  const [fieldType, setFieldType] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const useDefaultAlertWatch = watch(
    `controlSettings.${fieldIndex}.validationSettings.constraints.${constraintIndex}.useDefaultAlert`,
  );

  const formulaTypeWatch = watch(
    `controlSettings.${fieldIndex}.validationSettings.constraints.${constraintIndex}.type`,
  );

  useEffect(() => {
    /*cant register the SelectInput as xpath because it won't be able to be used in the input/num field then
    cant have kind in SelectInput as many formulas can have the same kind
    so we use the type of the formula in the SelectInput and setback the kind based on that*/
    const currentOption = options.filter((o: IConstraintOption) => o.type === formulaTypeWatch)[0];

    if (currentOption !== undefined) {
      if (currentOption.fieldType !== null) {
        setFieldType(currentOption.fieldType);
      } else {
        setFieldType(null);
      }
      setValue(
        `controlSettings.${fieldIndex}.validationSettings.constraints.${constraintIndex}.kind`,
        currentOption.kind,
      );
    } else {
      /* When initialising as kind="custom" need to reset the value so the text field appears */
      setValue(
        `controlSettings.${fieldIndex}.validationSettings.constraints.${constraintIndex}.kind`,
        'custom',
      );
      setFieldType('text');
    }
  }, [formulaTypeWatch, options, constraintIndex, fieldIndex, setValue]);

  const getErrorsAs = <T extends Constraints>(): FieldErrors<T> | undefined => {
    return errors?.controlSettings?.[fieldIndex]?.validationSettings?.constraints?.[
      constraintIndex
    ] as FieldErrors<T> | undefined;
  };

  const showMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const canMoveConstraintUp = constraintIndex > 0;
  const canMoveConstraintDown = constraintIndex < constraintsLength - 1;

  const moveConstraintUp = () => {
    move(constraintIndex, constraintIndex - 1);
    setAnchorEl(null);
  };

  const moveConstraintDown = () => {
    move(constraintIndex, constraintIndex + 1);
    setAnchorEl(null);
  };

  const confirmDelete = () => {
    setShowConfirmDelete(false);
    deleteConstraint(constraintIndex);
  };

  const hideConfirmDelete = () => {
    setShowConfirmDelete(false);
  };

  return (
    <>
      <div className={classes.row}>
        <SmallMenuIcon tooltipText="Constraint settings" onClick={showMenu} />
        <SelectInput
          label="Constraint Options"
          {...register(
            `controlSettings.${fieldIndex}.validationSettings.constraints.${constraintIndex}.type`,
          )}
        >
          {options.map((option: IConstraintOption) => (
            <option value={option.type} key={`${fieldIndex}.${option.label}`}>
              {option.label}
            </option>
          ))}
        </SelectInput>
      </div>
      <When condition={fieldType === 'number'}>
        <TextInput
          type={fieldType ?? ''}
          label="Value"
          error={getErrorsAs<LimitConstraint>()?.limit !== undefined}
          helperText={getErrorsAs<LimitConstraint>()?.limit?.message}
          {...register(
            `controlSettings.${fieldIndex}.validationSettings.constraints.${constraintIndex}.limit`,
            { valueAsNumber: true },
          )}
        />
      </When>
      <When condition={fieldType === 'text'}>
        <TextInput
          type={fieldType ?? ''}
          label="Value"
          error={getErrorsAs<FormulaConstraint>()?.xpath !== undefined}
          helperText={getErrorsAs<FormulaConstraint>()?.xpath?.message}
          {...register(
            `controlSettings.${fieldIndex}.validationSettings.constraints.${constraintIndex}.xpath`,
          )}
          multiline={true}
        />
      </When>
      <SingleCheckbox
        label="Use default alert"
        control={control}
        name={`controlSettings.${fieldIndex}.validationSettings.constraints.${constraintIndex}.useDefaultAlert`}
      />
      <When condition={!useDefaultAlertWatch}>
        <TextInput
          type="text"
          label="Custom Alert Message"
          {...register(
            `controlSettings.${fieldIndex}.validationSettings.constraints.${constraintIndex}.alert`,
          )}
        />
      </When>

      <ConfirmationDialog
        confirmationText={'Are you sure you want to delete this constraint?'}
        open={showConfirmDelete}
        handleCancel={hideConfirmDelete}
        handleConfirm={confirmDelete}
      />
      <ActionMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <ActionMenuItem
          disabled={!canMoveConstraintUp}
          onClick={moveConstraintUp}
          text="Move up"
          icon={<ArrowUpwardIcon />}
        />
        <ActionMenuItem
          disabled={!canMoveConstraintDown}
          onClick={moveConstraintDown}
          text="Move down"
          icon={<ArrowDownwardIcon />}
        />
        <ActionMenuItem
          onClick={() => {
            setAnchorEl(null);
            setShowConfirmDelete(true);
          }}
          text="Delete"
          icon={<DeleteIcon />}
        />
      </ActionMenu>
    </>
  );
};

export default ValidationFormula;

const useStyles = makeStyles()(() => ({
  switch: {
    marginLeft: 5,
  },
  row: {
    display: 'flex',
    width: '100%',
  },
}));
