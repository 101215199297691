import { ConfirmationDialog, StyledButton, When } from '@airelogic/form-management/components';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useState } from 'react';
import { FieldArray, useFieldArray, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { FormValues } from '../../../Form';
import ReferenceDataTable from './ReferenceDataTable';

const useStyles = makeStyles()(() => ({
  dialogHeader: {
    backgroundColor: '#251430', //theme.palette.primary.main,
    color: '#FFFFFF', //theme.palette.primary.contrastText,
  },

  dialogAction: {
    backgroundColor: '#DBE0E4',
  },

  noItemsContainer: {
    height: 100,
    textAlign: 'center',
    padding: 10,
  },

  dialogContent: {},
  dialogPaper: {
    height: '80%',
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  fieldIndex: number;
  emptyItem: FieldArray<FormValues, `controlSettings.${number}.additionalSettings.items`>;
}

const InlineReferenceDataDialog = ({ open, onClose, fieldIndex, emptyItem }: Props) => {
  const { classes } = useStyles();

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const { trigger, control, watch } = useFormContext<FormValues>();

  const { fields, append, remove, insert, move } = useFieldArray({
    control,
    name: `controlSettings.${fieldIndex}.additionalSettings.items`,
  });

  const controlType = watch(`controlSettings.${fieldIndex}.type`);

  const allowRemoveAll = fields.length > 0;

  const hideConfirmation = () => {
    setShowConfirmDelete(false);
  };

  const confirmDelete = () => {
    setShowConfirmDelete(false);
    remove();
  };

  const handleRemoveAll = () => {
    if (allowRemoveAll) {
      setShowConfirmDelete(true);
    }
  };

  const addChoice = () => {
    append(emptyItem);
  };

  const handleClose = async () => {
    const valid = await trigger(`controlSettings.${fieldIndex}.additionalSettings.items`);

    if (valid) {
      onClose();
    }
  };

  return (
    <Dialog
      maxWidth={false}
      fullWidth={true}
      aria-labelledby="inline-ref-data"
      open={open}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle className={classes.dialogHeader}>Edit choices</DialogTitle>

      <DialogContent dividers>
        <ReferenceDataTable
          fields={fields}
          controlFieldIndex={fieldIndex}
          controlType={controlType}
          addChoice={addChoice}
          insert={insert}
          remove={remove}
          move={move}
        />
        <When condition={fields.length === 0}>
          <div className={classes.noItemsContainer}>
            <Typography color="error">Choices cannot be empty.</Typography>
          </div>
        </When>
      </DialogContent>

      <DialogActions className={classes.dialogAction}>
        <StyledButton disabled={!allowRemoveAll} onClick={handleRemoveAll}>
          Remove all
        </StyledButton>
        <StyledButton autoFocus onClick={handleClose}>
          Close
        </StyledButton>
      </DialogActions>
      <ConfirmationDialog
        confirmationText={`Are you sure you want to remove all choices?`}
        open={showConfirmDelete}
        handleCancel={hideConfirmation}
        handleConfirm={confirmDelete}
      ></ConfirmationDialog>
    </Dialog>
  );
};

export default InlineReferenceDataDialog;
