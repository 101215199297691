import { TabKeys } from '../HeaderTabs';
import AdditionalSettings from './AdditionalSettings';
import ContentSettings from './ContentSettings';
import Formulas from './Formulas';
import VisibilityOptions from './VisibilityOptions';

const OptionsResolver = ({ fieldIndex, tab }: { fieldIndex: number; tab: TabKeys }) => {
  switch (tab) {
    case 'basicSettings':
      return (
        <>
          <VisibilityOptions fieldIndex={fieldIndex} />
          <ContentSettings fieldIndex={fieldIndex} />
          <AdditionalSettings fieldIndex={fieldIndex} />
        </>
      );
    case 'formula':
      return <Formulas fieldIndex={fieldIndex} />;

    default:
      return null;
  }
};

export default OptionsResolver;
