import {
  MustBePositive,
  requiredXPathExpressionSchema,
  ResourceKeyRegex,
} from '@airelogic/form-management/common/validations';
import { z } from 'zod';
import { DynamicDropdownIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { schema as selectDefaultsSchema } from './SelectDefaults';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = [
  'string',
  'boolean',
  'email',
  'integer',
  'decimal',
  'date',
  'time',
  'datetime',
];

export const validationSchema = z
  .object({
    multiSelect: z.boolean(),
    resourceKey: z.string().min(1).regex(ResourceKeyRegex, 'Key must not contain whitespace'),
    resourceVersion: z.number().min(1, { message: MustBePositive }),
    itemPath: requiredXPathExpressionSchema,
    labelPath: requiredXPathExpressionSchema,
    valuePath: requiredXPathExpressionSchema,
    dataSource: z.enum(['aireforms', 'custom-plugin', 'aireglu']),
  })
  .merge(selectDefaultsSchema);

export type AdditionalSettings = z.infer<typeof validationSchema>;

export const dynamicDropdownControl: ControlDefinition<AdditionalSettings> = {
  displayName: 'Dynamic Data Dropdown',
  icon: DynamicDropdownIcon,
  type: 'DYNAMIC_DROPDOWN',
  supportedDataTypes: dataTypes,
  additionalSettings: {
    validationSchema,
    defaults: {
      choiceFilterXPath: '',
      multiSelect: false,
      withOther: false,
      itemPath: 'concept',
      labelPath: 'displayName',
      valuePath: '@code',
      style: 'default',
      dataSource: 'aireforms',
    },
  },
  supportsInitialValue: false,
};
