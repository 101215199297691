import { Activity } from './AuthenticatedUser';

export const resolveLandingPage = (userActivities: Activity[]) => {
  if (userActivities.includes('Manage:Form')) {
    return '/formbuilding/buildingblocks';
  }

  if (userActivities.includes('Preview:Form')) {
    return '/formbuilding/forms';
  }

  return '/accessdenied';
};
