import { useEffect, useMemo, useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { FormValues } from '../../Form';
import { useRepeatSettingsContext } from '../../RepeatSettingsContext';
import { selectSelectedRepeat } from '../../StateManagement/Selectors/GridSelector';
import { useAppDispatch, useAppSelector } from '../../StateManagement/hooks';
import { clearNavigateToErrors } from '../../StateManagement/layoutSlice';
import HeaderTabs, { TabKeys } from '../HeaderTabs';
import RepeatName from './Name';
import OptionsResolver from './OptionsResolver';

interface Props {
  fieldIndex: number;
  navigateToError: boolean;
  selectedTab: TabKeys;
  updateSelectedTab: (tab: TabKeys) => void;
}

const Settings = ({ fieldIndex, navigateToError, selectedTab, updateSelectedTab }: Props) => {
  const { control } = useFormContext<FormValues>();
  const { errors } = useFormState({ control });
  const dispatch = useAppDispatch();

  const repeatErrors = errors?.repeatSettings?.[fieldIndex];

  const tabErrors = useMemo(() => new Map<TabKeys, boolean>(), []);
  tabErrors.set('basicSettings', repeatErrors?.basicSettings !== undefined);
  tabErrors.set('formula', repeatErrors?.formulaSettings !== undefined);
  tabErrors.set('additionalSettings', repeatErrors?.additionalSettings !== undefined);

  useEffect(() => {
    if (navigateToError) {
      for (const [key, value] of tabErrors) {
        if (value) {
          updateSelectedTab(key);
          break;
        }
      }
      dispatch(clearNavigateToErrors());
    }
  }, [dispatch, navigateToError, tabErrors, updateSelectedTab]);

  return (
    <>
      <HeaderTabs onTabChange={updateSelectedTab} selectedTab={selectedTab} tabErrors={tabErrors} />
      <div>
        <RepeatName fieldIndex={fieldIndex} />
        <OptionsResolver fieldIndex={fieldIndex} tab={selectedTab} />
      </div>
    </>
  );
};

const SettingsPane = () => {
  const { getById: getRepeatSettings } = useRepeatSettingsContext();
  const [selectedTab, setSelectedTab] = useState<TabKeys>('basicSettings');
  const selectedRepeat = useAppSelector(selectSelectedRepeat);

  if (selectedRepeat === undefined) {
    return null;
  }

  const settings = getRepeatSettings(selectedRepeat.id);

  if (settings === undefined) {
    return null;
  }

  const updateSelectedTab = (key: TabKeys) => setSelectedTab(key);

  return (
    <Settings
      key={settings.key}
      fieldIndex={settings.index}
      {...selectedRepeat}
      selectedTab={selectedTab}
      updateSelectedTab={updateSelectedTab}
    />
  );
};

export default SettingsPane;
