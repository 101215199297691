import { nanoid, PayloadAction } from '@reduxjs/toolkit';
import { getRepeatingGridById } from '../localSelectors';
import { IState } from '../types';

interface MoveIteration {
  gridId: string;
  iterationId: string;
  direction: 'up' | 'down';
}

interface AddIteration {
  gridId: string;
  insertPosition?: {
    relativeIterationId: string;
    direction: 'above' | 'below';
  };
}

interface DeleteIteration {
  gridId: string;
  iterationId: string;
}

export const repeatReducers = {
  moveRepeatIteration: (state: IState, action: PayloadAction<MoveIteration>) => {
    const grid = getRepeatingGridById(state, action.payload.gridId);
    if (grid) {
      switch (action.payload.direction) {
        case 'up':
          moveIterationUp(grid.iterationIds, action.payload.iterationId);
          break;
        case 'down':
          moveIterationDown(grid.iterationIds, action.payload.iterationId);
      }
    }
  },
  deleteRepeatIteration: (state: IState, action: PayloadAction<DeleteIteration>) => {
    const grid = getRepeatingGridById(state, action.payload.gridId);
    if (grid) {
      grid.iterationIds = grid.iterationIds.filter((i) => i !== action.payload.iterationId);
    }
  },
  addRepeatIteration: (state: IState, action: PayloadAction<AddIteration>) => {
    const { gridId, insertPosition } = action.payload;

    const grid = getRepeatingGridById(state, gridId);
    if (grid) {
      if (insertPosition) {
        const offset = insertPosition.direction === 'below' ? 1 : 0;
        const currentIterationIndex = grid.iterationIds.indexOf(insertPosition.relativeIterationId);
        grid.iterationIds.splice(currentIterationIndex + offset, 0, nanoid());
      } else {
        grid.iterationIds.push(nanoid());
      }
    }
  },
};

const moveIterationUp = (iterations: string[], iterationId: string) => {
  const currentIterationIndex = iterations.indexOf(iterationId);
  const numberOfIterations = iterations.length;
  if (numberOfIterations <= 1 || currentIterationIndex === 0) {
    return;
  }
  const prevIterationId = iterations.splice(currentIterationIndex, 1)[0];
  iterations.splice(currentIterationIndex - 1, 0, prevIterationId);
};

const moveIterationDown = (iterations: string[], iterationId: string) => {
  const currentIterationIndex = iterations.indexOf(iterationId);
  const numberOfIterations = iterations.length;
  if (numberOfIterations <= 1 || currentIterationIndex === numberOfIterations - 1) {
    return;
  }
  const nextIterationId = iterations.splice(currentIterationIndex, 1)[0];
  iterations.splice(currentIterationIndex + 1, 0, nextIterationId);
};
