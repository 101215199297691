import { createSelector } from '@reduxjs/toolkit';
import {
  getHoveredCell,
  getLocalState,
  getSelectedCell,
  getSelectedComponent,
} from '../localSelectors';
import { Cell } from '../types';

const selectSelectedCell = createSelector(getLocalState, getSelectedCell);

export const makeIsSelectedCell = () =>
  createSelector([selectSelectedCell, (_, cell: Cell) => cell], (selectedCell, cell) =>
    selectedCell
      ? selectedCell.gridId === cell.gridId &&
        selectedCell.rowId === cell.rowId &&
        selectedCell.columnId === cell.columnId
      : false,
  );

export const selectSelectedComponent = createSelector(getLocalState, getSelectedComponent);

export const selectCurrentlyHoveredCell = createSelector(getLocalState, getHoveredCell);
