import _ from 'lodash';
import { PropsWithChildren, createContext, useContext, useEffect } from 'react';
import { FieldArrayWithId, UseFieldArrayReturn } from 'react-hook-form';
import { controlSettingsGenerator } from './Controls/DefaultSettings';
import { FormValues } from './Form';
import { generateNewControlName, generatePastedControlName } from './NameGenerator';
import {
  selectAllControlIdsAndTypes,
  selectClipboard,
} from './StateManagement/Selectors/ControlSelector';
import { useAppSelector } from './StateManagement/hooks';

const ControlSettingsContext = createContext<
  FieldArrayWithId<FormValues, 'controlSettings', 'key'>[] | null
>(null);

export const useControlSettingsContext = () => {
  const context = useContext(ControlSettingsContext);

  if (context === null) {
    throw Error('useControlSettingsContext must be used within a ControlSettingsProvider');
  }

  return context;
};

export const ControlSettingsProvider = ({
  children,
  ...props
}: PropsWithChildren<UseFieldArrayReturn<FormValues, 'controlSettings', 'key'>>) => {
  const allControlIdsAndTypes = useAppSelector(selectAllControlIdsAndTypes);
  const clipboard = useAppSelector(selectClipboard);

  const { fields, append, remove } = props;

  useEffect(() => {
    const itemsAdded = _.differenceBy(allControlIdsAndTypes, fields, 'id');

    if (itemsAdded.length === 0) {
      return;
    }

    if (itemsAdded.length > 1) {
      throw Error('More controls were added than expected');
    }

    const newItem = itemsAdded[0];

    if (newItem.id === clipboard?.newControlId) {
      const pastedControlName = generatePastedControlName(
        fields.map((x) => x.basicSettings.name),
        clipboard.originControl.settings.basicSettings.name,
      );

      append({
        ...clipboard.originControl.settings,
        id: newItem.id,
        type: clipboard.originControl.type,
        basicSettings: {
          ...clipboard.originControl.settings.basicSettings,
          name: pastedControlName,
        },
      });
    } else {
      const defaultName = generateNewControlName(fields.map((x) => x.basicSettings.name));

      append({
        id: newItem.id,
        ...controlSettingsGenerator(newItem.type, defaultName),
      });
    }
  }, [allControlIdsAndTypes, append, fields, clipboard]);

  useEffect(() => {
    const itemsRemoved = _.difference(
      fields.map((f) => f.id),
      allControlIdsAndTypes.map((x) => x.id),
    );
    if (itemsRemoved.length > 0) {
      const indexesToRemove = fields.reduce((indexes, current, index) => {
        if (itemsRemoved.includes(current.id)) {
          indexes.push(index);
        }

        return indexes;
      }, Array<number>());
      remove(indexesToRemove);
    }
  }, [allControlIdsAndTypes, remove, fields]);

  return (
    <ControlSettingsContext.Provider value={fields}>{children}</ControlSettingsContext.Provider>
  );
};
