import { TextInput, When } from '@airelogic/form-management/components';
import { Grid2 as Grid } from '@mui/material';
import { FieldErrors, useFormContext, useWatch } from 'react-hook-form';
import { AdditionalSettings } from '../../../Controls/DynamicDropdown';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';
import DataSource from './DataSource';
import DefaultSelectOptions from './DefaultSelectOptions';

interface Props {
  fieldIndex: number;
}

const DynamicDropdownAdditionalSettings = ({ fieldIndex }: Props) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<FormValues>();

  const getErrors = () => {
    return errors?.controlSettings?.[fieldIndex]?.additionalSettings as
      | FieldErrors<AdditionalSettings>
      | undefined;
  };

  const dataSourceWatch = useWatch({
    control,
    name: `controlSettings.${fieldIndex}.additionalSettings.dataSource`,
  });

  return (
    <>
      <PanelWithHeader title="Resource settings">
        <DataSource
          fieldIndex={fieldIndex}
          tooltip={'The source where the reference data will be loaded from'}
        />
        <TextInput
          type="text"
          label="Key"
          error={getErrors()?.resourceKey !== undefined}
          helperText={getErrors()?.resourceKey?.message}
          {...register(`controlSettings.${fieldIndex}.additionalSettings.resourceKey`)}
          required
        />
        <TextInput
          type="number"
          label="Version"
          error={getErrors()?.resourceVersion !== undefined}
          helperText={getErrors()?.resourceVersion?.message}
          {...register(`controlSettings.${fieldIndex}.additionalSettings.resourceVersion`, {
            valueAsNumber: true,
          })}
          required
        />
        <When condition={dataSourceWatch === 'aireforms'}>
          <TextInput
            type="text"
            label="Items XPath"
            error={getErrors()?.itemPath !== undefined}
            helperText={
              getErrors()?.itemPath?.message ?? 'XPath expression returning one node for each item.'
            }
            {...register(`controlSettings.${fieldIndex}.additionalSettings.itemPath`)}
            required
          />
          <TextInput
            type="text"
            label="Label XPath"
            error={getErrors()?.labelPath !== undefined}
            helperText={
              getErrors()?.labelPath?.message ?? 'XPath expression relative to an item node.'
            }
            {...register(`controlSettings.${fieldIndex}.additionalSettings.labelPath`)}
            required
          />
          <TextInput
            type="text"
            label="Value XPath"
            error={getErrors()?.valuePath !== undefined}
            helperText={
              getErrors()?.valuePath?.message ?? 'XPath expression relative to an item node.'
            }
            {...register(`controlSettings.${fieldIndex}.additionalSettings.valuePath`)}
            required
          />
        </When>
      </PanelWithHeader>
      <PanelWithHeader title="Additional settings">
        <Grid container>
          <DefaultSelectOptions fieldIndex={fieldIndex} supportsMultiSelect={true} />
        </Grid>
      </PanelWithHeader>
    </>
  );
};

export default DynamicDropdownAdditionalSettings;
