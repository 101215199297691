import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  borderLeft: {
    borderLeft: '1px solid #ccc',
  },

  borderRight: {
    borderRight: '1px solid #ccc',
  },

  borderTop: {
    borderTop: '1px solid #ccc',
  },

  borderBottom: {
    borderBottom: '1px solid #ccc',
  },

  border: {
    border: '1px solid #ccc',
  },

  repeatedGrid: {
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    border: `1px solid #fff`,
  },

  repeatedGridActive: {
    borderColor: `${theme.palette.primary.main}`,
  },

  icon: {
    cursor: 'pointer',
  },

  iconDisabled: {
    cursor: 'not-allowed',
  },

  iterationOptions: {
    background: '#FFF',
    width: '150px',
    display: 'block',
    height: '229px',
    zIndex: 2,
    position: 'absolute',
    border: '1px solid #DDDDDD',
    borderRadius: 4,
  },

  iterationOpsList: {
    padding: 0,
    textAlign: 'center',
  },

  iterationOpsListItem: {
    cursor: 'pointer',
    float: 'left',
    '&:hover': {
      backgroundColor: '#DDDDDD',
    },
    width: '100%',
    paddingTop: 5,
  },

  iterationOpsListItemDisabled: {
    cursor: 'not-allowed',
    float: 'left',
    width: '100%',
    paddingTop: 5,
  },

  iterationOpsListIcons: {
    float: 'left',
  },

  iterationOpsItemText: {
    float: 'left',
    marginTop: 4,
    marginLeft: 5,
  },

  divider: {
    background: '#e5e5e5',
    height: '1px',
    float: 'left',
    width: '100%',
  },

  gridOptions: {
    marginLeft: -24,
  },

  deleteGrid: {
    cursor: 'pointer',
  },

  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
