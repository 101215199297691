import React from 'react';
import { BuildingBlock } from '../BuildingBlock';
import { useRollback } from '../Service';

const ConfirmationText = ({ buildingBlock }: { buildingBlock: BuildingBlock }) => {
  return (
    <>
      <p>
        Rolling back the building block <b>{buildingBlock.blockKey}</b> will replace the version in
        the designer with the last imported version. The curent changes will be lost forever.
      </p>
      <p>Are you sure you wish to continue?</p>
    </>
  );
};

export const useRollbackConfirmation = (buildingBlock: BuildingBlock) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState<boolean>(false);
  const { mutate: rollback } = useRollback(buildingBlock.orbeonId);

  const handleRollbackClick = () => {
    setShowConfirmationDialog(true);
  };

  const cancelRollback = () => {
    setShowConfirmationDialog(false);
  };

  const confirmRollback = async () => {
    setShowConfirmationDialog(false);
    rollback();
  };

  return {
    handleRollbackClick,
    rollbackConfirmationDialogProps: {
      open: showConfirmationDialog,
      handleCancel: cancelRollback,
      handleConfirm: confirmRollback,
      confirmationText: <ConfirmationText buildingBlock={buildingBlock} />,
    },
  };
};
