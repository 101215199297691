import Constraints from './Constraints';
import DataType from './DataType';
import GlobalAlertMessage from './GlobalAlertMessage';
import Required from './Required';

interface Props {
  fieldIndex: number;
}

const ValidationAndAlerts = ({ fieldIndex }: Props) => {
  return (
    <>
      <GlobalAlertMessage fieldIndex={fieldIndex} />
      <Required fieldIndex={fieldIndex} />
      <DataType fieldIndex={fieldIndex} />
      <Constraints fieldIndex={fieldIndex} />
    </>
  );
};

export default ValidationAndAlerts;
