import { IconButton, IconButtonProps, SvgIcon, Tooltip } from '@mui/material';
import { useStyles } from './IconButton.styles';
import { ReactComponent as NewVersionIconSVG } from './SVGs/new-version.svg';

interface Props extends Omit<IconButtonProps, 'component' | 'className' | 'children'> {
  tooltipText?: string;
}

const NewVersionIcon = (props: Props) => {
  const { classes } = useStyles();
  const { tooltipText = '', ...rest } = props;

  return (
    <Tooltip title={tooltipText}>
      <IconButton className={classes.strokeButton} {...rest}>
        <SvgIcon component={NewVersionIconSVG} className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
};

export default NewVersionIcon;
