import { Container, Theme } from '@mui/material';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';
import FooterBar from './FooterBar';
import NavMenu from './NavMenu';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

interface ILayout {
  children: NonNullable<React.ReactNode>;
}

const Layout = ({ children }: ILayout) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <NavMenu />
      <main className={classes.main}>
        <Container maxWidth={false}>{children}</Container>
      </main>
      <FooterBar />
    </div>
  );
};

export default Layout;
