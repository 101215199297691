import { createTheme } from '@mui/material';
// "#251430" -- purple
export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
  palette: {
    background: {
      default: '#FAFAFA',
    },
    primary: {
      main: '#3463FD', // Blue
      contrastText: '#fff',
    },
    secondary: {
      main: '#33444E', // Grey
      //contrastText: "#000",
    },
    info: {
      main: '#33444E', // Grey
      contrastText: '#fff',
      light: '#889AA5',
    },
    text: {
      primary: '#32444E',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: 'Hind, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '14px',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: { backgroundColor: '#251430' },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          opacity: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 'medium',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: 'medium',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 'medium',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          height: '100%',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
  },
});
