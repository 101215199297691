import { MustNotBeEmptyText } from '@airelogic/form-management/common/validations';
import { z } from 'zod';
import { SwitchIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = ['boolean'];

export const validationSchema = z
  .object({
    stopCondition: z.enum(['', 'true', 'false']),
    stopConditionText: z.string(),
    pdfHighlight: z.enum(['', 'true', 'false']),
  })
  .superRefine((val, ctx) => {
    if (val.stopCondition !== '') {
      if (['', '<p></p>', '<p><br></p>'].indexOf(val.stopConditionText) !== -1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: MustNotBeEmptyText,
          path: [`stopConditionText`],
        });
      }
    }
  });

export type AdditionalSettings = z.infer<typeof validationSchema>;

export const yesNoControl: ControlDefinition<AdditionalSettings> = {
  displayName: 'Yes/No Buttons',
  icon: SwitchIcon,
  type: 'BOOLEAN_BUTTONS',
  supportedDataTypes: dataTypes,
  additionalSettings: {
    validationSchema: validationSchema,
    defaults: {
      stopCondition: '',
      stopConditionText: '',
      pdfHighlight: '',
    },
  },
  supportsInitialValue: true,
};
