import { RichText, SelectInput } from '@airelogic/form-management/components';
import { Controller, useFormContext } from 'react-hook-form';
import { HighlightOption } from '../../../Controls/ExplanatoryText';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';
import OmitFromSubmission from './OmitFromSubmission';

interface Props {
  fieldIndex: number;
}
const options: { value: HighlightOption; label: string }[] = [
  {
    value: '',
    label: 'Default',
  },
  {
    value: 'highlight',
    label: 'Highlight',
  },
  {
    value: 'success',
    label: 'Highlight with success',
  },
  {
    value: 'warning',
    label: 'Highlight with warning',
  },
  {
    value: 'danger',
    label: 'Highlight with danger',
  },
];

const ExplanatoryTextAdditionalSettings = ({ fieldIndex }: Props) => {
  const { register } = useFormContext<FormValues>();

  return (
    <PanelWithHeader title="Additional Settings">
      <SelectInput
        id="HighlightOptions"
        label="Highlight Options"
        {...register(`controlSettings.${fieldIndex}.additionalSettings.highlight`)}
      >
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </SelectInput>
      <Controller
        name={`controlSettings.${fieldIndex}.additionalSettings.text`}
        render={({ field: { onChange, value } }) => (
          <RichText id="explanation" label="Text" defaultValue={value} onChange={onChange} />
        )}
      />
      <OmitFromSubmission fieldIndex={fieldIndex} />
    </PanelWithHeader>
  );
};

export default ExplanatoryTextAdditionalSettings;
