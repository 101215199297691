import { Button, Dialog } from '@mui/material';
import { useState } from 'react';
import FormContextDialogContent from './FormContextDialogContent';

const FormContextDialog = () => {
  const [DialogOpen, setDialogOpen] = useState<boolean>(false);

  const onOpenDialog = () => {
    setDialogOpen(true);
  };

  return (
    <>
      <Button onClick={onOpenDialog} color={'primary'}>
        Configure Form Context
      </Button>
      <Dialog open={DialogOpen} maxWidth={'lg'} fullWidth={true}>
        <FormContextDialogContent onClose={() => setDialogOpen(false)} />
      </Dialog>
    </>
  );
};

export default FormContextDialog;
