import { TextInput } from '@airelogic/form-management/components';
import { Box, Grid2 as Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '../Form';
import { selectSelectedComponent } from '../StateManagement/Selectors/LayoutSelectionsSelector';
import { useAppSelector } from '../StateManagement/hooks';
import { useStyles } from './PropertiesPanel.Styles';

const appNameHelp =
  'Identifies a group of building blocks, for instance by functionality or department (e.g."sales"). If you have one group of building blocks, you can use your organization name (e.g. "acme").';
const blockNameHelp =
  'Identifies this specific building blocks (e.g. "health-record" or "claim"). This name shows in the URL, so we recommend you use only lowercases characters.';

const PropertiesPanel = () => {
  const { classes } = useStyles();

  const {
    register,
    formState: { errors },
  } = useFormContext<FormValues>();

  const selectedComponent = useAppSelector(selectSelectedComponent);

  if (selectedComponent) return null;

  return (
    <Grid className={classes.outerGrid}>
      <Typography variant="h6">Building Block Properties</Typography>
      <Box>
        <TextInput
          required
          helperText={errors?.properties?.applicationName?.message ?? appNameHelp}
          error={errors?.properties?.applicationName !== undefined}
          id="application-name"
          label="Application Name"
          {...register('properties.applicationName')}
        />
      </Box>
      <Box>
        <TextInput
          required
          helperText={errors?.properties?.blockName?.message ?? blockNameHelp}
          error={errors?.properties?.blockName !== undefined}
          id="building-block-name"
          label="Building Block Name"
          {...register('properties.blockName')}
        />
      </Box>
      <Box>
        <TextInput
          helperText="Building block title in the current language"
          id="building-block-title"
          label="Building Block Title"
          {...register('properties.blockTitle')}
        />
      </Box>
      <Box>
        <TextInput
          multiline
          minRows={3}
          helperText="Building block description in the current language"
          id="building-block-description"
          label="Building Block Description"
          {...register('properties.blockDescription')}
        />
      </Box>
    </Grid>
  );
};

export default PropertiesPanel;
