import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ExistingReferenceData } from './Types';

export const useGetAllReferenceData = () => {
  return useQuery({
    queryKey: ['referencedata-table'],
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<ExistingReferenceData[]>(
        '/internalapi/formbuilding/referencedata',
        { signal },
      );
      return data;
    },
  });
};
