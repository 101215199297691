import { SingleCheckbox, TextInput } from '@airelogic/form-management/components';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { AdditionalSettings } from '../../../Controls/Slider';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';

interface Props {
  fieldIndex: number;
}

export const HelperText = {
  lowerValueText: 'Text displayed at the left side of the slider',
  upperValueText: 'Text displayed at the right of the slider',
  minValueText: 'The lowest selectable value along the slider',
  maxValueText: 'The highest selectable value along the slider',
};

const SliderAdditionalSettings = ({ fieldIndex }: Props) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<FormValues>();

  const getErrors = () => {
    return errors?.controlSettings?.[fieldIndex]?.additionalSettings as
      | FieldErrors<AdditionalSettings>
      | undefined;
  };

  return (
    <PanelWithHeader title="Additional Settings">
      <TextInput
        type="text"
        label="Lower Value Text"
        helperText={HelperText.lowerValueText}
        {...register(`controlSettings.${fieldIndex}.additionalSettings.lowerValueText`)}
      />
      <TextInput
        type="text"
        label="Upper Value Text"
        helperText={HelperText.upperValueText}
        {...register(`controlSettings.${fieldIndex}.additionalSettings.upperValueText`)}
      />
      <TextInput
        type="number"
        label="Minimum Value"
        required
        error={!!getErrors()?.minimumValue}
        helperText={getErrors()?.minimumValue?.message ?? HelperText.minValueText}
        {...register(`controlSettings.${fieldIndex}.additionalSettings.minimumValue`, {
          valueAsNumber: true,
          deps: [`controlSettings.${fieldIndex}.additionalSettings.maximumValue`],
        })}
      />
      <TextInput
        type="number"
        label="Maximum Value"
        required
        error={!!getErrors()?.maximumValue}
        helperText={getErrors()?.maximumValue?.message ?? HelperText.maxValueText}
        {...register(`controlSettings.${fieldIndex}.additionalSettings.maximumValue`, {
          valueAsNumber: true,
        })}
      />
      <SingleCheckbox
        label="Show Value"
        control={control}
        name={`controlSettings.${fieldIndex}.additionalSettings.showValue`}
      />

      <SingleCheckbox
        label="Show Value As Tooltip"
        control={control}
        name={`controlSettings.${fieldIndex}.additionalSettings.showValueAsTooltip`}
      />
    </PanelWithHeader>
  );
};

export default SliderAdditionalSettings;
