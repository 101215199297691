import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  grid: {
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  deleteGrid: {
    cursor: 'pointer',
  },

  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
