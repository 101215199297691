import { SingleCheckbox } from '@airelogic/form-management/components';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormValues } from '../../../Form';

interface Props {
  fieldIndex: number;
}

const OmitFromSubmission = ({ fieldIndex }: Props) => {
  const { control, setValue } = useFormContext<FormValues>();

  const omitFromSubmission = useWatch({
    control,
    name: `controlSettings.${fieldIndex}.additionalSettings.omitFromSubmission`,
  });

  useEffect(() => {
    if (omitFromSubmission) {
      setValue(`controlSettings.${fieldIndex}.basicSettings.includeInExternalNotifications`, false);
    }
  }, [omitFromSubmission, fieldIndex, setValue]);

  return (
    <SingleCheckbox
      label="Omit from submission"
      control={control}
      name={`controlSettings.${fieldIndex}.additionalSettings.omitFromSubmission`}
    />
  );
};

export default OmitFromSubmission;
