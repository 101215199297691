import { useFormContext } from 'react-hook-form';
import { FormValues } from '../../../Form';
import AnnotatedImage from './AnnotatedImage';
import BasicSelectControl from './BasicSelectControl';
import ExplanatoryText from './ExplanatoryText';
import ImageSelect from './ImageSelect';
import RichTextSelect from './RichTextSelect';
import StaticImage from './StaticImage';

interface Props {
  settingsIndex: number;
}

const Resolver = ({ settingsIndex }: Props) => {
  const { watch } = useFormContext<FormValues>();
  const controlType = watch(`controlSettings.${settingsIndex}.type`);

  switch (controlType) {
    case 'EXPLANATORY_TEXT':
      return <ExplanatoryText settingsIndex={settingsIndex} />;
    case 'RADIO_BUTTON':
    case 'DROPDOWN':
    case 'CHECKBOX':
      return <BasicSelectControl settingsIndex={settingsIndex} />;
    case 'RICH_TEXT_SELECT':
      return <RichTextSelect settingsIndex={settingsIndex} />;
    case 'IMAGE_SELECT':
      return <ImageSelect settingsIndex={settingsIndex} />;
    case 'ANNOTATED_IMAGE':
    case 'ADVANCED_ANNOTATED_IMAGE':
      return <AnnotatedImage settingsIndex={settingsIndex} />;
    case 'STATIC_IMAGE':
      return <StaticImage settingsIndex={settingsIndex} />;
  }

  return null;
};

export default Resolver;
