import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ReferenceDataSet } from '../Types';

type Params = {
  key: string;
  version?: string;
};

export const useGetReferenceData = ({ key, version }: Params) => {
  return useQuery({
    queryKey: ['referencedata', { key, version }],
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<ReferenceDataSet>(
        `/internalapi/formbuilding/referencedata/${key}${version ? `/${version}` : ''}`,
        { signal },
      );
      return data;
    },
  });
};
