import { DeleteIcon, SingleCheckbox, TextInput } from '@airelogic/form-management/components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button } from '@mui/material';
import { Fragment } from 'react';
import { FieldErrors, useFieldArray, useFormContext } from 'react-hook-form';
import { AdditionalSettings } from '../../../Controls/ServerSideCalculation';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';
import DataSource from './DataSource';

interface Props {
  fieldIndex: number;
}

const ServerSideCalcAdditionalSettings = ({ fieldIndex }: Props) => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<FormValues>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `controlSettings.${fieldIndex}.additionalSettings.valuesOfInterest`,
  });

  const onAppendDataRow = () => {
    append({
      key: '',
      xpath: '',
    });
  };

  const onRemoveDataRow = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  const getErrors = () => {
    return errors?.controlSettings?.[fieldIndex]?.additionalSettings as
      | FieldErrors<AdditionalSettings>
      | undefined;
  };

  return (
    <PanelWithHeader title="Additional Settings">
      <DataSource
        fieldIndex={fieldIndex}
        tooltip={'The source where the calculation will be loaded from'}
      />
      <TextInput
        type="text"
        label="Calculation Key"
        helperText={getErrors()?.key?.message ?? ''}
        error={getErrors()?.key?.message !== undefined}
        {...register(`controlSettings.${fieldIndex}.additionalSettings.key`)}
      />
      <TextInput
        type="number"
        label="Version"
        helperText={getErrors()?.version?.message ?? ''}
        error={getErrors()?.version?.message !== undefined}
        {...register(`controlSettings.${fieldIndex}.additionalSettings.version`, {
          valueAsNumber: true,
        })}
      />
      <Box>
        <SingleCheckbox
          label="Fire Immediately"
          control={control}
          name={`controlSettings.${fieldIndex}.additionalSettings.fireImmediately`}
        />
      </Box>

      <Button
        startIcon={<AddCircleIcon />}
        aria-label="add new row data from the iteration array"
        onClick={onAppendDataRow}
      >
        Add New Row Data
      </Button>
      {fields.map((item, index) => {
        return (
          <Fragment key={item.id}>
            <hr />
            <p>Calculation</p>
            <TextInput
              type="text"
              label="Key"
              helperText={getErrors()?.valuesOfInterest?.[index]?.key?.message}
              error={getErrors()?.valuesOfInterest?.[index]?.key?.message !== undefined}
              {...register(
                `controlSettings.${fieldIndex}.additionalSettings.valuesOfInterest.${index}.key`,
              )}
            />
            <TextInput
              type="text"
              label="XPath"
              multiline={true}
              minRows={3}
              helperText={getErrors()?.valuesOfInterest?.[index]?.xpath?.message}
              error={getErrors()?.valuesOfInterest?.[index]?.xpath?.message !== undefined}
              {...register(
                `controlSettings.${fieldIndex}.additionalSettings.valuesOfInterest.${index}.xpath`,
              )}
            />
            <DeleteIcon
              disabled={fields.length === 1}
              aria-label="remove row data from the iteration array"
              onClick={() => {
                onRemoveDataRow(index);
              }}
            />
          </Fragment>
        );
      })}
    </PanelWithHeader>
  );
};

export default ServerSideCalcAdditionalSettings;
