import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  headerText: {
    color: '#33444E',
    fontSize: 30,
  },

  formInput: {
    margin: theme.spacing(3),
  },

  surface: {
    background: '#EDF0F2',
    padding: theme.spacing(2),
  },

  itemPanel: {
    background: '#EDF0F2',
    padding: theme.spacing(2),
    height: '100%',
  },

  outerGrid: {
    margin: 0,
    marginBottom: theme.spacing(2),
    flex: 1,
  },

  tabsHolder: {
    flexGrow: 1,
    backgroundColor: 'red',
    display: 'flex',
  },

  tabs: {},

  addButton: {
    color: '#3463FD',
  },

  border: {
    border: '1px solid #ccc',
    borderRadius: 4,
  },

  borderRight: {
    borderRight: '1px solid #ccc',
  },

  opsCell: {
    width: '3%',
  },

  headerRow: {
    backgroundColor: '#DBE0E4',
  },

  table: {
    height: 'auto',
    maxHeight: 400,
    overflowY: 'auto',
    border: '1px solid #ccc',
    borderRadius: 4,
  },
}));
