import { Box, Paper, Typography } from '@mui/material';
import { ReactComponent as WarningIcon } from './Assets/Warning_F4H_Icon.svg';
import { useStyles } from './ErrorTemplate.styles';

interface Props {
  header: string;
  message: string;
}

const ErrorTemplate = ({ header, message }: Props) => {
  const { classes } = useStyles();
  return (
    <Paper className={classes.paper}>
      <Box className={classes.box}>
        <WarningIcon className={classes.warningIcon} title="warning icon" />
        <br />
        <Typography variant="h4" align="center">
          {header}
        </Typography>

        <Typography variant="h6" align="center">
          {message}
        </Typography>
      </Box>
    </Paper>
  );
};

export default ErrorTemplate;
