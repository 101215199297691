import { TextInput } from '@airelogic/form-management/components';
import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { getControlDefinitionByType } from '../../Controls/Controls';
import { FormValues } from '../../Form';
import { useNameValidationDeps } from '../useNameValidationDeps';

interface Props {
  fieldIndex: number;
}

const ControlName = ({ fieldIndex }: Props) => {
  const { classes } = useStyles();
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<FormValues>();

  const controlType = watch(`controlSettings.${fieldIndex}.type`);
  const controlDefinition = getControlDefinitionByType(controlType);

  const validationDependencies = useNameValidationDeps();

  return (
    <div className={classes.controlNameContainer}>
      <div className={classes.controlType}>
        <Typography variant="h6">{controlDefinition.displayName}</Typography>
        {/* <HelpIcon className={classes.controlTypeHelpIcon} /> */}
      </div>
      <TextInput
        type="text"
        label="Control Name"
        error={errors?.controlSettings?.[fieldIndex]?.basicSettings?.name !== undefined}
        helperText={errors?.controlSettings?.[fieldIndex]?.basicSettings?.name?.message}
        required
        {...register(`controlSettings.${fieldIndex}.basicSettings.name`, {
          deps: validationDependencies,
        })}
      />
    </div>
  );
};

export default ControlName;

const useStyles = makeStyles()(() => ({
  controlNameContainer: {
    paddingTop: 10,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 15,
    backgroundColor: '#DBE0E4',
  },
  controlName: {
    backgroundColor: '#FFF',
    borderRadius: 4,
  },
  controlType: {
    marginTop: 5,
    marginBottom: 5,
  },
  controlTypeLabel: {
    float: 'left',
  },
  controlTypeHelpIcon: {
    marginTop: -2,
    marginLeft: 15,
    cursor: 'pointer',
  },
  controlNameLabel: {
    position: 'absolute',
  },
}));
