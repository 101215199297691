import { FunctionComponent, SVGProps } from 'react';
import { ControlType } from '../Controls/types';
import { selectCanAddControl } from '../StateManagement/Selectors/ControlSelector';
import { useAppDispatch, useAppSelector } from '../StateManagement/hooks';
import { addControl } from '../StateManagement/layoutSlice';

import ControlPaneItem from './ControlPaneItem';

interface Props {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  label: string;
  type: ControlType;
}

export const ControlListItem = ({ icon, label, type }: Props) => {
  const disabled = useAppSelector(selectCanAddControl) === false;

  const dispatch = useAppDispatch();

  const clickHandler = () => {
    dispatch(addControl({ controlType: type }));
  };

  return <ControlPaneItem disabled={disabled} icon={icon} label={label} onClick={clickHandler} />;
};
