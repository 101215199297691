import { LoadingSpinner } from '@airelogic/common';
import { ErrorTemplate } from '@airelogic/form-management/common';
import axios from 'axios';
import { useParams } from 'react-router';
import Page from './Page';
import { useAppDispatch } from './StateManagement/hooks';
import { rehydrate } from './StateManagement/layoutSlice';
import useGetRehydrateData from './useGetRehydrateData';

interface Location {
  orbeonId: string;
}

const EditExisting = () => {
  const params = useParams<Location>();
  const dispatch = useAppDispatch();

  const { status, data, error } = useGetRehydrateData(params.orbeonId);

  if (status === 'pending') {
    return <LoadingSpinner fullscreen={true} />;
  }
  if (status === 'error') {
    if (axios.isAxiosError(error) && error.response?.status === 404)
      return (
        <ErrorTemplate
          header={'Building block not found'}
          message={"The building block you're looking for cannot be found."}
        />
      );

    throw error;
  }

  dispatch(rehydrate({ existingState: data }));

  const existingSettings = {
    controlSettings: data.controls.map((c) => ({ id: c.id, ...c.settings, type: c.type })),
    sectionSettings: data.sectionSettings,
    repeatSettings: data.repeatSettings,
    properties: data.properties,
  };

  return <Page id={data.id} settings={existingSettings} />;
};

export default EditExisting;
