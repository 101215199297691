import { ScribbleIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = ['string'];

export const scribbleControl: ControlDefinition = {
  displayName: 'Scribble',
  icon: ScribbleIcon,
  type: 'SCRIBBLE',
  supportedDataTypes: dataTypes,
  formulaOverrides: {
    allowCalculatedValue: false,
    allowInitialValue: false,
    allowReadOnly: false,
  },
  supportsInitialValue: false,
};
