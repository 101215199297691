import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  cell: {
    flex: 1,
    padding: 10,
    minHeight: 100,
    height: '100%',
    border: '1px solid #ccc',
  },

  cellActive: {
    borderColor: `${theme.palette.primary.main}`,
  },

  cellHovered: {
    borderColor: `${theme.palette.primary.light}`,
  },

  component: {
    float: 'left',
    marginTop: 5,
    width: '100%',
  },

  componentClicked: {
    float: 'left',
    marginTop: 5,
    width: '100%',
  },
}));
