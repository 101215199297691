import { ConfirmationDialog, IconButton, When } from '@airelogic/form-management/components';
import DeleteIcon from '@mui/icons-material/Delete';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import { Grid2 as Grid } from '@mui/material';
import { useMemo, useState } from 'react';
import {
  makeCanAddColumnToGrid,
  makeCanDeleteColumnInGrid,
  makeSelectControlCountInColumn,
} from '../../StateManagement/Selectors/GridSelector';
import { selectCurrentlyHoveredCell } from '../../StateManagement/Selectors/LayoutSelectionsSelector';
import { useAppDispatch, useAppSelector } from '../../StateManagement/hooks';
import { addColumnToGrid, deleteColumn } from '../../StateManagement/layoutSlice';

interface OptionProps {
  display?: boolean;
  gridId: string;
  columnId: string;
}

const ColumnOptions = ({ gridId, columnId, display = false }: OptionProps) => {
  const dispatch = useAppDispatch();

  const selectControlCountInColumn = useMemo(makeSelectControlCountInColumn, []);
  const selectCanDeleteColumn = useMemo(makeCanDeleteColumnInGrid, []);
  const selectCanAddColumn = useMemo(makeCanAddColumnToGrid, []);
  const [isOptionsHovered, setIsOptionsHovered] = useState(false);

  const isColumnHovered = useAppSelector(
    (state) => selectCurrentlyHoveredCell(state)?.columnId === columnId,
  );

  const isHovered = isOptionsHovered || isColumnHovered || display;

  const controlCount = useAppSelector((state) => selectControlCountInColumn(state, columnId));
  const canDelete = useAppSelector((state) => selectCanDeleteColumn(state, gridId));
  const canAdd = useAppSelector((state) => selectCanAddColumn(state, gridId));

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const onAddColumnBefore = () => {
    dispatch(addColumnToGrid({ gridId, insertPosition: 'before', relativeColumnId: columnId }));
  };

  const onAddColumnAfter = () => {
    dispatch(addColumnToGrid({ gridId, insertPosition: 'after', relativeColumnId: columnId }));
  };

  const onDeleteColumn = () => {
    controlCount > 0 ? setShowConfirmDelete(true) : removeColumn();
  };

  const confirmDelete = () => {
    setShowConfirmDelete(false);
    removeColumn();
  };

  const removeColumn = () => {
    dispatch(deleteColumn({ gridId, columnId }));
  };

  const cancelDelete = () => {
    setShowConfirmDelete(false);
  };

  const handleHover = () => {
    setIsOptionsHovered(true);
  };

  const handleUnhover = () => {
    setIsOptionsHovered(false);
  };

  return (
    <>
      <Grid
        style={{ height: '24px' }}
        onMouseEnter={handleHover}
        onMouseLeave={handleUnhover}
        size="grow"
      >
        <When condition={isHovered}>
          <Grid container>
            <Grid container justifyContent="flex-start" size={1}>
              <When condition={canAdd}>
                <IconButton tooltipText="Add a column to the left" onClick={onAddColumnBefore}>
                  <SkipPreviousIcon />
                </IconButton>
              </When>
            </Grid>
            <Grid container justifyContent="center" size={10}>
              <When condition={canDelete}>
                <IconButton tooltipText="Delete column" onClick={onDeleteColumn}>
                  <DeleteIcon />
                </IconButton>
              </When>
            </Grid>
            <Grid container justifyContent="flex-end" size={1}>
              <When condition={canAdd}>
                <IconButton tooltipText="Add a column to the right" onClick={onAddColumnAfter}>
                  <SkipNextIcon />
                </IconButton>
              </When>
            </Grid>
          </Grid>
        </When>
      </Grid>
      <ConfirmationDialog
        data-testid={`deleteColumnPrompt-${columnId}`}
        confirmationText={`Are you sure you want to delete the current column? ${controlCount} control(s) will be deleted.`}
        open={showConfirmDelete}
        handleCancel={cancelDelete}
        handleConfirm={confirmDelete}
      />
    </>
  );
};

export default ColumnOptions;
