import { RichText } from '@airelogic/form-management/components';
import { Controller, useFormContext } from 'react-hook-form';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';

interface Props {
  fieldIndex: number;
}

const HelpMessage = ({ fieldIndex }: Props) => {
  const { control } = useFormContext<FormValues>();

  return (
    <PanelWithHeader title="Help Message">
      <Controller
        control={control}
        name={`controlSettings.${fieldIndex}.basicSettings.helpMessage`}
        render={({ field: { onChange, value } }) => (
          <RichText defaultValue={value} onChange={onChange} />
        )}
      />
    </PanelWithHeader>
  );
};

export default HelpMessage;
