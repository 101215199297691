import PanelWithHeader from '../../PanelWithHeader';
import OmitFromSubmission from './OmitFromSubmission';

interface Props {
  fieldIndex: number;
}

const PasswordFieldAdditionalSettings = ({ fieldIndex }: Props) => {
  return (
    <PanelWithHeader title="Additional Settings">
      <OmitFromSubmission fieldIndex={fieldIndex} />
    </PanelWithHeader>
  );
};

export default PasswordFieldAdditionalSettings;
