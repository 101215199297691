import { useApplicationContext } from '@airelogic/form-management/common/app-context';
import { When } from '@airelogic/form-management/components';
import { Container } from '@mui/material';
import { Route, useRouteMatch } from 'react-router';
import Switch from '../ErrorHandlingSwitch';
import AireIdentityLoginPage from './OIDCLoginPage';

const Routing = () => {
  const match = useRouteMatch();

  const { authMode } = useApplicationContext();
  return (
    <Container maxWidth="sm">
      <When condition={authMode === 'oidc'}>
        <Switch>
          <Route path={`${match.path}/login`} component={AireIdentityLoginPage} />
        </Switch>
      </When>
    </Container>
  );
};

export default Routing;
