import { TextField, TextFieldProps } from '@mui/material';
import * as React from 'react';
import InputTooltip from '../Tooltips/InputTooltip';

interface Props extends Omit<TextFieldProps, 'variant' | 'ref' | 'inputRef' | 'size' | 'margin'> {
  tooltip?: NonNullable<React.ReactNode>;
  readOnly?: boolean;
}

const TextInput = React.forwardRef(
  (props: Props, ref: React.Ref<HTMLInputElement>): React.ReactElement => {
    const { tooltip, readOnly = false, slotProps, InputProps, ...rest } = props;

    return (
      <TextField
        id={props.id ?? props.name}
        fullWidth
        margin="dense"
        inputRef={ref}
        variant="outlined"
        size="small"
        {...rest}
        slotProps={{
          input: {
            startAdornment: tooltip ? <InputTooltip title={tooltip} /> : undefined,
            readOnly: readOnly,
            ...InputProps,
          },

          inputLabel: { shrink: true },
          ...slotProps,
        }}
      />
    );
  },
);

export default TextInput;
