import { Can } from '@airelogic/form-management/common/app-context';
import { NavLinkButton } from '@airelogic/form-management/components';
import { Container, Theme } from '@mui/material';
import { useRouteMatch } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import FormsTable from './FormsTable';

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    marginBottom: theme.spacing(1),
  },
}));

const Page = () => {
  const { classes } = useStyles();
  const { path } = useRouteMatch();

  return (
    <Container>
      <Can I="create" a="Form">
        <NavLinkButton
          variant="contained"
          color="primary"
          className={classes.button}
          to={`${path}/new`}
        >
          Create new
        </NavLinkButton>
      </Can>

      <FormsTable></FormsTable>
    </Container>
  );
};

export default Page;
