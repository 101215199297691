import { z } from 'zod';
import { ExplanatoryTextIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const validationSchema = z.object({
  text: z.string(),
  highlight: z.enum(['', 'highlight', 'success', 'warning', 'danger']),
  omitFromSubmission: z.boolean(),
});

export type HighlightOption = z.infer<typeof validationSchema.shape.highlight>;

const dataTypes: DataType[] = ['string'];

export type AdditionalSettings = z.infer<typeof validationSchema>;

export const explanatoryTextControl: ControlDefinition<AdditionalSettings> = {
  displayName: 'Explanatory Text',
  icon: ExplanatoryTextIcon,
  type: 'EXPLANATORY_TEXT',
  supportedDataTypes: dataTypes,
  additionalSettings: {
    validationSchema,
    defaults: {
      highlight: '',
      text: '',
      omitFromSubmission: false,
    },
  },
  supportsInitialValue: false,
};
