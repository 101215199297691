import { RootState } from './store';
import { Cell, Grid, IState } from './types';

export const getLocalState = (state: RootState) => state.layout;

//TODO: Look at splitting this out if it gets too large
/* control */
export const getAllControls = (state: IState) => state.controls;
export const getControl = (state: IState, id: string) => state.controls.find((x) => x.id === id)!; //Move to byId;
export const getClipboard = (state: IState) => getSelections(state).clipboard;

export const getControlForCell = (state: IState, cell: Cell) =>
  getAllControls(state).find(
    (component) => component.cell.columnId === cell.columnId && component.cell.rowId === cell.rowId,
  );

export const getControlsForGrid = (state: IState, gridId: string) =>
  getAllControls(state).filter((component) => component.cell.gridId === gridId);

export const getSelectedControl = (state: IState) => {
  const selectedComponent = getSelectedComponent(state);
  return selectedComponent && selectedComponent.type === 'control'
    ? {
        id: selectedComponent.id,
        navigateToError: !!selectedComponent.navigateToError,
      }
    : undefined;
};

export const isSelectedControl = (state: IState, controlId: string) => {
  const selectedControl = getSelectedControl(state);
  return selectedControl && selectedControl.id === controlId;
};

export const getControlsInRow = (state: IState, rowId: string) =>
  state.controls.filter((c) => c.cell.rowId === rowId);

/* selections */
export const getSelections = (state: IState) => state.layoutSelections;
export const getSelectedComponent = (state: IState) => getSelections(state).selectedComponent;
export const getHoveredCell = (state: IState) => getSelections(state).hoveredCell;
export const getSelectedCell = (state: IState) => getSelections(state).selectedCell;

/* sections */
export const getAllSectionIds = (state: IState) => state.sections.allIds;
export const canDeleteSection = (state: IState) => getAllSectionIds(state).length > 1;

export const getSelectedSection = (state: IState) => {
  const selectedComponent = getSelectedComponent(state);
  return selectedComponent && selectedComponent.type === 'section'
    ? {
        id: selectedComponent.id,
        navigateToError: !!selectedComponent.navigateToError,
      }
    : undefined;
};

export const isSelectedSection = (state: IState, sectionId: string) => {
  const selectedSection = getSelectedSection(state);

  return selectedSection && selectedSection.id === sectionId;
};

/* grids */
export const getAllGrids = (state: IState) => Object.values(state.grids.byId);
export const getAllGridIds = (state: IState) => state.grids.allIds;

export const getGridById = (state: IState, id: string) => state.grids.byId[id];

export const getRepeatingGridById = (state: IState, id: string) => {
  const grid = getGridById(state, id);
  return grid.isRepeated ? grid : undefined;
};

export const getGridIdsInSection = (state: IState, sectionId: string) =>
  getAllGridIds(state).filter((x) => getGridById(state, x).sectionId === sectionId);

export const canDeleteGrid = (state: IState, gridId: string) =>
  getGridIdsInSection(state, getGridById(state, gridId).sectionId).length > 1;

export const canDeleteRowInGrid = (grid: Grid) => grid.rowIds.length > 1;
export const canDeleteColumnInGrid = (grid: Grid) => grid.columnIds.length > 1;

export const canSplitGrid = (state: IState, gridId: string, rowId: string) => {
  const grid = getGridById(state, gridId);
  return !grid.isRepeated && grid.rowIds.indexOf(rowId) > 0;
};

export const getCellsInRow = (state: IState, gridId: string, rowId: string) => {
  const grid = getGridById(state, gridId);
  const controls = getControlsInRow(state, rowId);

  const columns = [];
  for (let i = 0; i < grid.columnIds.length; i++) {
    const columnId = grid.columnIds[i];
    const control = controls.find((c) => c.cell.columnId === columnId);
    if (control) {
      columns.push({
        columnId,
        cellSpan: control.cellSpan,
      });
      i += control.cellSpan - 1;
    } else {
      columns.push({
        columnId,
        cellSpan: 1,
      });
    }
  }
  return columns;
};

export const getNextCell = (
  state: IState,
  currentCell: Cell,
  currentCellSpan: number,
): Cell | undefined => {
  const grid = getGridById(state, currentCell.gridId);

  const currentColumnIndex =
    grid.columnIds.findIndex((c) => c === currentCell.columnId) + currentCellSpan - 1;
  if (currentColumnIndex === grid.columnIds.length - 1) {
    return undefined;
  }
  return {
    gridId: currentCell.gridId,
    rowId: currentCell.rowId,
    columnId: grid.columnIds[currentColumnIndex + 1],
  };
};

export const getSelectedGrid = (state: IState) => {
  const selectedComponent = getSelectedComponent(state);
  return selectedComponent && selectedComponent.type === 'grid'
    ? {
        id: selectedComponent.id,
        navigateToError: !!selectedComponent.navigateToError,
      }
    : undefined;
};

export const isSelectedGrid = (state: IState, gridId: string) => {
  const selectedGrid = getSelectedGrid(state);

  return selectedGrid && selectedGrid.id === gridId;
};

const MAX_COLUMNS = 8;
export const canAddColumnToGrid = (grid: Grid) => grid.columnIds.length < MAX_COLUMNS;
