import { OrderedListNoStyle, TextInput } from '@airelogic/form-management/components';
import { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { getControlDefinitionByType } from '../../../Controls/Controls';
import { FormValues } from '../../../Form';
import { makeIsRepeatingGrid } from '../../../StateManagement/Selectors/GridSelector';
import { useAppSelector } from '../../../StateManagement/hooks';
import PanelWithHeader from '../../PanelWithHeader';

interface Props {
  fieldIndex: number;
  gridId: string;
}

const RepeatedControlDefaultValues = ({ fieldIndex, gridId }: Props) => {
  const { control, register, getValues } = useFormContext<FormValues>();
  const selectIsRepeatingGrid = useMemo(makeIsRepeatingGrid, []);
  const controlType = getValues(`controlSettings.${fieldIndex}.type`);
  const supportsInitialValue = getControlDefinitionByType(controlType).supportsInitialValue;
  const isRepeatingGrid = useAppSelector((state) => selectIsRepeatingGrid(state, gridId));

  const { fields } = useFieldArray({
    control,
    name: `controlSettings.${fieldIndex}.basicSettings.repeatableDefaultValues`,
  });

  if (isRepeatingGrid && supportsInitialValue) {
    return (
      <PanelWithHeader title="Default values">
        <OrderedListNoStyle aria-label="Default values">
          {fields.map((field, index) => (
            <li key={field.id}>
              <TextInput
                id={`defaultValue-${index}`}
                type="text"
                label={`Iteration ${index + 1}`}
                {...register(
                  `controlSettings.${fieldIndex}.basicSettings.repeatableDefaultValues.${index}.defaultValue`,
                )}
              />
            </li>
          ))}
        </OrderedListNoStyle>
      </PanelWithHeader>
    );
  } else {
    return null;
  }
};

export default RepeatedControlDefaultValues;
