import _ from 'lodash';
import { PropsWithChildren, createContext, useContext, useEffect } from 'react';
import { FieldArrayWithId, UseFieldArrayReturn } from 'react-hook-form';
import { FormValues } from './Form';
import { sectionSettingsGenerator } from './FormStructures/Section';
import { generateNewSectionName } from './NameGenerator';
import { selectAllSectionIds } from './StateManagement/Selectors/SectionSelector';
import { useAppDispatch, useAppSelector } from './StateManagement/hooks';
import { updateSelectedComponent } from './StateManagement/layoutSlice';

const SectionSettingsContext = createContext<
  FieldArrayWithId<FormValues, 'sectionSettings', 'key'>[] | null
>(null);

export const useSectionSettingsContext = () => {
  const context = useContext(SectionSettingsContext);

  if (context === null) {
    throw Error('useSectionSettingsContext must be used within a SectionSettingsProvider');
  }

  return context;
};

export const SectionSettingsProvider = ({
  children,
  ...props
}: PropsWithChildren<UseFieldArrayReturn<FormValues, 'sectionSettings', 'key'>>) => {
  const sectionIds = useAppSelector(selectAllSectionIds);
  const { fields, append, remove } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const itemsAdded = _.difference(
      sectionIds,
      fields.map((f) => f.id),
    );

    if (itemsAdded.length === 0) {
      return;
    }

    if (itemsAdded.length > 1) {
      throw Error('More sections were added than expected');
    }

    const newItem = itemsAdded[0];
    const defaultName = generateNewSectionName(fields.map((x) => x.basicSettings.name));
    append({
      id: newItem,
      ...sectionSettingsGenerator(defaultName),
    });
    dispatch(updateSelectedComponent({ id: newItem, type: 'section' }));
  }, [sectionIds, append, fields, dispatch]);

  useEffect(() => {
    const itemsRemoved = _.difference(
      fields.map((f) => f.id),
      sectionIds,
    );
    if (itemsRemoved.length > 0) {
      const indexesToRemove = fields.reduce((indexes, current, index) => {
        if (itemsRemoved.includes(current.id)) {
          indexes.push(index);
        }

        return indexes;
      }, Array<number>());
      remove(indexesToRemove);
    }
  }, [sectionIds, remove, fields]);

  return (
    <SectionSettingsContext.Provider value={fields}>{children}</SectionSettingsContext.Provider>
  );
};
