import { useMemo } from 'react';
import { useRepeatSettingsContext } from '../../RepeatSettingsContext';
import { makeSelectGridById } from '../../StateManagement/Selectors/GridSelector';
import { useAppSelector } from '../../StateManagement/hooks';
import Grid from './Grid';
import RepeatedGrid from './RepeatedGrid';

interface Props {
  gridId: string;
}

const GridFactory = ({ gridId }: Props) => {
  const selectGridById = useMemo(makeSelectGridById, []);
  const grid = useAppSelector((state) => selectGridById(state, gridId));
  const { getById: getRepeatSettings } = useRepeatSettingsContext();

  if (grid.isRepeated) {
    const settings = getRepeatSettings(grid.id);
    if (settings) {
      //This can be null on first render
      return <RepeatedGrid repeatingGrid={grid} settingsIndex={settings.index} />;
    }
    return null;
  } else {
    return <Grid {...grid} />;
  }
};

export default GridFactory;
