import ReferenceDataFormEdit from '../ReferenceDataFormEdit/ReferenceDataFormEdit';
import { ReferenceDataFields } from '../Types';

const emptyDefaults: ReferenceDataFields = {
  key: '',
  referenceData: [
    {
      displayName: '',
      code: '',
      stopCondition: false,
      stopConditionText: '',
      optionIsExclusive: false,
      deprecated: false,
      filterGroups: '',
      isNew: true,
    },
  ],
};

const NewReferenceDataPage = () => {
  return <ReferenceDataFormEdit currentValues={emptyDefaults} isNew={true} />;
};

export default NewReferenceDataPage;
