import { NavigatePrompt } from '@airelogic/form-management/components';
import { PropsWithChildren } from 'react';
import { useFormState } from 'react-hook-form';
import { FormValues } from './Form';

const CloseFormHandler = ({ children }: PropsWithChildren<unknown>) => {
  const { isDirty } = useFormState<FormValues>();

  return (
    <NavigatePrompt
      when={isDirty}
      confirmationText="Are you sure you want to discard changes since the last save?"
      negativeChoice="No, keep building"
    >
      {children}
    </NavigatePrompt>
  );
};

export default CloseFormHandler;
