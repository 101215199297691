import {
  Box,
  Divider,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuProps,
  Theme,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';

const useMenuItemStyles = makeStyles()((theme: Theme) => ({
  menuText: {
    color: 'white',
    fontSize: '16px',
    marginRight: 'auto',
  },
  menuIcon: {
    marginLeft: '10px',
  },
  menuOpenIcon: {
    color: '#33444E',
    '&:hover': {
      color: 'black',
    },
  },
  menuItemIcon: {
    color: 'white',
    marginLeft: '10px',
  },
  menuItem: {
    '&:hover': {
      backgroundColor: '#3463FD',
    },
    borderRadius: '5px',
    color: 'white',
    margin: '2px',
  },
}));

const StyledActionMenu = withStyles(Menu, (_) => ({
  paper: {
    background: '#33444E',
    padding: '3px',
    boxShadow: '0px 3px 6px #00000029',
    backdropFilter: 'blur(10px)',
    borderRadius: '10px',
    opacity: '1',
  },
}));

export const ActionMenu = (props: MenuProps) => {
  return (
    <StyledActionMenu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
  );
};

interface ActionMenuItemProps {
  text: string;
  icon: React.ReactNode;
}

export const ActionMenuItem = React.forwardRef(
  (
    props: ActionMenuItemProps & MenuItemProps<'li'>,
    ref: React.Ref<HTMLLIElement>,
  ): React.ReactElement => {
    const { classes } = useMenuItemStyles();
    const { text, icon, ...rest } = props;

    return (
      <MenuItem className={classes.menuItem} {...rest}>
        <Box display="flex" flexDirection="row" width="100%">
          <Typography variant="h6" className={classes.menuText}>
            {text}
          </Typography>
          <Box className={classes.menuItemIcon}>{icon}</Box>
        </Box>
      </MenuItem>
    );
  },
);

export const ActionMenuDivider = () => {
  return <Divider style={{ backgroundColor: 'white' }} />;
};
