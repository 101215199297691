import { DateTimeIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = ['datetime'];

export const dateTimeControl: ControlDefinition = {
  displayName: 'Date and Time',
  icon: DateTimeIcon,
  type: 'DATETIME',
  supportedDataTypes: dataTypes,
  supportsInitialValue: false,
};
