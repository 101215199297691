import { SelectInput } from '@airelogic/form-management/components';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '../../Form';
import { SectionHighlightOption } from '../../FormStructures/Section';
import PanelWithHeader from '../PanelWithHeader';

interface Props {
  fieldIndex: number;
}

const sectionHighlightingOptions: { value: SectionHighlightOption; label: string }[] = [
  {
    value: '',
    label: 'Default',
  },
  {
    value: 'form-section-highlight',
    label: 'Highlight',
  },
  {
    value: 'form-section-success',
    label: 'Highlight with success',
  },
  {
    value: 'form-section-warning',
    label: 'Highlight with warning',
  },
  {
    value: 'form-section-danger',
    label: 'Highlight with danger',
  },
];

const SectionDisplaySettings = ({ fieldIndex }: Props) => {
  const { register } = useFormContext<FormValues>();

  return (
    <PanelWithHeader title="Display Settings">
      <SelectInput
        id="sectionHighlighting"
        label="Section Highlighting"
        {...register(`sectionSettings.${fieldIndex}.displaySettings.sectionHighlighting`)}
      >
        {sectionHighlightingOptions.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </SelectInput>
    </PanelWithHeader>
  );
};

export default SectionDisplaySettings;
