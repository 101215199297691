import { Box } from '@mui/material';
import { useMemo } from 'react';
import { makeSelectCellsInRow } from '../../StateManagement/Selectors/GridSelector';
import { useAppSelector } from '../../StateManagement/hooks';
import Cell from './Cell';

interface Props {
  gridId: string;
  rowId: string;
  repeatedIterationId?: string /*Used only for repeated grids*/;
}

const Row = ({ gridId, rowId, repeatedIterationId }: Props) => {
  const selectCellsInRow = useMemo(makeSelectCellsInRow, []);
  const cells = useAppSelector((state) => selectCellsInRow(state, gridId, rowId));

  return (
    <Box display="flex" data-testid={`row-${rowId}`} width={1}>
      {cells.map((cell) => {
        return (
          <Box key={`${rowId}-${cell.columnId}`} flex={cell.cellSpan}>
            <Cell
              cell={{
                gridId,
                rowId,
                columnId: cell.columnId,
              }}
              repeatedIterationId={repeatedIterationId}
            />
          </Box>
        );
      })}
    </Box>
  );
};
export default Row;
