import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    '& td': {
      paddingRight: theme.spacing(1),
    },
    '& th': {
      paddingRight: theme.spacing(1),
    },
  },
}));

const DeliveryStatusTooltipContent = () => {
  const { classes } = useStyles();
  return (
    <table className={classes.root}>
      <thead>
        <tr>
          <th>Status</th>
          <th>Creatable</th>
          <th>Amendable</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Prototype</td>
          <td>No</td>
          <td>No</td>
        </tr>
        <tr>
          <td>Development</td>
          <td>No</td>
          <td>No</td>
        </tr>
        <tr>
          <td>Production</td>
          <td>Yes</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>Deprecated</td>
          <td>No</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>Withdrawn</td>
          <td>No</td>
          <td>No</td>
        </tr>
      </tbody>
    </table>
  );
};

export default DeliveryStatusTooltipContent;
