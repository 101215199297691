import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  headerText: {
    color: '#33444E',
  },

  surface: {
    background: '#EDF0F2',
    padding: theme.spacing(2),
  },

  outerGrid: {
    margin: 0,
    marginBottom: theme.spacing(2),
  },

  tabsHolder: {
    flexGrow: 1,
    backgroundColor: 'red',
    display: 'flex',
    // height: 224,
  },

  saveBtn: {
    marginLeft: theme.spacing(1),
  },
}));
