import { ControlGroup as ControlPaneGroup } from '../ControlsPane/ControlsPane';
import { getControlDefinitionByType } from './Controls';
import { ControlType } from './types';

interface ControlGroup {
  readonly displayName: string;
  readonly controls: ControlType[];
}

export const controlGroups: readonly ControlGroup[] = [
  {
    displayName: 'Text Controls',
    controls: ['TEXT_FIELD', 'TEXT_AREA', 'RICHTEXT', 'PASSWORD_FIELD'],
  },
  {
    displayName: 'Output Controls',
    controls: ['EXPLANATORY_TEXT', 'CALCULATED_VALUE', 'SCORE_FIELD'],
  },
  {
    displayName: 'Typed Controls',
    controls: ['NUMBER_FIELD', 'EMAIL_ADDRESS'],
  },
  {
    displayName: 'Date and Time Controls',
    controls: ['DATE_FIELD', 'TIME_FIELD', 'SPLIT_DATE', 'DATETIME', 'PARTIAL_DATE'],
  },
  {
    displayName: 'Selection Controls',
    controls: [
      'DROPDOWN',
      'RADIO_BUTTON',
      'CHECKBOX',
      'BOOLEAN_BUTTONS',
      'SLIDER',
      'DYNAMIC_DROPDOWN',
      'IMAGE_SELECT',
      'LAZY_SELECT',
      'RICH_TEXT_SELECT',
    ],
  },
  {
    displayName: 'Attachments',
    controls: [
      'SIGNATURE',
      'SCRIBBLE',
      'IMAGE_UPLOAD',
      'STATIC_IMAGE',
      'YOUTUBE',
      'ANNOTATED_IMAGE',
      'ADVANCED_ANNOTATED_IMAGE',
    ],
  },
  {
    displayName: 'Integrated Only',
    controls: ['SERVER_SIDE_CALCULATION'],
  },
  {
    displayName: 'Miscellaneous',
    controls: ['GEOLOCATOR'],
  },
];

export const controlPaneGroups: ControlPaneGroup[] = controlGroups.map((cg) => ({
  displayName: cg.displayName,
  controls: cg.controls.map(getControlDefinitionByType),
}));
