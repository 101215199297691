import { nanoid, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  canAddColumnToGrid,
  canDeleteColumnInGrid,
  canDeleteGrid,
  canDeleteRowInGrid,
  canSplitGrid,
  getAllControls,
  getGridById,
  getGridIdsInSection,
  getSelectedCell,
  isSelectedGrid,
} from '../localSelectors';

import { Cell, Grid, IState } from '../types';
import { deleteControl } from './controlReducers';
import {
  clearSelectedComponent,
  getNextSelectionIndex,
  getOverlappingControlsForColumn,
  updateCellSelection,
} from './selectionsReducer';

interface AddRowToGrid {
  gridId: string;
  insertPosition: 'above' | 'below';
  relativeRowId: string;
  /**
   * Should only be used to aid testing.
   */
  rowId?: string;
}

interface AddColumnToGrid {
  gridId: string;
  insertPosition: 'before' | 'after';
  relativeColumnId: string;
  /**
   * Should only be used to aid testing.
   */
  columnId?: string;
}

interface DeleteGrid {
  gridId: string;
}

interface SplitGrid {
  gridId: string;
  rowId: string;
}
interface DeleteRow {
  gridId: string;
  rowId: string;
}
interface DeleteColumn {
  gridId: string;
  columnId: string;
}

interface AddGridToSection extends AddGrid {
  sectionId: string;
  relativeGridId?: string;
}

interface AddGrid extends GridTestingOptionals {
  repeat: boolean;
}

interface MoveGrid {
  gridId: string;
  direction: 'up' | 'down';
}

export interface GridTestingOptionals {
  /**
   * Should only be used to aid testing.
   */
  gridId?: string;
  /**
   * Should only be used to aid testing.
   */
  rowIds?: string[];
  /**
   * Should only be used to aid testing.
   */
  columnIds?: string[];
}

const generateBlankGrid = (options: AddGridToSection): Grid => {
  const newGridId = options.gridId ?? nanoid();
  return {
    id: newGridId,
    sectionId: options.sectionId,
    rowIds: options.rowIds ?? [nanoid()],
    columnIds: options.columnIds ?? [nanoid()],
    isRepeated: options.repeat,
    iterationIds: options.repeat ? [nanoid()] : [],
  };
};

export const addGridToSection = (state: IState, options: AddGridToSection) => {
  const grid = generateBlankGrid(options);
  state.grids.byId[grid.id] = grid;
  if (options.relativeGridId) {
    state.grids.allIds.splice(
      state.grids.allIds.indexOf(options.relativeGridId) + 1, //insert after
      0,
      grid.id,
    );
  } else {
    state.grids.allIds.push(grid.id);
  }

  state.layoutSelections.selectedCell = {
    gridId: grid.id,
    rowId: grid.rowIds[0],
    columnId: grid.columnIds[0],
  };
};

export const gridReducers = {
  addGrid: (state: IState, action: PayloadAction<AddGrid>) => {
    state.layoutSelections.selectedComponent = undefined;
    const selectedCell = getSelectedCell(state);
    if (selectedCell) {
      const currentlySelectedGrid = getGridById(state, selectedCell.gridId);
      if (currentlySelectedGrid) {
        addGridToSection(state, {
          sectionId: currentlySelectedGrid.sectionId,
          relativeGridId: currentlySelectedGrid.id,
          ...action.payload,
        });
      }
    }
  },
  addRowToGrid: (state: IState, action: PayloadAction<AddRowToGrid>) => {
    addRowToGrid(state, action.payload);
  },
  addColumnToGrid: (state: IState, action: PayloadAction<AddColumnToGrid>) => {
    const grid = getGridById(state, action.payload.gridId);
    if (canAddColumnToGrid(grid)) {
      const offset = action.payload.insertPosition === 'before' ? 0 : 1;
      const colIndex = grid.columnIds.indexOf(action.payload.relativeColumnId);

      grid.columnIds.splice(colIndex + offset, 0, action.payload.columnId ?? nanoid());

      if (colIndex + offset < grid.columnIds.length - 1) {
        const overlappingControls = getOverlappingControlsForColumn(
          state,
          action.payload.gridId,
          grid.columnIds[colIndex],
        );

        overlappingControls.forEach((control) => {
          control.cellSpan += 1;
        });
      }
    }
  },
  deleteRow: (state: IState, action: PayloadAction<DeleteRow>) => {
    const grid = getGridById(state, action.payload.gridId);

    if (canDeleteRowInGrid(grid)) {
      const selectedCell = getSelectedCell(state);
      if (
        selectedCell &&
        selectedCell.gridId === action.payload.gridId &&
        selectedCell.rowId === action.payload.rowId
      ) {
        const rowId =
          grid.rowIds[getNextSelectionIndex(grid.rowIds, action.payload.rowId).nextSelectionIndex];
        updateCellSelection(state, {
          gridId: selectedCell.gridId,
          columnId: selectedCell.columnId,
          rowId: rowId,
        });
      }
      grid.rowIds = grid.rowIds.filter((r) => r !== action.payload.rowId);
      deleteControlsInRow(state, action.payload.rowId);
    }
  },
  deleteColumn: (state: IState, action: PayloadAction<DeleteColumn>) => {
    const grid = getGridById(state, action.payload.gridId);

    if (canDeleteColumnInGrid(grid)) {
      const selectedCell = getSelectedCell(state);

      const overlappingControls = getOverlappingControlsForColumn(
        state,
        action.payload.gridId,
        action.payload.columnId,
      );

      overlappingControls.forEach((control) => {
        control.cellSpan -= 1;
      });

      if (
        selectedCell &&
        selectedCell.gridId === action.payload.gridId &&
        selectedCell.columnId === action.payload.columnId
      ) {
        const columnId =
          grid.columnIds[
            getNextSelectionIndex(grid.columnIds, action.payload.columnId).nextSelectionIndex
          ];

        updateCellSelection(state, {
          gridId: selectedCell.gridId,
          columnId: columnId,
          rowId: selectedCell.rowId,
        });
      }

      grid.columnIds = grid.columnIds.filter((r) => r !== action.payload.columnId);
      deleteControlsInColumn(state, action.payload.columnId);
    }
  },
  deleteGrid: (state: IState, action: PayloadAction<DeleteGrid>) => {
    if (canDeleteGrid(state, action.payload.gridId)) {
      const selectedCell = getSelectedCell(state);
      if (selectedCell && selectedCell.gridId === action.payload.gridId) {
        const grids = getGridIdsInSection(
          state,
          getGridById(state, action.payload.gridId).sectionId,
        );
        const indexes = getNextSelectionIndex(grids, selectedCell.gridId);

        const nextSelectedGrid = getGridById(state, grids[indexes.nextSelectionIndex]);
        const nextCellSelection =
          indexes.nextSelectionIndex < indexes.currentIndex
            ? getLastCellInGrid(nextSelectedGrid)
            : getFirstCellInGrid(nextSelectedGrid);
        updateCellSelection(state, nextCellSelection);
      }

      deleteGrid(state, action.payload.gridId);
    }
  },
  splitGrid: (state: IState, action: PayloadAction<SplitGrid>) => {
    if (canSplitGrid(state, action.payload.gridId, action.payload.rowId)) {
      const currentGrid = getGridById(state, action.payload.gridId);
      const newGridId = nanoid();

      const currentRowIndex = currentGrid.rowIds.indexOf(action.payload.rowId);
      const rowsToMove = currentGrid.rowIds.slice(currentRowIndex);

      const columnTracker: { previousColId: string; newColId: string }[] = [];
      const rowsTracker: string[] = [];

      for (const col of currentGrid.columnIds) {
        const newColId = nanoid();
        columnTracker.push({
          previousColId: col,
          newColId: newColId,
        });
      }

      for (const row of rowsToMove) {
        state.grids.byId[currentGrid.id].rowIds = state.grids.byId[currentGrid.id].rowIds.filter(
          (r) => r !== row,
        );
        rowsTracker.push(row);
      }

      const controlsOfInterest = state.controls.filter((control) =>
        rowsTracker.includes(control.cell.rowId),
      );
      for (const control of controlsOfInterest) {
        const filteredColumnTracker = columnTracker.find(
          (c) => c.previousColId === control.cell.columnId,
        );
        if (filteredColumnTracker) {
          control.cell.gridId = newGridId;
          control.cell.columnId = filteredColumnTracker.newColId;
        }
      }

      addGridToSection(state, {
        sectionId: currentGrid.sectionId,
        relativeGridId: currentGrid.id,
        columnIds: columnTracker.map((ct) => ct.newColId),
        gridId: newGridId,
        rowIds: rowsTracker,
        repeat: false,
      });
    }
  },
  moveGrid: (state: IState, action: PayloadAction<MoveGrid>) => {
    switch (action.payload.direction) {
      case 'down':
        moveGridDown(state, action.payload.gridId);
        break;
      case 'up':
        moveGridUp(state, action.payload.gridId);
    }
  },
};

const isLastOrOnlyWithinSection = (state: IState, gridId: string): boolean => {
  const currentGrid = state.grids.byId[gridId];
  const gridsInSection = getGridIdsInSection(state, currentGrid.sectionId);

  //Is only grid within section
  if (gridsInSection.length < 2) return true;

  //Is last grid within section
  return gridsInSection[gridsInSection.length - 1] === gridId;
};

const isFirstOrOnlyWithinSection = (state: IState, gridId: string): boolean => {
  const currentGrid = state.grids.byId[gridId];
  const gridsInSection = getGridIdsInSection(state, currentGrid.sectionId);

  //Is only grid within section
  if (gridsInSection.length < 2) return true;

  //Is first grid within section
  return gridsInSection[0] === gridId;
};

const moveGridDown = (state: IState, gridId: string) => {
  if (isLastOrOnlyWithinSection(state, gridId)) {
    return;
  }

  const currentGridIndex = state.grids.allIds.indexOf(gridId);
  const nextGridId = state.grids.allIds.splice(currentGridIndex, 1)[0];
  state.grids.allIds.splice(currentGridIndex + 1, 0, nextGridId);
};

const moveGridUp = (state: IState, gridId: string) => {
  if (isFirstOrOnlyWithinSection(state, gridId)) {
    return;
  }

  const currentGridIndex = state.grids.allIds.indexOf(gridId);
  const previousGridId = state.grids.allIds.splice(currentGridIndex, 1)[0];
  state.grids.allIds.splice(currentGridIndex - 1, 0, previousGridId);
};

export const addRowToGrid = (state: IState, options: AddRowToGrid) => {
  const grid = getGridById(state, options.gridId);
  const rowId = options.rowId ?? nanoid();
  const offset = options.insertPosition === 'above' ? 0 : 1;
  grid.rowIds.splice(grid.rowIds.indexOf(options.relativeRowId) + offset, 0, rowId);
  return rowId;
};

const deleteGrid = (state: IState, gridId: string) => {
  deleteControlsInGrid(state, gridId);
  delete state.grids.byId[gridId];
  state.grids.allIds = state.grids.allIds.filter((g) => g !== gridId);
  if (isSelectedGrid(state, gridId)) {
    clearSelectedComponent(state);
  }
};

const deleteControlsInGrid = (state: IState, gridId: string) => {
  const grid = getGridById(state, gridId);

  for (const column of grid.columnIds) deleteControlsInColumn(state, column);
  for (const row of grid.rowIds) deleteControlsInRow(state, row);
};

export const deleteGridsInSection = (state: IState, sectionId: string) => {
  for (const grid of getGridIdsInSection(state, sectionId)) deleteGrid(state, grid);
};

const getControlsForColumn = (state: IState, columnId: string) =>
  getAllControls(state).filter((x) => x.cell.columnId === columnId);
const getControlsForRow = (state: IState, rowId: string) =>
  getAllControls(state).filter((x) => x.cell.rowId === rowId);

export const deleteControlsInRow = (state: IState, rowId: string) => {
  const controls = getControlsForRow(state, rowId);
  for (const control of controls) {
    deleteControl(state, control.id);
  }
};

export const deleteControlsInColumn = (state: IState, columnId: string) => {
  const controls = getControlsForColumn(state, columnId);

  for (const control of controls) {
    deleteControl(state, control.id);
  }
};

export const getLastCellInGrid = (grid: Grid): Cell => {
  return {
    gridId: grid.id,
    columnId: _.last(grid.columnIds)!,
    rowId: _.last(grid.rowIds)!,
  };
};

export const getFirstCellInGrid = (grid: Grid): Cell => {
  return {
    gridId: grid.id,
    columnId: _.first(grid.columnIds)!,
    rowId: _.first(grid.rowIds)!,
  };
};
