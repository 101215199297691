import { SingleCheckbox, TextInput, When } from '@airelogic/form-management/components';
import { Grid2 as Grid } from '@mui/material';
import { useEffect } from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { AdditionalSettings, lazyDropdownControl } from '../../../Controls/LazyDropdown';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';
import DataSource from './DataSource';
import DefaultSelectOptions from './DefaultSelectOptions';
interface Props {
  fieldIndex: number;
}

const LazyDropdownAdditionalSettings = ({ fieldIndex }: Props) => {
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<FormValues>();

  const getErrors = () => {
    return errors?.controlSettings?.[fieldIndex]
      ?.additionalSettings as FieldErrors<AdditionalSettings>;
  };

  const styleWatch = watch(`controlSettings.${fieldIndex}.additionalSettings.style`)!;

  /* If it is a button style, we need to set loadInitialReferenceData back to default */
  useEffect(() => {
    if (styleWatch !== 'default') {
      setValue(
        `controlSettings.${fieldIndex}.additionalSettings.loadInitialReferenceData`,
        lazyDropdownControl.additionalSettings?.defaults.loadInitialReferenceData,
      );
    } else {
      setValue(`controlSettings.${fieldIndex}.additionalSettings.multiSelect`, false);
    }
  }, [styleWatch, fieldIndex, setValue]);

  return (
    <>
      <PanelWithHeader title="Resource settings">
        <DataSource
          fieldIndex={fieldIndex}
          tooltip={'The source where the reference data will be loaded from'}
        />
        <TextInput
          id="ResourceKey"
          type="text"
          label="Key"
          error={!!getErrors()?.resourceKey}
          helperText={getErrors()?.resourceKey?.message}
          {...register(`controlSettings.${fieldIndex}.additionalSettings.resourceKey`)}
          required
        />
        <TextInput
          id="ResourceVersion"
          type="number"
          label="Version"
          error={!!getErrors()?.resourceVersion}
          helperText={getErrors()?.resourceVersion?.message}
          {...register(`controlSettings.${fieldIndex}.additionalSettings.resourceVersion`, {
            valueAsNumber: true,
          })}
          required
        />
      </PanelWithHeader>
      <PanelWithHeader title="Additional settings">
        <Grid container>
          <DefaultSelectOptions
            fieldIndex={fieldIndex}
            supportsMultiSelect={styleWatch !== 'default'}
          />
          <When condition={styleWatch === 'default'}>
            <Grid size={12}>
              <SingleCheckbox
                label="Load initial reference data"
                control={control}
                name={`controlSettings.${fieldIndex}.additionalSettings.loadInitialReferenceData`}
              />
            </Grid>
          </When>
        </Grid>
      </PanelWithHeader>
    </>
  );
};

export default LazyDropdownAdditionalSettings;
