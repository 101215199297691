import { Fragment } from 'react';
import { selectAllSectionIds } from '../StateManagement/Selectors/SectionSelector';
import { useAppSelector } from '../StateManagement/hooks';
import Section from './LayoutComponents/Section';

const LayoutPane = () => {
  const sectionIds = useAppSelector(selectAllSectionIds);

  return (
    <Fragment>
      {sectionIds.map((sectionId) => {
        return <Section key={sectionId} sectionId={sectionId} />;
      })}
    </Fragment>
  );
};

export default LayoutPane;
