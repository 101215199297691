import { MustNotBeEmptyText } from '@airelogic/form-management/common/validations';
import { z } from 'zod';
import { SliderIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = ['integer'];

export const validationSchema = z
  .object({
    lowerValueText: z.string(),
    upperValueText: z.string(),
    minimumValue: z
      .number({ required_error: MustNotBeEmptyText, invalid_type_error: MustNotBeEmptyText })
      .int(),
    maximumValue: z
      .number({ required_error: MustNotBeEmptyText, invalid_type_error: MustNotBeEmptyText })
      .int(),
    showValue: z.boolean(),
    showValueAsTooltip: z.boolean(),
  })
  .superRefine((val, ctx) => {
    if (val.minimumValue >= val.maximumValue) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['maximumValue'],
        message: 'Maximum value must be greater than Minimum value',
      });
    }
  });

export type AdditionalSettings = z.infer<typeof validationSchema>;

export const sliderControl: ControlDefinition<AdditionalSettings> = {
  displayName: 'Slider',
  icon: SliderIcon,
  type: 'SLIDER',
  supportedDataTypes: dataTypes,
  additionalSettings: {
    validationSchema,
    defaults: {
      lowerValueText: '',
      upperValueText: '',
      minimumValue: 0,
      maximumValue: 100,
      showValue: false,
      showValueAsTooltip: false,
    },
  },
  supportsInitialValue: false,
};
