import { ControlSettings, FormValues } from '../Form';
import {
  getAllSectionIds,
  getGridById,
  getGridIdsInSection,
} from '../StateManagement/localSelectors';
import { Grid, IControl, IState } from '../StateManagement/types';
import {
  Control as ExportControl,
  Grid as ExportGrid,
  Section as ExportSection,
  SaveRequest,
} from './types';

export const transform = (id: string, state: IState, settings: FormValues) => {
  const properties = settings.properties;

  const sections = getAllSectionIds(state).map((sectionId) =>
    mapSection(state, settings, sectionId),
  );

  const saveRequest: SaveRequest = {
    id,
    sections,
    properties,
  };

  return saveRequest;
};

const mapSection = (state: IState, settings: FormValues, sectionId: string): ExportSection => {
  const sectionSetting = settings.sectionSettings.find(
    (sectionSetting) => sectionSetting.id === sectionId,
  );

  if (sectionSetting === undefined) {
    throw new Error(`Unable to find section settings id: ${sectionId}`);
  }

  const grids = getGridIdsInSection(state, sectionId)
    .map((gridId) => getGridById(state, gridId))
    .map((grid) => mapGrid(state, settings, grid));

  return {
    id: sectionId,
    grids: grids,
    settings: sectionSetting,
  };
};

const mapGrid = (state: IState, settings: FormValues, grid: Grid): ExportGrid => {
  const controls = state.controls
    .filter((c) => c.cell.gridId === grid.id)
    .map((c) => mapControl(settings.controlSettings, c));

  if (grid.isRepeated) {
    const repeatSettings = settings.repeatSettings.find((setting) => setting.id === grid.id);

    if (repeatSettings === undefined) {
      throw new Error(`Unable to find properties for repeat id: ${grid.id}`);
    }

    return {
      id: grid.id,
      sectionId: grid.sectionId,
      columnIds: grid.columnIds,
      isRepeated: grid.isRepeated,
      rowIds: grid.rowIds,
      controls: controls,
      settings: repeatSettings,
      iterationIds: grid.iterationIds,
    };
  }

  return {
    id: grid.id,
    sectionId: grid.sectionId,
    columnIds: grid.columnIds,
    isRepeated: grid.isRepeated,
    rowIds: grid.rowIds,
    controls: controls,
  };
};

const mapControl = (controlSettings: ControlSettings[], control: IControl): ExportControl => {
  const settings = controlSettings.find((settings) => settings.id === control.id);

  if (settings === undefined) {
    throw new Error(
      `Unable to find properties for control id: ${control.id} type: ${control.type}`,
    );
  }
  return {
    id: control.id,
    cell: control.cell,
    type: control.type,
    settings: settings,
    cellSpan: control.cellSpan,
  };
};
