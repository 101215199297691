import { NavLinkButton } from '@airelogic/form-management/components';
import { Container, Theme } from '@mui/material';
import { useRouteMatch } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import ReferenceDataTable from './ReferenceDataTable';

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    marginBottom: theme.spacing(1),
  },
}));

const List = () => {
  const { classes } = useStyles();
  const { path } = useRouteMatch();

  return (
    <Container>
      <NavLinkButton
        variant="contained"
        color="primary"
        className={classes.button}
        to={`${path}/new`}
      >
        Create new
      </NavLinkButton>
      <ReferenceDataTable />
    </Container>
  );
};

export default List;
