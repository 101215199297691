import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useCallback } from 'react';
import { FormValues } from '../Form';
import { IState } from '../StateManagement/types';
import { transform } from './transform';

interface SaveRequest {
  id: string;
  state: IState;
  settings: FormValues;
}

export const useSaveService = () => {
  const { mutateAsync } = useMutation({
    mutationFn: async (request: SaveRequest) => {
      try {
        const response = await axios.post(
          '/internalapi/formbuilding/buildingblocks/save',
          transform(request.id, request.state, request.settings),
        );
        return response.status === 200;
      } catch {
        return false;
      }
    },
  });

  return useCallback(async (request: SaveRequest) => mutateAsync(request), [mutateAsync]);
};
