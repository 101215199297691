import { TabKeys } from '../HeaderTabs';
import SectionDisplaySettings from './SectionDisplaySettings';
import SectionFormulas from './SectionFormulas';
import SectionVisibilityOptions from './SectionVisibilityOptions';

const OptionsResolver = ({ fieldIndex, tab }: { fieldIndex: number; tab: TabKeys }) => {
  switch (tab) {
    case 'basicSettings':
      return (
        <>
          <SectionVisibilityOptions fieldIndex={fieldIndex} />
          <SectionDisplaySettings fieldIndex={fieldIndex} />
        </>
      );
    case 'formula':
      return <SectionFormulas fieldIndex={fieldIndex} />;
    default:
      return null;
  }
};

export default OptionsResolver;
