import { FunctionComponent, SVGProps } from 'react';
import { FormStructureType } from '../FormStructures/types';
import { useAppDispatch } from '../StateManagement/hooks';
import { addGrid, addSection } from '../StateManagement/layoutSlice';
import ControlPaneItem from './ControlPaneItem';

interface Props {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  label: string;
  type: FormStructureType;
}

export const StructureListItem = ({ icon, label, type }: Props) => {
  const dispatch = useAppDispatch();

  const clickHandler = () => {
    switch (type) {
      case 'SECTION':
        dispatch(addSection({}));
        break;
      case 'GRID':
        dispatch(addGrid({ repeat: false }));
        break;
      case 'REPEATED_GRID':
        dispatch(addGrid({ repeat: true }));
        break;
    }
  };

  return <ControlPaneItem icon={icon} label={label} onClick={clickHandler} />;
};
