import { SignatureIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = ['string'];

export const signatureControl: ControlDefinition = {
  displayName: 'Signature',
  icon: SignatureIcon,
  type: 'SIGNATURE',
  supportedDataTypes: dataTypes,
  formulaOverrides: {
    allowCalculatedValue: false,
    allowInitialValue: false,
  },
  supportsInitialValue: false,
};
