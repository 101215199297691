import { IConstraintOption } from '../../../../Controls/types';

export const IntegerOps: IConstraintOption[] = [
  {
    label: 'Formula',
    kind: 'custom',
    type: '',
    fieldType: 'text',
  },
  {
    label: 'Negative',
    kind: 'predefined',
    type: 'negative',
    fieldType: null,
  },
  {
    label: 'Positive',
    kind: 'predefined',
    type: 'positive',
    fieldType: null,
  },
  {
    label: 'Non Negative',
    kind: 'predefined',
    type: 'non-negative',
    fieldType: null,
  },
  {
    label: 'Non Positive',
    kind: 'predefined',
    type: 'non-positive',
    fieldType: null,
  },
];
