import { Route, useRouteMatch } from 'react-router';
import ErrorPage from './Page';

const Routing = () => {
  const match = useRouteMatch();

  return <Route path={`${match.path}`} component={ErrorPage} />;
};

export default Routing;
