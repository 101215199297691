import {
  ActionMenu,
  ActionMenuItem,
  ConfirmationDialog,
  MenuIcon,
} from '@airelogic/form-management/components';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react';

interface MenuProps {
  index: number;
  onRemove: (index: number) => void;
  onMoveUp: (index: number) => void;
  onMoveDown: (index: number) => void;
  onEditPage: () => void;
  disableRemove: boolean;
}

export const PageEditMenu = (props: MenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { index, onRemove, onMoveUp, onMoveDown, onEditPage, disableRemove } = props;
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMoveUpClick = (_event: React.MouseEvent<HTMLElement>) => {
    onMoveUp(index);
    setAnchorEl(null);
  };

  const handleMoveDownClick = (_event: React.MouseEvent<HTMLElement>) => {
    onMoveDown(index);
    setAnchorEl(null);
  };

  const handleRemoveClick = () => {
    onRemove(index);
    setAnchorEl(null);
  };

  const handleEditNameClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
    onEditPage();
  };

  const confirmDelete = () => {
    setShowConfirmDelete(false);
    handleRemoveClick();
  };

  const hideConfirmation = () => {
    setShowConfirmDelete(false);
  };

  return (
    <>
      <MenuIcon aria-label="Edit page" onClick={handleClick} />
      <ActionMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <ActionMenuItem
          onClick={handleMoveUpClick}
          text="Move Page"
          icon={<ArrowUpwardIcon />}
        ></ActionMenuItem>
        <ActionMenuItem
          onClick={handleMoveDownClick}
          text="Move Page"
          icon={<ArrowDownwardIcon />}
        ></ActionMenuItem>
        <ActionMenuItem
          onClick={handleEditNameClick}
          text="Edit Name"
          icon={<EditIcon />}
        ></ActionMenuItem>
        <ActionMenuItem
          disabled={disableRemove}
          onClick={() => {
            setShowConfirmDelete(true);
          }}
          text="Delete"
          icon={<DeleteIcon />}
        ></ActionMenuItem>
      </ActionMenu>
      <ConfirmationDialog
        confirmationText={
          'Are you sure you want to delete this page? Its configuration (including building blocks it contains) will be lost.'
        }
        open={showConfirmDelete}
        handleCancel={hideConfirmation}
        handleConfirm={confirmDelete}
      ></ConfirmationDialog>
    </>
  );
};

export default PageEditMenu;
