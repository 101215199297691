import { OrderedListNoStyle } from '@airelogic/form-management/components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormSchema, createEmptyFormContext } from '../FormBuildingInterfaces';
import { useStyles } from './FormContext.styles';
import FormContextRow from './FormContextRow';

interface Props {
  onClose: () => void;
}

const FormContextDialogContent = ({ onClose }: Props) => {
  const { classes } = useStyles();
  const { trigger, control } = useFormContext<FormSchema>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'formContext',
  });

  const onAppendFormContext = () => {
    append(createEmptyFormContext());
  };

  const close = async () => {
    const valid = await trigger(`formContext`);
    if (valid) {
      onClose();
    }
  };

  return (
    <>
      <DialogTitle className={classes.dialog}>Configure form context</DialogTitle>
      <DialogContent>
        <Paper elevation={0} className={classes.scrollingPaper}>
          {fields.length > 0 ? (
            <OrderedListNoStyle aria-label="Form Context">
              {fields.map((item, index) => {
                return (
                  <li key={item.id}>
                    <FormContextRow remove={remove} index={index} />
                  </li>
                );
              })}
            </OrderedListNoStyle>
          ) : (
            <Box style={{ textAlign: 'center' }}>
              <Typography variant="h6" component="h6">
                No key value pairs have been added yet.
              </Typography>
            </Box>
          )}
        </Paper>
        <Box>
          <Button startIcon={<AddCircleIcon />} onClick={onAppendFormContext} color={'primary'}>
            Add Key Value Pair
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color={'primary'}>
          Close
        </Button>
      </DialogActions>
    </>
  );
};

export default FormContextDialogContent;
