import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  list: {
    listStyle: 'none',
    paddingLeft: 0,
  },
});

const OrderedListNoStyle = ({
  children,
  ...rest
}: React.DetailedHTMLProps<React.OlHTMLAttributes<HTMLOListElement>, HTMLOListElement>) => {
  const { classes } = useStyles();

  return (
    <ol className={classes.list} {...rest}>
      {children}
    </ol>
  );
};

export default OrderedListNoStyle;
