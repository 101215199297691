import { controlSettingsGenerator } from '../Controls/DefaultSettings';
import { sectionSettingsGenerator } from '../FormStructures/Section';
import { IControl, IGrids, ISections, IState } from './types';

export const Sections: ISections = {
  allIds: ['xZRmJ_boh'],
};

export const Grids: IGrids = {
  byId: {
    Wyg3uM5zd: {
      id: 'Wyg3uM5zd',
      sectionId: 'xZRmJ_boh',
      isRepeated: false,
      rowIds: ['0MW4QF7YD'],
      columnIds: ['ahXn4mpFB', 'MgVUu5gty'],
    },
  },
  allIds: ['Wyg3uM5zd'],
};

const initialControl: IControl = {
  id: 'bH5Jv47TB',
  cell: {
    gridId: 'Wyg3uM5zd',
    rowId: '0MW4QF7YD',
    columnId: 'ahXn4mpFB',
  },
  type: 'TEXT_FIELD',
  cellSpan: 1,
};

export const defaultControlSettings = () => [
  { id: 'bH5Jv47TB', ...controlSettingsGenerator('TEXT_FIELD', 'control-1') },
];
export const defaultSectionSettings = () => [
  { id: 'xZRmJ_boh', ...sectionSettingsGenerator('section-1') },
];
export const Controls: IControl[] = [initialControl];

export const initialState: IState = {
  sections: Sections,
  grids: Grids,
  controls: Controls,
  layoutSelections: {
    selectedCell: {
      gridId: 'Wyg3uM5zd',
      rowId: '0MW4QF7YD',
      columnId: 'ahXn4mpFB',
    },
    selectedComponent: {
      id: 'bH5Jv47TB',
      type: 'control',
    },
  },
};
