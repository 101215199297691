import { MustNotBeEmptyText } from '@airelogic/form-management/common/validations';
import { z } from 'zod';
import { StaticImageIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = ['base64Binary'];

const validationSchema = z.object({
  imageBase64: z.string().min(1, MustNotBeEmptyText),
  altText: z.string(),
  omitFromSubmission: z.boolean(),
});

export type AdditionalSettings = z.infer<typeof validationSchema>;

export const staticImageControl: ControlDefinition<AdditionalSettings> = {
  displayName: 'Static Image',
  icon: StaticImageIcon,
  type: 'STATIC_IMAGE',
  supportedDataTypes: dataTypes,
  formulaOverrides: {
    allowCalculatedValue: false,
    allowInitialValue: false,
    allowReadOnly: false,
  },
  additionalSettings: {
    validationSchema,
    defaults: {
      imageBase64: '',
      altText: '',
      omitFromSubmission: false,
    },
  },
  supportsInitialValue: false,
};
