import { Button, ButtonProps } from '@mui/material';
import { useStyles } from './Styles';

const StyledButton = (props: Omit<ButtonProps, 'variant'>) => {
  const { ...rest } = props;

  const { classes } = useStyles({
    color: props.color,
  });

  return (
    <Button variant="outlined" className={classes.button} {...rest}>
      {props.children}
    </Button>
  );
};

export default StyledButton;
