import { SingleCheckbox, TextInput } from '@airelogic/form-management/components';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { AdditionalSettings } from '../../../Controls/Youtube';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';
import OmitFromSubmission from './OmitFromSubmission';

interface Props {
  fieldIndex: number;
}

const YoutubeAdditionalSettings = ({ fieldIndex }: Props) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<FormValues>();

  const getErrors = () => {
    return errors?.controlSettings?.[fieldIndex]?.additionalSettings as
      | FieldErrors<AdditionalSettings>
      | undefined;
  };

  return (
    <PanelWithHeader title="Additional Settings">
      <TextInput
        id="Video URL"
        type="text"
        label="Video URL"
        helperText={getErrors()?.videoUrl?.message}
        error={getErrors()?.videoUrl?.message !== undefined}
        {...register(`controlSettings.${fieldIndex}.additionalSettings.videoUrl`)}
      />

      <SingleCheckbox
        label="Autoplay"
        control={control}
        name={`controlSettings.${fieldIndex}.additionalSettings.autoplay`}
      />
      <OmitFromSubmission fieldIndex={fieldIndex} />
    </PanelWithHeader>
  );
};

export default YoutubeAdditionalSettings;
