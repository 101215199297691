import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';

interface Props {
  showDialog: boolean;
  noticeText: string;
}

const NoticeDialogForExistingTranslations = ({ showDialog, noticeText }: Props) => {
  const [open, setOpen] = useState<boolean>(showDialog);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">A notice about existing translations</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{noticeText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoticeDialogForExistingTranslations;
