import { LoadingSpinner } from '@airelogic/common';
import { useParams } from 'react-router-dom';
import { parseGetFormResponse } from '../FormBuilding.Service';
import { EditorMode, FormEdit } from '../FormEdit/FormEdit';
import { useGetBuildingBlocks, useGetDocumentClassifications, useGetForm } from '../Service';

interface Params {
  key: string;
  version?: string;
}

const EditForm = () => {
  const { key, version } = useParams<Params>();
  const { data: formData, status: formStatus, error: formError } = useGetForm({ key, version });
  const {
    data: blockData,
    status: blockStatus,
    error: blockError,
    isRefetching: isRefetchingBlocks,
    refetch,
  } = useGetBuildingBlocks();
  const { data: classifications, status: classificationStatus } = useGetDocumentClassifications();

  if (formStatus === 'pending' || classificationStatus === 'pending' || blockStatus === 'pending') {
    return <LoadingSpinner fullscreen={true} />;
  }

  if (formStatus === 'error') {
    throw formError;
  }

  if (blockStatus === 'error') {
    throw blockError;
  }

  const refetchBlocks = async () => {
    await refetch();
  };

  const editorMode: EditorMode = version ? 'edit' : 'newversion';

  return (
    <FormEdit
      refreshBlocks={refetchBlocks}
      blockVersions={blockData}
      isRefreshingBlocks={isRefetchingBlocks}
      editorMode={editorMode}
      classifications={classifications ?? []}
      currentValues={parseGetFormResponse(formData, editorMode)}
    />
  );
};

export default EditForm;
