import AnnotatedImage from './AnnotatedImage';

interface Props {
  fieldIndex: number;
}

const AdvancedAnnotatedImage = ({ fieldIndex }: Props) => (
  <AnnotatedImage fieldIndex={fieldIndex} />
);

export default AdvancedAnnotatedImage;
