import { ListItemButton, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import { FunctionComponent, SVGProps } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  icon: {
    minWidth: '30px',
  },
}));

interface Props {
  disabled?: boolean;
  label: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  onClick: () => void;
}

const ControlPaneItem = ({ disabled = false, label, icon, onClick }: Props) => {
  const { classes } = useStyles();

  return (
    <ListItemButton disabled={disabled} onClick={onClick}>
      <ListItemIcon className={classes.icon}>
        <SvgIcon component={icon} />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
};

export default ControlPaneItem;
