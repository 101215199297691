import {
  doesNotStartWithANumber,
  isValidResourceKeySchema,
  MustNotBeEmptyText,
  resourceKeySchema,
} from '@airelogic/form-management/common/validations';
import { z } from 'zod';

export const validationSchema = z.object({
  applicationName: resourceKeySchema(),
  blockName: z
    .string()
    .min(1, MustNotBeEmptyText)
    .superRefine(doesNotStartWithANumber)
    .superRefine(isValidResourceKeySchema),
  blockTitle: z.string(),
  blockDescription: z.string(),
  hasTranslations: z.boolean(),
});

export type Properties = z.infer<typeof validationSchema>;
