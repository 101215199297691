export const ResourceKeyRegex = /^[a-zA-Z_\-0-9]{1,}$/;
const NegatedResourceKeyRegex = /[^a-zA-Z_\-0-9]/g;

export const generateResourceKey = (value: string) => {
  return value.trim().replace(/ /g, '-').replace(NegatedResourceKeyRegex, '').toLocaleLowerCase();
};

export const isValidResourceKey = (value: string) => {
  return ResourceKeyRegex.test(value);
};
