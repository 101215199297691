import { SingleCheckbox } from '@airelogic/form-management/components';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';

interface Props {
  fieldIndex: number;
}

const NotificationSettings = ({ fieldIndex }: Props) => {
  const { control, getValues } = useFormContext<FormValues>();

  const omitFromSubmission = getValues(
    `controlSettings.${fieldIndex}.additionalSettings.omitFromSubmission`,
  );

  if (!omitFromSubmission) {
    return (
      <PanelWithHeader title="Notification Settings">
        <SingleCheckbox
          control={control}
          label="Include in external notifcations"
          name={`controlSettings.${fieldIndex}.basicSettings.includeInExternalNotifications`}
        />
      </PanelWithHeader>
    );
  } else {
    return null;
  }
};

export default NotificationSettings;
