import { SplitDateIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = ['date'];

export const splitDateControl: ControlDefinition = {
  displayName: 'Split Date',
  icon: SplitDateIcon,
  type: 'SPLIT_DATE',
  supportedDataTypes: dataTypes,
  supportsInitialValue: true,
};
