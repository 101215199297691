import { SingleCheckbox } from '@airelogic/form-management/components';
import { FormGroup } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '../../Form';
import PanelWithHeader from '../PanelWithHeader';

interface Props {
  fieldIndex: number;
}

const VisibilityOptions = ({ fieldIndex }: Props) => {
  const { control } = useFormContext<FormValues>();

  return (
    <PanelWithHeader title="Visibility Settings">
      <FormGroup>
        <SingleCheckbox
          label="Hide From PDF View"
          control={control}
          name={`repeatSettings.${fieldIndex}.basicSettings.visibilityOptions.hideFromPdf`}
        />
        <SingleCheckbox
          label="Hide From Read View"
          control={control}
          name={`repeatSettings.${fieldIndex}.basicSettings.visibilityOptions.hideFromRead`}
        />
        <SingleCheckbox
          label="Hide From Edit View"
          control={control}
          name={`repeatSettings.${fieldIndex}.basicSettings.visibilityOptions.hideFromEdit`}
        />
      </FormGroup>
    </PanelWithHeader>
  );
};

export default VisibilityOptions;
