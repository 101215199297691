import { arTranslations } from './ar';
import { cyTranslations } from './cy';
import { enTranslations } from './en';
import { esTranslations } from './es';
import { hrTranslations } from './hr';
import { idTranslations } from './id';
import { itTranslations } from './it';
import { plTranslations } from './pl';
import { roTranslations } from './ro';
import { ruTranslations } from './ru';
import { skTranslations } from './sk';
import { tlTranslations } from './tl';
import { trTranslations } from './tr';
import { urTranslations } from './ur';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resources = {
  translation: enTranslations.en.translations,
} as const;

export type Translations = typeof resources;

export type LanguageTranslations = Record<
  string,
  {
    translations: Partial<typeof enTranslations.en.translations>;
  }
>;

export const translations: LanguageTranslations = {
  ...enTranslations,
  ...cyTranslations,
  ...esTranslations,
  ...hrTranslations,
  ...itTranslations,
  ...ruTranslations,
  ...trTranslations,
  ...plTranslations,
  ...skTranslations,
  ...roTranslations,
  ...urTranslations,
  ...tlTranslations,
  ...idTranslations,
  ...arTranslations,
};
