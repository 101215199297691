import { sanitizeHtmlString } from '@airelogic/common';
import { Divider, List, ListItem, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { RichTextSelectInlineReferenceData } from '../../../Controls/RichTextSelect';
import { FormValues } from '../../../Form';

interface Props {
  settingsIndex: number;
}

const useStyles = makeStyles()({
  html: {
    '& p': {
      margin: 0,
    },
  },
});

const allowedLength = 120;

const RichTextSelect = ({ settingsIndex }: Props) => {
  const { watch } = useFormContext<FormValues>();
  const items = watch(
    `controlSettings.${settingsIndex}.additionalSettings.items`,
  ) as RichTextSelectInlineReferenceData[];
  const { classes } = useStyles();

  const trimContent = (content: string) => {
    if (content.length > allowedLength) {
      return content.substring(0, allowedLength) + '...';
    }
    return content;
  };

  return (
    <List component="ol" disablePadding>
      {items?.map((item, index) => {
        //Use index as value could be empty when editing causing conflicting keys
        return (
          <Fragment key={index}>
            <ListItem disableGutters disablePadding>
              <Typography
                variant="caption"
                className={classes.html}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtmlString(trimContent(item?.description)),
                }}
              />
            </ListItem>
            <Divider />
          </Fragment>
        );
      })}
    </List>
  );
};

export default RichTextSelect;
