import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    height: 'auto',
    borderRadius: 4,
    border: '2px solid #DBE0E4',
    borderLeft: `6px solid ${theme.palette.primary.main}`,
  },

  innerContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  error: {
    borderLeftColor: theme.palette.error.main,
  },

  label: {
    wordWrap: 'break-word',
  },

  hint: {
    display: 'inline-block',
    wordWrap: 'break-word',
    lineHeight: '2',
  },

  hintContainer: {
    width: '70%',
    display: 'inline-block',
  },

  actionButtons: {
    width: '30%',
    display: 'inline-block',
    textAlign: 'right',
  },

  headerTitle: {
    width: '70%',
    display: 'inline-block',
  },

  required: {
    color: theme.palette.error.main,
  },

  requiredFormula: {
    color: theme.palette.text.primary,
  },

  defaultValue: {
    fontStyle: 'italic',
  },
}));
