import { IconButton, IconButtonProps, SvgIcon, Tooltip } from '@mui/material';
import { useStyles } from './IconButton.styles';
import { ReactComponent as PreviewIconSVG } from './SVGs/eye-line.svg';

interface Props extends Omit<IconButtonProps, 'component' | 'className' | 'children'> {
  tooltipText?: string;
}

const PreviewIcon = (props: Props) => {
  const { classes } = useStyles();
  const { tooltipText = '', ...rest } = props;

  return (
    <Tooltip title={tooltipText}>
      <IconButton className={classes.fillButton} {...rest}>
        <SvgIcon component={PreviewIconSVG} className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
};

export default PreviewIcon;
