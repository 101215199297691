import { IConstraintOption } from '../../../../Controls/types';

export const FormulaOps: IConstraintOption[] = [
  {
    label: 'Formula',
    kind: 'custom',
    type: '',
    fieldType: 'text',
  },
];
