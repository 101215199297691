import {
  BuildingBlock,
  BuildingBlockSchema,
  Form,
  FormPreview,
  FormSchema,
  GetFormResponse,
  Page,
  PageSchema,
  PdfReadVisibilityField,
} from './FormBuildingInterfaces';
import { EditorMode } from './FormEdit/FormEdit';

export interface DuplicatedBlock {
  key: string;
  index: number;
  innerIndex: number;
}

export const derivePdfReadVisibility = (
  readIgnore: boolean,
  pdfIgnore: boolean,
): PdfReadVisibilityField => {
  if (!pdfIgnore) {
    return 'alwaysvisible';
  }

  if (readIgnore) {
    return 'hidefromreadandpdf';
  }

  return 'hidefrompdf';
};

export const parseGetFormResponse = (
  response: GetFormResponse,
  editorMode: EditorMode,
): FormSchema => {
  return {
    metadata: {
      title: response.title,
      key: response.key,
      version: response.version,
      deliveryStatus: editorMode === 'newversion' ? 'Development' : response.deliveryStatus,
      layout: response.layout === 'wizard' ? 'single-pass' : 'clinician',
      classification: {
        label: response.classification,
        value: response.classification,
        inputValue: response.classification,
      },
    },
    persistenceSettings: {
      allowAmend: response.persistenceSettings.allowAmend,
      allowDiscard: response.persistenceSettings.allowDiscard,
      allowDrafts: response.persistenceSettings.allowDrafts,
      autoSaveInterval: response.persistenceSettings.autoSaveInterval,
    },
    pdfSettings: {
      style: response.pdfSettings.style,
      displayPageTitles: response.pdfSettings.displayPageTitles,
      displaySectionTitles: response.pdfSettings.displaySectionTitles,
    },
    formContext: response.formContext,
    pages: response.pages.map((page): PageSchema => {
      return {
        title: page.title,
        buildingBlocks: page.buildingBlocks.map((block): BuildingBlockSchema => {
          return {
            key: block.key,
            version: block.version,
            view: block.view,
            prePopulationEnabled: block.prePopulationRules !== null,
            prePopulationRules: block.prePopulationRules ?? {
              rule: '',
              useCorrelationId: false,
              dataOnly: false,
            },
            pdfReadVisibility: derivePdfReadVisibility(block.readIgnore, block.pdfIgnore),
            relevance:
              block.relevanceXPath !== undefined &&
              block.relevanceXPath !== '' &&
              block.relevanceXPath !== 'true()'
                ? 'customrule'
                : 'alwaysdisplay',
            customRule: block.relevanceXPath,
          };
        }),
      };
    }),
    hasTranslations: response.hasTranslations,
  };
};

export const buildForm = (formInput: FormSchema): Form => {
  return {
    key: formInput.metadata.key.toLowerCase(),
    title: formInput.metadata.title,
    deliveryStatus: formInput.metadata.deliveryStatus,
    classification: formInput.metadata.classification.inputValue,
    layout: formInput.metadata.layout === 'clinician' ? 'document' : 'wizard',
    showBanner: true,
    persistenceSettings: {
      allowAmend: formInput.persistenceSettings.allowAmend,
      allowDiscard: formInput.persistenceSettings.allowDiscard,
      allowDrafts: formInput.persistenceSettings.allowDrafts,
      autoSaveInterval: formInput.persistenceSettings.autoSaveInterval,
    },
    pdfSettings: {
      style: formInput.pdfSettings.style,
      displayPageTitles: formInput.pdfSettings.displayPageTitles,
      displaySectionTitles: formInput.pdfSettings.displaySectionTitles,
    },
    formContext: formInput.formContext,
    pages: mapPagesAndBuildingBlocks(formInput.pages),
  };
};

export const buildFormPreview = (formInput: FormSchema): FormPreview => {
  return {
    title: formInput.metadata.title,
    layout: formInput.metadata.layout === 'clinician' ? 'document' : 'wizard',
    pages: mapPagesAndBuildingBlocks(formInput.pages),
  };
};

const mapPagesAndBuildingBlocks = (pages: PageSchema[]) => {
  return pages.map(
    (page): Page => ({
      title: page.title,
      buildingBlocks: page.buildingBlocks.map(
        (bb): BuildingBlock => ({
          key: bb.key,
          version: bb.version,
          view: bb.view,
          prePopulationRules: bb.prePopulationEnabled ? bb.prePopulationRules : null,
          relevanceXPath: bb.relevance === 'alwaysdisplay' ? '' : bb.customRule,
          pdfIgnore: bb.pdfReadVisibility !== 'alwaysvisible',
          readIgnore: bb.pdfReadVisibility === 'hidefromreadandpdf',
        }),
      ),
    }),
  );
};

export const getDuplicateBuildingBlocks = (formInput: FormSchema): DuplicatedBlock[] => {
  const currentBlocks = formInput.pages
    .map((p, pageIndex) => {
      return p.buildingBlocks.map((b, blockIndex) => {
        return { key: b.key, index: pageIndex, innerIndex: blockIndex };
      });
    })
    .flat(1);

  const uniqueBlockKeys = new Set(
    currentBlocks.map((b) => {
      return b.key;
    }),
  );

  if (currentBlocks.length !== uniqueBlockKeys.size) {
    return currentBlocks.filter(function (block) {
      if (uniqueBlockKeys.has(block.key)) {
        uniqueBlockKeys.delete(block.key);
        return false;
      } else {
        return true;
      }
    });
  }

  return [];
};
