import { Alert, AlertProps, Snackbar, SnackbarProps } from '@mui/material';

interface ToastProps extends Pick<AlertProps, 'severity'>, Pick<SnackbarProps, 'open' | 'onClose'> {
  children: React.ReactNode;
}

const Toast = ({ open, onClose, severity, children }: ToastProps) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert severity={severity} elevation={6} variant="filled">
        {children}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
