import { LoadingSpinner } from '@airelogic/common';
import { ExistingTranslationsNotice } from '@airelogic/form-management/components';
import { useParams } from 'react-router';
import ReferenceDataFormEdit from '../ReferenceDataFormEdit/ReferenceDataFormEdit';
import { useGetReferenceData } from './Service';

interface Params {
  key: string;
  version?: string;
}

const ExistingReferenceDataPage = () => {
  const params = useParams<Params>();

  const { data, status, error } = useGetReferenceData(params);

  if (status === 'pending') {
    return <LoadingSpinner fullscreen={true} />;
  }

  if (status === 'error') {
    throw new Error(`Unexpected error occured: ${error}`);
  }

  return (
    <>
      <ReferenceDataFormEdit
        currentValues={{
          ...params,
          referenceData: data.concepts,
        }}
        isNew={false}
      />
      <ExistingTranslationsNotice
        showDialog={data.hasTranslations}
        noticeText={`This reference data set has existing translations. Editing labels will cause those translations to be lost.`}
      />
    </>
  );
};

export default ExistingReferenceDataPage;
