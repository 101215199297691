import {
  isValidXmlNodeName,
  optionalXPathExpressionSchema,
  xmlNameSchema,
} from '@airelogic/form-management/common/validations';
import { z } from 'zod';
import { NewRepeatGridIcon } from '../Assets/index';
import { FormStructureDefintition } from './types';

export const validationSchema = z
  .object({
    basicSettings: z.object({
      name: xmlNameSchema(),
      visibilityOptions: z.object({
        hideFromPdf: z.boolean(),
        hideFromRead: z.boolean(),
        hideFromEdit: z.boolean(),
      }),
    }),
    formulaSettings: z.object({
      visibility: optionalXPathExpressionSchema,
      readOnly: optionalXPathExpressionSchema,
    }),
    contentSettings: z
      .object({
        minimumRepetitions: z.number().gte(0).nullable(),
        maximumRepetitions: z.number().gte(1).nullable(),
        noItemsMessage: z.string().min(1, 'Message is required'),
        useCustomIterationName: z.boolean(),
        customIterationName: z.string(),
      })
      .superRefine((val, ctx) => {
        if (val.useCustomIterationName) {
          if (val.customIterationName.trim() === '') {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['customIterationName'],
              message: 'Custom iteration name is required',
            });
          } else if (isValidXmlNodeName(val.customIterationName) === false) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['customIterationName'],
              message: 'Custom iteration name is invalid',
            });
          }
        }
      })
      .superRefine((val, ctx) => {
        if (
          val.minimumRepetitions &&
          val.maximumRepetitions &&
          val.minimumRepetitions > val.maximumRepetitions
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['maximumRepetitions'],
            message: `Must be empty or greater than or equal to ${val.minimumRepetitions}`,
          });
        }
      }),
    additionalSettings: z.object({
      disableDeleteRow: z.boolean(),
      disableAddRow: z.boolean(),
      displayAsMasterChild: z.boolean(),
    }),
  })
  .strict();

export type RepeatSettings = z.infer<typeof validationSchema>;

export const repeatSettingsGenerator = (defaultName: string): RepeatSettings => ({
  basicSettings: {
    name: defaultName,
    visibilityOptions: {
      hideFromPdf: false,
      hideFromRead: false,
      hideFromEdit: false,
    },
  },
  formulaSettings: {
    visibility: '',
    readOnly: '',
  },
  contentSettings: {
    minimumRepetitions: 1,
    maximumRepetitions: null,
    noItemsMessage: 'No items.',
    useCustomIterationName: false,
    customIterationName: '',
  },
  additionalSettings: {
    disableAddRow: false,
    disableDeleteRow: false,
    displayAsMasterChild: false,
  },
});

export const repeatingGrid: FormStructureDefintition = {
  displayName: 'Repeating Grid',
  icon: NewRepeatGridIcon,
  type: 'REPEATED_GRID',
};
