import { DateIcon } from '../Assets';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = ['date'];

export const dateControl: ControlDefinition = {
  displayName: 'Date',
  icon: DateIcon,
  type: 'DATE_FIELD',
  supportedDataTypes: dataTypes,
  supportsInitialValue: true,
};
