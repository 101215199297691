import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  surface: {
    background: '#EDF0F2',
    padding: theme.spacing(2),
    width: '100%',
    marginTop: 0,
    marginBottom: 10,
  },

  outerGrid: {
    margin: 0,
  },

  spannerButtonError: {
    color: theme.palette.error.main,
  },
}));
