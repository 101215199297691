import { validateXPath } from '@airelogic/xpath';
import { z } from 'zod';
import {
  cannotStartWithNumberMessage,
  invalidResourceKeyMessage,
  invalidXmlNodeNameMessage,
  MustNotBeEmptyText,
} from './Messages';
import { isValidResourceKey } from './ResourceKey';

//borrowed from https://github.com/NaturalIntelligence/fast-xml-parser/blob/a281befb9eab1a9c5d9b1ed526fd0fca4c50e808/src/util.js
const nameStartChar =
  ':A-Za-z_\\u00C0-\\u00D6\\u00D8-\\u00F6\\u00F8-\\u02FF\\u0370-\\u037D\\u037F-\\u1FFF\\u200C-\\u200D\\u2070-\\u218F\\u2C00-\\u2FEF\\u3001-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFFD';
const nameChar = nameStartChar + '\\-.\\d\\u00B7\\u0300-\\u036F\\u203F-\\u2040';
const nameRegexp = '[' + nameStartChar + '][' + nameChar + ']*';
// eslint-disable-next-line no-misleading-character-class
const regexName = new RegExp(`^${nameRegexp}$`);

const youtubePath = new RegExp(/(?:youtube\.com|youtu\.be)\/watch\?v=/);
const startsWithNumberRegex = /^[0-9]+/;

export const isValidXmlNodeName = (value: string) => {
  return regexName.test(value);
};

export const isStartingWithANumber = (value: string) => {
  return startsWithNumberRegex.test(value);
};

export const isValidYoutubeVideo = (value: string) => {
  return youtubePath.test(value);
};

export const doesNotStartWithANumber = (value: string, ctx: z.RefinementCtx) => {
  if (startsWithNumberRegex.test(value)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: cannotStartWithNumberMessage,
    });
  }
};

export const isValidResourceKeySchema = (value: string, ctx: z.RefinementCtx) => {
  if (isValidResourceKey(value) === false) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: invalidResourceKeyMessage,
    });
  }
};

export const resourceKeySchema = () =>
  z.string().min(1, MustNotBeEmptyText).superRefine(isValidResourceKeySchema);

export const xmlNameSchema = () =>
  z
    .string()
    .min(1, MustNotBeEmptyText)
    .superRefine(doesNotStartWithANumber)
    .superRefine(isXmlNodeNameSchema);

export const isXmlNodeNameSchema = (value: string, ctx: z.RefinementCtx) => {
  if (isValidXmlNodeName(value) === false) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: invalidXmlNodeNameMessage,
    });
  }
};

export const requiredXPathExpressionSchema = z
  .string()
  .min(1)
  .superRefine((val, ctx) => {
    const xPathResult = validateXPath(val);
    if (xPathResult.isValid === false) {
      ctx.addIssue({
        code: 'custom',
        message: xPathResult.message,
      });
    }
  });

export const optionalXPathExpressionSchema = z.string().superRefine((val, ctx) => {
  if (val === '') {
    return;
  }
  const xPathResult = validateXPath(val);
  if (xPathResult.isValid === false) {
    ctx.addIssue({
      code: 'custom',
      message: xPathResult.message,
    });
  }
});
