import { PayloadAction } from '@reduxjs/toolkit';
import { getAllSectionIds, getGridById, getGridIdsInSection } from '../localSelectors';
import { IState } from '../types';

const rehydrate = (
  _: IState,
  action: PayloadAction<{ existingState: Omit<IState, 'layoutSelections'> }>,
) => {
  const newState = {
    ...action.payload.existingState,
    layoutSelections: {},
  };

  const firstSection = getAllSectionIds(newState)[0];
  const firstGridInSectionId = getGridIdsInSection(newState, firstSection)[0];
  const firstGrid = getGridById(newState, firstGridInSectionId);

  newState.layoutSelections = {
    selectedCell: {
      gridId: firstGrid.id,
      rowId: firstGrid.rowIds[0],
      columnId: firstGrid.columnIds[0],
    },
  };

  return newState;
};

export default rehydrate;
