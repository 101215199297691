import { z } from 'zod';
import { SecretIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = ['string'];

const validationSchema = z.object({
  omitFromSubmission: z.boolean(),
});

export type AdditionalSettings = z.infer<typeof validationSchema>;

export const passwordControl: ControlDefinition<AdditionalSettings> = {
  displayName: 'Password Field',
  icon: SecretIcon,
  type: 'PASSWORD_FIELD',
  supportedDataTypes: dataTypes,
  additionalSettings: {
    validationSchema,
    defaults: {
      omitFromSubmission: false,
    },
  },
  supportsInitialValue: true,
};
