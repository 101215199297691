import _ from 'lodash';
import { PropsWithChildren, createContext, useCallback, useContext, useEffect } from 'react';
import { FieldArrayWithId, UseFieldArrayReturn } from 'react-hook-form';
import { FormValues } from './Form';
import { repeatSettingsGenerator } from './FormStructures/RepeatingGrid';
import { generateNewGridName } from './NameGenerator';
import { selectAllRepeatingGridIds } from './StateManagement/Selectors/GridSelector';
import { useAppDispatch, useAppSelector } from './StateManagement/hooks';
import { updateSelectedComponent } from './StateManagement/layoutSlice';

const RepeatSettingsContext = createContext<{
  fields: FieldArrayWithId<FormValues, 'repeatSettings', 'key'>[];
  getById: (
    id: string,
  ) => (FieldArrayWithId<FormValues, 'repeatSettings', 'key'> & { index: number }) | undefined;
} | null>(null);

export const useRepeatSettingsContext = () => {
  const context = useContext(RepeatSettingsContext);

  if (context === null) {
    throw Error('useRepeatSettingsContext must be used within a RepeatSettingsProvider');
  }

  return context;
};

export const RepeatSettingsProvider = ({
  children,
  ...props
}: PropsWithChildren<UseFieldArrayReturn<FormValues, 'repeatSettings', 'key'>>) => {
  const repeatingGridIds = useAppSelector(selectAllRepeatingGridIds);
  const { fields, append, remove } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const itemsAdded = _.difference(
      repeatingGridIds,
      fields.map((f) => f.id),
    );

    if (itemsAdded.length === 0) {
      return;
    }

    if (itemsAdded.length > 1) {
      throw Error('More repeats were added than expected');
    }

    const newItem = itemsAdded[0];
    const defaultName = generateNewGridName(fields.map((x) => x.basicSettings.name));
    append({
      id: newItem,
      ...repeatSettingsGenerator(defaultName),
    });
    dispatch(updateSelectedComponent({ id: newItem, type: 'grid' }));
  }, [repeatingGridIds, append, fields, dispatch]);

  useEffect(() => {
    const itemsRemoved = _.difference(
      fields.map((f) => f.id),
      repeatingGridIds,
    );
    if (itemsRemoved.length > 0) {
      const indexesToRemove = fields.reduce((indexes, current, index) => {
        if (itemsRemoved.includes(current.id)) {
          indexes.push(index);
        }

        return indexes;
      }, Array<number>());
      remove(indexesToRemove);
    }
  }, [repeatingGridIds, remove, fields]);

  const getById = useCallback(
    (id: string) => {
      const index = fields.findIndex((rs) => rs.id === id);
      if (index === -1) return undefined;

      return {
        index,
        ...fields[index],
      };
    },
    [fields],
  );

  return (
    <RepeatSettingsContext.Provider
      value={{
        fields,
        getById,
      }}
    >
      {children}
    </RepeatSettingsContext.Provider>
  );
};
