import _ from 'lodash';
import { z } from 'zod';
import { validationSchema as controlSchema } from './Controls/ValidationSchema';
import { NameUnique } from './ErrorMessages';
import { validationSchema as repeatSchema } from './FormStructures/RepeatingGrid';
import { validationSchema as sectionSchema } from './FormStructures/Section';
import { validationSchema as propertiesSchema } from './PropertiesBar/Properties';

const controlSettingsSchema = controlSchema.array().min(1);

const sectionSettingsSchema = sectionSchema.extend({ id: z.string() }).array().min(1);
const repeatSettingsSchema = repeatSchema.extend({ id: z.string() }).array();

export const validationSchema = z
  .object({
    sectionSettings: sectionSettingsSchema,
    controlSettings: controlSettingsSchema,
    repeatSettings: repeatSettingsSchema,
    properties: propertiesSchema,
  })
  .strict()
  .superRefine((val, ctx) => {
    const joined = [
      ...val.sectionSettings.map((x, index) => ({
        path: `sectionSettings.${index}.basicSettings.name`,
        name: x.basicSettings.name,
      })),
      ...val.controlSettings.map((x, index) => ({
        path: `controlSettings.${index}.basicSettings.name`,
        name: x.basicSettings.name,
      })),
      ...val.repeatSettings.flatMap((x, index) => {
        const repeatName = {
          path: `repeatSettings.${index}.basicSettings.name`,
          name: x.basicSettings.name,
        };

        if (x.contentSettings.useCustomIterationName === false) {
          return [repeatName];
        }

        return [
          repeatName,
          {
            path: `repeatSettings.${index}.contentSettings.customIterationName`,
            name: x.contentSettings.customIterationName,
          },
        ];
      }),
    ];

    const duplicates = _(joined)
      .groupBy((x) => x.name)
      .pickBy((x) => x.length > 1)
      .values()
      .flatten();

    if (duplicates.size() > 0) {
      duplicates.forEach((dupe) => {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [dupe.path],
          message: NameUnique,
        });
      });
    }
  });
