import { generateResourceKey } from '@airelogic/form-management/common/validations';
import { RichText, SingleCheckbox, TextInput, When } from '@airelogic/form-management/components';
import { ChoiceOptions } from '@airelogic/form-management/referencedata';
import { TableCell, TableRow } from '@mui/material';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { AdditionalSettings, DropdownInlineRefData } from '../../../../Controls/Dropdown';
import { InlineReferenceData } from '../../../../Controls/InlineReferenceData';
import { FormValues } from '../../../../Form';
import { blankChoice } from '../Properties/Properties';

const useStyles = makeStyles()(() => ({
  borderRight: {
    borderRight: '1px solid #ccc',
    paddingTop: 0,
    paddingBottom: 0,
  },

  checkboxCell: {
    width: '50px',
  },
}));

interface Props {
  controlFieldIndex: number;
  itemFieldIndex: number;
  itemsLength: number;
  onRemove(index: number): void;
  onAdd(): void;
  onMove(fromIndex: number, toIndex: number): void;
  onInsert(index: number, object: DropdownInlineRefData): void;
}

const CheckboxRow = ({
  controlFieldIndex,
  itemFieldIndex,
  onRemove,
  onMove,
  onInsert,
  itemsLength,
}: Props) => {
  const { classes, cx } = useStyles();

  const removeChoice = () => {
    onRemove(itemFieldIndex);
  };

  const moveChoiceUp = () => {
    onMove(itemFieldIndex, itemFieldIndex - 1);
  };

  const moveChoiceDown = () => {
    onMove(itemFieldIndex, itemFieldIndex + 1);
  };

  const insertAbove = () => {
    onInsert(itemFieldIndex, blankChoice);
  };

  const insertBelow = () => {
    onInsert(itemFieldIndex + 1, blankChoice);
  };

  const {
    register,
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<FormValues>();

  const stopConditionWatch = watch(
    `controlSettings.${controlFieldIndex}.additionalSettings.items.${itemFieldIndex}.stopCondition`,
  );

  const getErrors = () => {
    return (
      errors?.controlSettings?.[controlFieldIndex]
        ?.additionalSettings as FieldErrors<AdditionalSettings>
    )?.items?.[itemFieldIndex] as FieldErrors<InlineReferenceData>;
  };

  const checkAndUpdateValue = (value: string) => {
    const oldValue = getValues(
      `controlSettings.${controlFieldIndex}.additionalSettings.items.${itemFieldIndex}.value`,
    );
    if (oldValue === '') {
      setValue(
        `controlSettings.${controlFieldIndex}.additionalSettings.items.${itemFieldIndex}.value`,
        generateResourceKey(value),
      );
    }
  };

  return (
    <TableRow>
      <TableCell align="left" className={classes.borderRight}>
        <ChoiceOptions
          choiceIndex={itemFieldIndex}
          removeChoice={removeChoice}
          moveChoiceUp={moveChoiceUp}
          moveChoiceDown={moveChoiceDown}
          insertAbove={insertAbove}
          insertBelow={insertBelow}
          isLastRow={itemFieldIndex === itemsLength - 1}
          disableDelete={itemFieldIndex === 0 && itemsLength === 1}
        />
      </TableCell>
      <TableCell align="left" className={classes.borderRight}>
        <TextInput
          label="Label"
          {...register(
            `controlSettings.${controlFieldIndex}.additionalSettings.items.${itemFieldIndex}.label`,
            {
              onBlur: (e) => checkAndUpdateValue(e.target.value),
            },
          )}
          error={getErrors()?.label !== undefined}
          helperText={getErrors()?.label?.message}
        />
      </TableCell>
      <TableCell align="left" className={classes.borderRight}>
        <TextInput
          label="Value"
          {...register(
            `controlSettings.${controlFieldIndex}.additionalSettings.items.${itemFieldIndex}.value`,
          )}
          error={getErrors()?.value !== undefined}
          helperText={getErrors()?.value?.message}
        />
      </TableCell>
      <TableCell align="center" className={cx(classes.checkboxCell, classes.borderRight)}>
        <SingleCheckbox
          label="Stop condition"
          displayLabel={false}
          control={control}
          name={`controlSettings.${controlFieldIndex}.additionalSettings.items.${itemFieldIndex}.stopCondition`}
        />
        <When condition={stopConditionWatch === true}>
          <div>
            <Controller
              control={control}
              name={`controlSettings.${controlFieldIndex}.additionalSettings.items.${itemFieldIndex}.stopConditionText`}
              render={({ field: { onChange, value } }) => (
                <RichText
                  defaultValue={value}
                  error={getErrors()?.stopConditionText !== undefined}
                  helperText={getErrors()?.stopConditionText?.message}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </When>
      </TableCell>
      <TableCell align="left" className={classes.borderRight}>
        <TextInput
          label="Filter groups"
          {...register(
            `controlSettings.${controlFieldIndex}.additionalSettings.items.${itemFieldIndex}.filterGroups`,
          )}
        />
      </TableCell>
      <TableCell align="center" className={cx(classes.checkboxCell, classes.borderRight)}>
        <SingleCheckbox
          label="Exclusive"
          displayLabel={false}
          control={control}
          name={`controlSettings.${controlFieldIndex}.additionalSettings.items.${itemFieldIndex}.optionIsExclusive`}
        />
      </TableCell>
      <TableCell align="center" className={cx(classes.checkboxCell, classes.borderRight)}>
        <SingleCheckbox
          label="Deprecated"
          displayLabel={false}
          control={control}
          name={`controlSettings.${controlFieldIndex}.additionalSettings.items.${itemFieldIndex}.deprecated`}
        />
      </TableCell>
    </TableRow>
  );
};

export default CheckboxRow;
