import { nanoid, PayloadAction } from '@reduxjs/toolkit';
import { ControlType } from '../../Controls/types';
import { getControlForCell, getGridById, getNextCell, getSelectedCell } from '../localSelectors';
import { Cell, IControl, IState, LayoutClipboard } from '../types';
import { addRowToGrid } from './gridReducers';

interface AddControlToCell extends AddControl {
  cell: Cell;
}

interface AddControl {
  controlType: ControlType;
  /**
   * Should only be used to aid testing.
   */
  controlId?: string;
  /**
   * Should only be used to aid testing.
   */
  rowId?: string;
}

interface PasteControl {
  clipboard: LayoutClipboard;
  /**
   * Should only be used to aid testing.
   */
  newControldId?: string;
}

export const createControl = (options: AddControlToCell): IControl => {
  const id = options.controlId ?? nanoid();

  return {
    id: id,
    type: options.controlType,
    cell: options.cell,
    cellSpan: 1,
  };
};

const pasteControl = (state: IState, payload: PasteControl) => {
  if (payload.newControldId) {
    /**
     * Should only be used to aid testing.
     */
    addControl(
      state,
      { controlType: payload.clipboard.originControl.type, controlId: payload.newControldId },
      true,
    );
  } else {
    addControl(state, { controlType: payload.clipboard.originControl.type }, true);
  }

  if (
    state.layoutSelections.clipboard &&
    payload.clipboard.action === 'cut' &&
    !payload.clipboard.cutControlPasted
  ) {
    state.layoutSelections.clipboard.cutControlPasted = true;
  }
};

const addControl = (state: IState, payload: AddControl, isPasting?: boolean) => {
  const nextPosition = getNextAvailableControlPosition(state, payload.rowId);
  if (nextPosition) {
    const control = createControl({ ...payload, cell: nextPosition });
    state.controls.push(control);
    state.layoutSelections.selectedCell = nextPosition;
    state.layoutSelections.selectedComponent = { id: control.id, type: 'control' };

    if (isPasting && state.layoutSelections.clipboard !== undefined) {
      state.layoutSelections.clipboard.newControlId = control.id;
    }
  }
};

const increaseCellSpan = (state: IState, controlId: string) => {
  const control = state.controls.find((c) => c.id === controlId);

  if (control) {
    const canIncrease = canIncreaseCellSpan(state, control);
    if (canIncrease) {
      control.cellSpan += 1;
    }
  }
};

const decreaseCellSpan = (state: IState, controlId: string) => {
  const control = state.controls.find((c) => c.id === controlId);

  if (control && control.cellSpan > 1) {
    control.cellSpan -= 1;
  }
};

export const controlReducers = {
  addControl: (state: IState, action: PayloadAction<AddControl>) => {
    addControl(state, action.payload);
  },
  deleteControl: (state: IState, action: PayloadAction<{ controlId: string }>) => {
    deleteControl(state, action.payload.controlId);
  },
  pasteControl: (state: IState, action: PayloadAction<PasteControl>) => {
    pasteControl(state, action.payload);
  },
  increaseCellSpan: (state: IState, action: PayloadAction<{ controlId: string }>) => {
    increaseCellSpan(state, action.payload.controlId);
  },
  decreaseCellSpan: (state: IState, action: PayloadAction<{ controlId: string }>) => {
    decreaseCellSpan(state, action.payload.controlId);
  },
};

const getNextAvailableControlPosition = (state: IState, rowId?: string) => {
  const selectedCell = getSelectedCell(state);

  if (selectedCell) {
    let position = selectedCell;
    const existingControl = getControlForCell(state, selectedCell);
    if (existingControl) {
      const nextCell = getNextCell(state, selectedCell, existingControl.cellSpan);
      if (nextCell) {
        if (getControlForCell(state, nextCell)) {
          return undefined;
        }
        position = nextCell;
      } else {
        const newRowId = addRowToGrid(state, {
          gridId: selectedCell.gridId,
          insertPosition: 'below',
          relativeRowId: selectedCell.rowId,
          rowId,
        });
        const firstColumnId = getGridById(state, selectedCell.gridId).columnIds[0];
        position = {
          gridId: selectedCell.gridId,
          rowId: newRowId,
          columnId: firstColumnId,
        };
      }
    }
    return position;
  }
  return undefined;
};

const canIncreaseCellSpan = (state: IState, control: IControl) => {
  if (control) {
    const grid = getGridById(state, control.cell.gridId);
    if (grid.columnIds.length < control.cellSpan) {
      return false;
    }

    const nextCell = getNextCell(state, control.cell, control.cellSpan);
    if (nextCell) {
      return getControlForCell(state, nextCell) === undefined;
    }
  }
  return false;
};

export const deleteControl = (state: IState, controlId: string) => {
  state.controls = state.controls.filter((c) => c.id !== controlId);
};
