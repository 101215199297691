import _ from 'lodash';

const sectionNameRegex = /^section-(\d+)$/;

export const generateNewSectionName = (existingNames: string[]) => {
  return `section-${getNextIndex(existingNames, sectionNameRegex)}`;
};

const controlNameRegex = /^control-(\d+)$/;

export const generateNewControlName = (existingNames: string[]) => {
  return `control-${getNextIndex(existingNames, controlNameRegex)}`;
};

export const generatePastedControlName = (existingNames: string[], targetName: string) => {
  if (existingNames.includes(targetName)) {
    return generateNewControlName(existingNames);
  } else {
    return targetName;
  }
};

const gridNameRegex = /^grid-(\d+)$/;

export const generateNewGridName = (existingNames: string[]) => {
  return `grid-${getNextIndex(existingNames, gridNameRegex)}`;
};

const getNextIndex = (existingNames: string[], regex: RegExp) => {
  const currentValues = existingNames.map((name) => {
    const result = regex.exec(name);
    if (!result) {
      return 0;
    }
    return Number(result[1]);
  });

  return (_.max(currentValues) ?? 0) + 1;
};
