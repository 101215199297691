import { optionalXPathExpressionSchema } from '@airelogic/form-management/common/validations';
import { z } from 'zod';
import { ImageSelectIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import {
  validationSchema as baseReferenceDataSchema,
  itemsSuperRefine,
  superRefine,
} from './InlineReferenceData';
import { ControlDefinition } from './types';

const referenceDataSchema = baseReferenceDataSchema.extend({
  imageBase64: z.string().min(1, { message: 'Image is required' }),
  hint: z.string(),
});

const dataTypes: DataType[] = [
  'string',
  'boolean',
  'email',
  'integer',
  'decimal',
  'date',
  'time',
  'datetime',
];

const defaultImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABiVBMVEUAAAAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlFDAlEy8kES0qJkI2UGw3Um5Ut9Q2UG1UuNUrJ0M3U284VXE3VHA7X3tQqMU6Xns3U3BFgp8kESwnHDhLl7NVudZLlrMjDiooHjpLl7RVutcmFjIoHTkoHTpHi6hSr8xHi6ckEi4yQFxKlbJKlrIyQl8lFTErKkYuNFA4VnIuNVFFg586X3s7X3w4VHE4VXJUttM3UW7///83YPpwAAAAUHRSTlMAAAMnaarY8fz98tmsbCkENJTe+9+XNxiH6eqLGzPA/sQ/19tEOBm/HAKNNejrOpso3eEtcbL297NzKuIvnu09kcPIHto8ScXJ7OMFLOC0LvA6jccAAAABYktHRIKLs/9EAAAAB3RJTUUH5AUEECQ1vf5t5gAAAfhJREFUGBllwUlPU1EcxuHfe+65HZhK1IiMxVAQiCUaSFwYP4FfmCi6gIUJKyIuFG3QRSUSxhKGTufvvS3RUp5H3NJdNaNLdEgjkt7QtSXpyIyUSIjH0it6bUtVDIgAMeX9GndMHkaZKwECMasV7ttVBUOIwoMViX5mu+4HJjSvJTmJu8yC7eS+mdNibQnJqY+TeOEkh3sEiHsERM9xi79LgMMlcCQcOLrmJJ+dBcxJgJcaZKRG9jrQEb2MjupFoN1uJQa9z2ZjKX8TDMzg05jit6QEhsYkzP4o3w5YOwBVP01qUq04VGkOCl3QoxJdzQNPvW/lM4XT6TiOo/yxYjMwA2IvEr9yg1wVKuxplp9GjwlPR/Mcd84/M+dNLtukvNFjQRELtlds5W1g2PaBA2/8p+NBYZfan5TVrUqi4jP0iJvCYqgKjFQpylaLICcUjWZCwlq5uhKk1sdd2bhVrDfGGxcXNxNT+Xy+nMsJWI20dPQaXCRwBBcARyqoGeD4s/s6uk1XCAQSoYMAnLiawwuwZfosG+B2EJqcL8ipzF27FqymjyZEqTgk0c9sc3UDE4g5K3Pf5tp7jAjQ6cPhL+P0uXmygUFEQic+O+TpdVK3DxgQ0XHccGetrRJd6zNnbuidkYroqh9ePxu42MkMczDAyEi88d3o+AvAfsE+F9VnMQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wNS0wNFQxNjozNjo1MyswMjowMJwwU1AAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDUtMDRUMTY6MzY6NTMrMDI6MDDtbevsAAAAV3pUWHRSYXcgcHJvZmlsZSB0eXBlIGlwdGMAAHic4/IMCHFWKCjKT8vMSeVSAAMjCy5jCxMjE0uTFAMTIESANMNkAyOzVCDL2NTIxMzEHMQHy4BIoEouAOoXEXTyQjWVAAAAAElFTkSuQmCC';

export const refinedReferenceDataSchema = referenceDataSchema.superRefine(superRefine);

export const validationSchema = z.object({
  display: z.enum(['full', 'compact']),
  choiceFilterXPath: optionalXPathExpressionSchema,
  items: refinedReferenceDataSchema.array().min(1).superRefine(itemsSuperRefine),
});

export type AdditionalSettings = z.infer<typeof validationSchema>;

export type ImageSelectInlineReferenceData = z.infer<typeof refinedReferenceDataSchema>;

export const presetItems: ImageSelectInlineReferenceData[] = [
  {
    label: 'First choice',
    value: 'first-choice',
    imageBase64: defaultImage,
    hint: '',
    stopCondition: false,
    stopConditionText: '',
    filterGroups: '',
    optionIsExclusive: false,
    deprecated: false,
    highlightAnswerInPDF: false,
  },
  {
    label: 'Second choice',
    value: 'second-choice',
    imageBase64: defaultImage,
    hint: '',
    stopCondition: false,
    stopConditionText: '',
    filterGroups: '',
    optionIsExclusive: false,
    deprecated: false,
    highlightAnswerInPDF: false,
  },
  {
    label: 'Third choice',
    value: 'third-choice',
    imageBase64: defaultImage,
    hint: '',
    stopCondition: false,
    stopConditionText: '',
    filterGroups: '',
    optionIsExclusive: false,
    deprecated: false,
    highlightAnswerInPDF: false,
  },
];

export const imageSelectControl: ControlDefinition<AdditionalSettings> = {
  displayName: 'Image Select',
  icon: ImageSelectIcon,
  type: 'IMAGE_SELECT',
  supportedDataTypes: dataTypes,
  additionalSettings: {
    validationSchema,
    defaults: {
      display: 'full',
      choiceFilterXPath: '',
      items: presetItems,
    },
  },
  supportsInitialValue: false,
};
