import { optionalXPathExpressionSchema } from '@airelogic/form-management/common';
import { z } from 'zod';

const styles = z.enum(['default', 'buttons', 'buttons-full-width']);

export const schema = z.object({
  style: styles,
  withOther: z.boolean(),
  choiceFilterXPath: optionalXPathExpressionSchema,
});

export type Styles = z.infer<typeof styles>;
export type Settings = z.infer<typeof schema>;
