import {
  doesNotStartWithANumber,
  isValidResourceKeySchema,
  MustNotBeEmptyText,
  ResourceKeyRegex,
} from '@airelogic/form-management/common/validations';
import { validateXPath } from '@airelogic/xpath';
import _ from 'lodash';
import { z } from 'zod';
import { getDuplicateBuildingBlocks } from './FormBuilding.Service';

const notEmptyString = z.string().min(1, MustNotBeEmptyText);

export const readVisibilities = ['alwaysvisible', 'hidefrompdf', 'hidefromreadandpdf'] as const;
export type PdfReadVisibilityField = (typeof readVisibilities)[number];
export const deliveryStatuses = [
  'Development',
  'UAT',
  'Production',
  'Deprecated',
  'Withdrawn',
  'Prototype',
] as const;
export type DeliveryStatus = (typeof deliveryStatuses)[number];

export const buildingBlockViews = ['inherit', 'edit', 'read'] as const;
type BuildingBlockView = (typeof buildingBlockViews)[number];
export const pdfStyles = ['none', 'default', 'faxable'] as const;
type PdfSettingsStyle = (typeof pdfStyles)[number];
type NewTerminologyLayout = 'document' | 'wizard';

const buildingBlockSchema = z
  .object({
    key: notEmptyString,
    version: z.number(),
    pdfReadVisibility: z.enum(readVisibilities),
    view: z.enum(buildingBlockViews),
    relevance: z.enum(['alwaysdisplay', 'customrule']),
    customRule: z.string(),
    prePopulationEnabled: z.boolean(),
    prePopulationRules: z
      .object({
        rule: z.string(),
        useCorrelationId: z.boolean(),
        dataOnly: z.boolean(),
      })
      .refine((prePop) => prePop.rule.length <= 80, {
        path: ['prePopulationRule'],
        message: 'Must be less than 80 characters long',
      }),
  }) /*
  The below are defined as refines rather than on the object, as otherwise for example a bb was not selected the custom rule refine would not get run as per:  https://github.com/colinhacks/zod/issues/479  
  */
  .refine((bb) => bb.key !== '', { path: ['key'], message: MustNotBeEmptyText })
  .superRefine((bb, ctx) => {
    if (bb.prePopulationEnabled && /^\s*$/.test(bb.prePopulationRules.rule)) {
      ctx.addIssue({
        code: 'custom',
        path: ['prePopulationRules', 'rule'],
        message: MustNotBeEmptyText,
      });
    }
  })
  .superRefine((bb, ctx) => {
    const path = 'customRule';
    if (bb.relevance === 'alwaysdisplay') {
      return;
    }

    if (bb.customRule === '') {
      ctx.addIssue({
        code: 'custom',
        path: [path],
        message: MustNotBeEmptyText,
      });
      return;
    }

    const xPathResult = validateXPath(bb.customRule);
    if (xPathResult.isValid === false) {
      ctx.addIssue({
        code: 'custom',
        path: [path],
        message: xPathResult.message,
      });
    }
  });

const pageSchema = z.object({
  title: notEmptyString.max(80, 'Must be less than 80 characters long'),
  buildingBlocks: buildingBlockSchema.array().min(1),
});

export const formContextItemSchema = z.object({
  key: notEmptyString.superRefine(doesNotStartWithANumber).superRefine(isValidResourceKeySchema),
  value: notEmptyString,
});

const layoutSchema = z.enum(['clinician', 'single-pass']);

export const formContextSuperRefine = (items: FormContext[], ctx: z.RefinementCtx) => {
  const keys = items.map((kvp, i) => ({
    key: kvp.key,
    path: `${i}.key`,
  }));

  const duplicates = _(keys)
    .groupBy((x) => x.key)
    .pickBy((x) => x.length > 1)
    .values()
    .flatten();

  if (duplicates.size() > 0) {
    duplicates.forEach((dupe) => {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: [dupe.path],
        message: 'Key name must be unique',
      });
    });
  }
};

export const schema = z
  .object({
    metadata: z.object({
      key: notEmptyString.regex(ResourceKeyRegex, 'Key must not contain whitespace'),
      title: notEmptyString.max(120, 'Must be less than 120 characters long'),
      version: z.number().optional(),
      deliveryStatus: z.enum(deliveryStatuses),
      layout: layoutSchema,
      classification: z.object({
        label: z.string(),
        value: z.string(),
        inputValue: notEmptyString.max(60, 'Must be less than 60 characters long'),
      }),
    }),
    pdfSettings: z.object({
      displaySectionTitles: z.boolean(),
      displayPageTitles: z.boolean(),
      style: z.enum(pdfStyles),
    }),
    persistenceSettings: z.object({
      allowAmend: z.boolean(),
      allowDiscard: z.boolean(),
      allowDrafts: z.boolean(),
      autoSaveInterval: z.number().gte(0),
    }),
    formContext: formContextItemSchema.array().superRefine(formContextSuperRefine),
    pages: pageSchema.array().min(1),
    hasTranslations: z.boolean(),
  })
  .strict()
  .superRefine((val, ctx) => {
    const invalidBlocks = getDuplicateBuildingBlocks(val);

    if (invalidBlocks.length > 0) {
      invalidBlocks.forEach((b) => {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [`pages.${b.index}.buildingBlocks.${b.innerIndex}.key`],
          message: `This block is used more than once in the form`,
        });
      });
    }
  });

export const createEmptyBuildingBlock = (): BuildingBlockSchema => ({
  key: '',
  version: 0,
  view: 'inherit',
  prePopulationEnabled: false,
  prePopulationRules: {
    rule: '',
    useCorrelationId: false,
    dataOnly: false,
  },
  pdfReadVisibility: 'alwaysvisible',
  relevance: 'alwaysdisplay',
  customRule: '',
});

export const createEmptyForm = (): FormSchema => ({
  metadata: {
    title: '',
    key: '',
    version: 1,
    deliveryStatus: 'Development',
    layout: 'single-pass',
    classification: {
      label: 'Uncategorised',
      value: 'Uncategorised',
      inputValue: 'Uncategorised',
    },
  },
  pages: [
    {
      title: '',
      buildingBlocks: [createEmptyBuildingBlock()],
    },
  ],
  persistenceSettings: {
    allowAmend: false,
    allowDiscard: false,
    allowDrafts: true,
    autoSaveInterval: 30,
  },
  pdfSettings: {
    style: 'default',
    displayPageTitles: false,
    displaySectionTitles: true,
  },
  formContext: [],
  hasTranslations: false,
});

export const createEmptyFormContext = (): FormContext[] => [
  {
    key: '',
    value: '',
  },
];

export type BuildingBlockSchema = z.infer<typeof buildingBlockSchema>;
export type Layout = z.infer<typeof layoutSchema>;
export type PageSchema = z.infer<typeof pageSchema>;
export type FormSchema = z.infer<typeof schema>;

export type FormContextItemSchema = z.infer<typeof formContextItemSchema>;

export interface BuildingBlockVersionData {
  readonly key: string;
  readonly versions: number[];
}

export interface Form {
  key: string;
  layout: string;
  title: string;
  deliveryStatus: DeliveryStatus;
  classification: string;
  showBanner: boolean;
  persistenceSettings: {
    allowAmend: boolean;
    allowDiscard: boolean;
    allowDrafts: boolean;
    autoSaveInterval: number;
  };
  pdfSettings: {
    style: string;
    displayPageTitles: boolean;
    displaySectionTitles: boolean;
  };
  formContext: FormContext[];
  pages: Page[];
}

export interface Page {
  title: string;
  buildingBlocks: BuildingBlock[];
}

export interface BuildingBlock {
  key: string;
  version: number;
  view: BuildingBlockView;
  pdfIgnore: boolean;
  readIgnore: boolean;
  prePopulationRules: {
    rule: string;
    useCorrelationId: boolean;
    dataOnly: boolean;
  } | null;
  relevanceXPath: string;
}

export interface GetFormResponse {
  key: string;
  version: number;
  title: string;
  deliveryStatus: DeliveryStatus;
  classification: string;
  layout: NewTerminologyLayout;
  showBanner: boolean;
  persistenceSettings: GetFormResponsePersistenceSettings;
  pdfSettings: GetFormResponsePdfSettings;
  formContext: FormContext[];
  pages: Page[];
  hasTranslations: boolean;
}

export interface GetFormMetadata {
  key: string;
  version: number;
  title: string;
  deliveryStatus: DeliveryStatus;
  classification: string;
  layout: NewTerminologyLayout;
}

export interface GetFormResponsePersistenceSettings {
  allowAmend: boolean;
  allowDiscard: boolean;
  allowDrafts: boolean;
  autoSaveInterval: number;
}

export interface GetFormResponsePdfSettings {
  style: PdfSettingsStyle;
  displayPageTitles: boolean;
  displaySectionTitles: boolean;
}

export interface FormPreview {
  layout: string;
  title: string;
  pages: Page[];
}

export type FormContext = {
  key: string;
  value: string;
};
