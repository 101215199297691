import { clearableLocalStorageKeyPrefix } from '@airelogic/form-management/common';
import React from 'react';
import { BuildingBlock } from '../BuildingBlock';

const ConfirmationText = ({ buildingBlock }: { buildingBlock: BuildingBlock }) => {
  return (
    <>
      <p>
        Proceeding will immediately overwrite the currently in-use (possibly including in live
        operation) building block <b>{buildingBlock.blockKey}</b> at version{' '}
        <b>{buildingBlock.latestVersion}</b>.
      </p>
      <p>Are you sure you wish to continue?</p>
    </>
  );
};

const importOverwiteConfirmationsStorageKey = `${clearableLocalStorageKeyPrefix}-import-overwrite-confirmations`;

export const useImportOverwriteConfirmationStore = () => {
  const getItems = () => {
    const confirmations = localStorage.getItem(importOverwiteConfirmationsStorageKey);
    return confirmations === null ? new Array<string>() : (JSON.parse(confirmations) as string[]);
  };

  const hasBeenConfirmed = (buildingBlock: BuildingBlock) => {
    return getItems().includes(buildingBlock.blockKey);
  };

  const addToConfirmations = (buildingBlock: BuildingBlock) => {
    const existing = getItems();
    existing.push(buildingBlock.blockKey);
    localStorage.setItem(importOverwiteConfirmationsStorageKey, JSON.stringify(existing));
  };

  return { hasBeenConfirmed, addToConfirmations };
};

export const useImportOverwriteConfirmation = (
  buildingBlock: BuildingBlock,
  onConfirm: () => Promise<void>,
) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState<boolean>(false);

  const { hasBeenConfirmed, addToConfirmations } = useImportOverwriteConfirmationStore();

  const handleImportOverwriteClick = () => {
    if (hasBeenConfirmed(buildingBlock)) {
      onConfirm();
    } else {
      setShowConfirmationDialog(true);
    }
  };

  const cancelImport = () => {
    setShowConfirmationDialog(false);
  };

  const confirmImport = async () => {
    setShowConfirmationDialog(false);
    addToConfirmations(buildingBlock);
    await onConfirm();
  };

  return {
    handleImportOverwriteClick,
    importOverwriteConfirmationDialogProps: {
      open: showConfirmationDialog,
      handleCancel: cancelImport,
      handleConfirm: confirmImport,
      confirmationText: <ConfirmationText buildingBlock={buildingBlock} />,
    },
  };
};
