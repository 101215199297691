import { NavLinkButton } from '@airelogic/form-management/components';
import { Container, Theme } from '@mui/material';
import { useRouteMatch } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import BuildingBlockTable from './BuildingBlockTable';
import UploadButton from './Upload';

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const BuildingBlocks = () => {
  const { classes: styles } = useStyles();

  const { path } = useRouteMatch();

  return (
    <Container>
      <NavLinkButton
        variant="contained"
        color="primary"
        className={styles.button}
        to={`${path}/designer/new`}
      >
        Create new
      </NavLinkButton>
      <UploadButton />

      <BuildingBlockTable />
    </Container>
  );
};

export default BuildingBlocks;
