import { NumberIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = ['integer', 'decimal'];

export const numberControl: ControlDefinition = {
  displayName: 'Number Field',
  icon: NumberIcon,
  type: 'NUMBER_FIELD',
  supportedDataTypes: dataTypes,
  supportsInitialValue: true,
};
