import {
  ActionMenu,
  ActionMenuDivider,
  ActionMenuItem,
  SmallMenuIcon,
  When,
} from '@airelogic/form-management/components';
import { SvgIcon } from '@mui/material';
import { useState } from 'react';
import { BuildingBlock } from '../BuildingBlock';

import RestoreIcon from '@mui/icons-material/Restore';
import DownloadIcon from '@mui/icons-material/SaveAltOutlined';
import { ReactComponent as EyeLineIconSVG } from './SVGs/eye-line.svg';
import { ReactComponent as ImportIconSVG } from './SVGs/import-icon.svg';
import { ReactComponent as NewVersionIconSVG } from './SVGs/new-version.svg';
import { ReactComponent as OverwriteIconSVG } from './SVGs/overwrite-icon.svg';

export type Action =
  | 'import'
  | 'importNewVersion'
  | 'importOverwrite'
  | 'previewWizard'
  | 'previewDocument'
  | 'rollback'
  | 'download';

type Props = {
  buildingBlock: BuildingBlock;
  onClick: (action: Action) => void;
};

const Menu = ({ buildingBlock, onClick }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (action: Action) => {
    handleClose();
    onClick(action);
  };

  return (
    <>
      <SmallMenuIcon
        id={`actions-menu-button-${buildingBlock.blockKey}`}
        aria-controls={open ? `basic-menu-${buildingBlock.blockKey}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        tooltipText={`Additional actions for ${buildingBlock.blockKey}`}
        onClick={handleOpen}
      />
      <ActionMenu
        id={`menu-${buildingBlock.blockKey}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-label': `Action menu for ${buildingBlock.blockKey}`,
        }}
      >
        <ActionMenuItem
          onClick={() => handleClick('previewDocument')}
          text="Clinician facing preview"
          icon={<SvgIcon component={EyeLineIconSVG} />}
        ></ActionMenuItem>
        <ActionMenuItem
          onClick={() => handleClick('previewWizard')}
          text="Single pass preview"
          icon={<SvgIcon component={EyeLineIconSVG} />}
        ></ActionMenuItem>
        <When
          condition={
            buildingBlock.importStatus === 'UNKNOWN' ||
            buildingBlock.importStatus === 'UPDATE_AVAILABLE'
          }
        >
          <ActionMenuDivider />
          <ActionMenuItem
            onClick={() => handleClick('importNewVersion')}
            text="Create new version"
            icon={<SvgIcon component={NewVersionIconSVG} />}
          ></ActionMenuItem>

          <ActionMenuItem
            onClick={() => handleClick('importOverwrite')}
            text="Overwrite current version"
            icon={<SvgIcon component={OverwriteIconSVG} />}
          ></ActionMenuItem>
        </When>

        <When condition={buildingBlock.importStatus === 'NOT_IMPORTED'}>
          <ActionMenuItem
            onClick={() => handleClick('import')}
            text="Import"
            icon={<SvgIcon component={ImportIconSVG} />}
          ></ActionMenuItem>
        </When>
        <ActionMenuDivider />
        <When condition={buildingBlock.importStatus === 'UPDATE_AVAILABLE'}>
          <ActionMenuItem
            onClick={() => handleClick('rollback')}
            text="Rollback"
            icon={<RestoreIcon />}
          />

          <ActionMenuDivider />
        </When>
        <ActionMenuItem
          onClick={() => handleClick('download')}
          text="Download"
          icon={<DownloadIcon />}
        ></ActionMenuItem>
      </ActionMenu>
    </>
  );
};

export default Menu;
