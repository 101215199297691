import { useFormContext } from 'react-hook-form';
import { FormValues } from '../../../Form';
import AdvancedAnnotatedImage from './AdvancedAnnotatedImage';
import AnnotatedImage from './AnnotatedImage';
import AutotranslateOptions from './AutotranslateOptions';
import CheckboxesAdditionalSettings from './Checkboxes';
import DropdownAdditionalSettings from './Dropdown';
import DynamicDropdownAdditionalSettings from './DynamicDropdownAdditionalSettings';
import ExplanatoryTextAdditionalSettings from './ExplanatoryTextAdditionalSettings';
import ImageSelectAdditionalSettings from './ImageSelect';
import LazyDropdownAdditionalSettings from './LazyDropdownAdditionalSettings';
import PasswordFieldAdditionalSettings from './PasswordFieldAdditionalSettings';
import RadioButtonAdditionalSettings from './RadioButton';
import RichTextSelectAdditionalSettings from './RichTextSelectAdditionalSettings';
import ScoreAdditionalSettings from './ScoreAdditionalSettings';
import ServerSideCalcAdditionalSettings from './ServerSideCalcAdditionalSettings';
import SliderAdditionalSettings from './SliderAdditionalSettings';
import StaticImageAdditionalSettings from './StaticImage';
import YesNoField from './YesNoField';
import YoutubeAdditionalSettings from './YoutubeAdditionalSettings';

interface Props {
  fieldIndex: number;
}

export const OptionsResolver = ({ fieldIndex }: Props) => {
  const { watch } = useFormContext<FormValues>();
  const controlType = watch(`controlSettings.${fieldIndex}.type`);

  switch (controlType) {
    case 'ADVANCED_ANNOTATED_IMAGE':
      return <AdvancedAnnotatedImage fieldIndex={fieldIndex} />;
    case 'ANNOTATED_IMAGE':
      return <AnnotatedImage fieldIndex={fieldIndex} />;
    case 'CHECKBOX':
      return <CheckboxesAdditionalSettings fieldIndex={fieldIndex} />;
    case 'RADIO_BUTTON':
      return <RadioButtonAdditionalSettings fieldIndex={fieldIndex} />;
    case 'DROPDOWN':
      return <DropdownAdditionalSettings fieldIndex={fieldIndex} />;
    case 'DYNAMIC_DROPDOWN':
      return <DynamicDropdownAdditionalSettings fieldIndex={fieldIndex} />;
    case 'EXPLANATORY_TEXT':
      return <ExplanatoryTextAdditionalSettings fieldIndex={fieldIndex} />;
    case 'IMAGE_SELECT':
      return <ImageSelectAdditionalSettings fieldIndex={fieldIndex} />;
    case 'RICH_TEXT_SELECT':
      return <RichTextSelectAdditionalSettings fieldIndex={fieldIndex} />;
    case 'SCORE_FIELD':
      return <ScoreAdditionalSettings fieldIndex={fieldIndex} />;
    case 'LAZY_SELECT':
      return <LazyDropdownAdditionalSettings fieldIndex={fieldIndex} />;
    case 'SERVER_SIDE_CALCULATION':
      return <ServerSideCalcAdditionalSettings fieldIndex={fieldIndex} />;
    case 'SLIDER':
      return <SliderAdditionalSettings fieldIndex={fieldIndex} />;
    case 'STATIC_IMAGE':
      return <StaticImageAdditionalSettings fieldIndex={fieldIndex} />;
    case 'YOUTUBE':
      return <YoutubeAdditionalSettings fieldIndex={fieldIndex} />;
    case 'BOOLEAN_BUTTONS':
      return <YesNoField fieldIndex={fieldIndex} />;
    case 'PASSWORD_FIELD':
      return <PasswordFieldAdditionalSettings fieldIndex={fieldIndex} />;
    case 'TEXT_FIELD':
    case 'TEXT_AREA':
      return <AutotranslateOptions fieldIndex={fieldIndex} />;
    default:
      return null;
  }
};

export default OptionsResolver;
