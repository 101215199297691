import { EmailIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = ['email'];

export const emailControl: ControlDefinition = {
  displayName: 'Email Address',
  icon: EmailIcon,
  type: 'EMAIL_ADDRESS',
  supportedDataTypes: dataTypes,
  supportsInitialValue: true,
};
