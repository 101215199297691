import { SingleCheckbox, TextInput } from '@airelogic/form-management/components';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { AdditionalSettings } from '../../../Controls/Score';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';

interface Props {
  fieldIndex: number;
}

const ScoreAdditionalSettings = ({ fieldIndex }: Props) => {
  const {
    setValue,
    getValues,
    watch,
    register,
    control,
    formState: { errors },
  } = useFormContext<FormValues>();

  const getErrors = () => {
    return errors?.controlSettings?.[fieldIndex]?.additionalSettings as
      | FieldErrors<AdditionalSettings>
      | undefined;
  };

  const reverseWatch = watch(`controlSettings.${fieldIndex}.additionalSettings.reverseOrder`);
  const includeNeutralWatch = watch(
    `controlSettings.${fieldIndex}.additionalSettings.includeNeutral`,
  );
  const gradedWatch = watch(`controlSettings.${fieldIndex}.additionalSettings.graded`);

  const updateValues = () => {
    const grades = watch(`controlSettings.${fieldIndex}.additionalSettings.grades`);
    if (grades !== undefined) {
      if (!includeNeutralWatch) {
        setValue(`controlSettings.${fieldIndex}.additionalSettings.grades.2`, grades[1]);
      } else {
        setValue(`controlSettings.${fieldIndex}.additionalSettings.grades.3`, grades[2]);
      }
    }
  };

  const resetValues = () => {
    setValue(`controlSettings.${fieldIndex}.additionalSettings.grades`, []);
    setValue(`controlSettings.${fieldIndex}.additionalSettings.includeNeutral`, false);
    setValue(`controlSettings.${fieldIndex}.additionalSettings.reverseOrder`, false);
  };

  const returnValue = (index: number) => {
    const val = getValues(`controlSettings.${fieldIndex}.additionalSettings.grades.${index}`)!;
    if (isNaN(val)) {
      return '';
    }
    return val;
  };

  const returnLabel = (index: number) => {
    let val = '' + returnValue(index);

    if (val === '') {
      val = '';
    }

    if (reverseWatch) {
      if (includeNeutralWatch) {
        if (index === 0) {
          return 'Red when value is >= ' + val;
        } else if (index === 1) {
          return 'Amber when < ' + val + ' and >=';
        } else if (index === 2) {
          return 'Green when < ' + val + ' and >=';
        } else if (index === 3) {
          return 'Neutral when < ';
        }
      } else {
        if (index === 0) {
          return 'Red when value is >= ' + val;
        } else if (index === 1) {
          return 'Amber when < ' + val + ' and >=';
        } else if (index === 2) {
          return 'Green when < ';
        }
      }
    } else {
      if (includeNeutralWatch) {
        if (index === 0) {
          return 'Neutral when value is >= ' + val;
        } else if (index === 1) {
          return 'Green when < ' + val + ' and >=';
        } else if (index === 2) {
          return 'Amber when < ' + val + ' and >=';
        } else if (index === 3) {
          return 'Red when < ';
        }
      } else {
        if (index === 0) {
          return 'Green when value is >= ' + val;
        } else if (index === 1) {
          return 'Amber when < ' + val + ' and >=';
        } else if (index === 2) {
          return 'Red when < ';
        }
      }
    }
    return 'Undefined';
  };

  return (
    <PanelWithHeader title="Additional Settings">
      <SingleCheckbox
        label="Graded"
        control={control}
        name={`controlSettings.${fieldIndex}.additionalSettings.graded`}
        rules={{
          onChange: () => {
            resetValues();
          },
        }}
      />

      {gradedWatch ? (
        <>
          <SingleCheckbox
            label="Include Neutral"
            control={control}
            name={`controlSettings.${fieldIndex}.additionalSettings.includeNeutral`}
            rules={{
              deps: [`controlSettings.${fieldIndex}.additionalSettings.grades`],
              onChange: () => {
                updateValues();
              },
            }}
          />

          <SingleCheckbox
            label="Reverse Order"
            control={control}
            name={`controlSettings.${fieldIndex}.additionalSettings.reverseOrder`}
            rules={{
              onChange: () => {
                updateValues();
              },
            }}
          />

          <TextInput
            id="grades0"
            type="number"
            required
            label={returnLabel(0)}
            error={!!getErrors()?.grades?.[0]}
            helperText={getErrors()?.grades?.[0]?.message}
            {...register(`controlSettings.${fieldIndex}.additionalSettings.grades.0`, {
              valueAsNumber: true,
              onChange: () => {
                updateValues();
              },
            })}
          />
          <TextInput
            id="grades1"
            type="number"
            required
            label={returnLabel(1)}
            error={!!getErrors()?.grades?.[1]}
            helperText={getErrors()?.grades?.[1]?.message}
            {...register(`controlSettings.${fieldIndex}.additionalSettings.grades.1`, {
              valueAsNumber: true,
              onChange: () => {
                updateValues();
              },
            })}
          />
          {includeNeutralWatch ? (
            <>
              <TextInput
                id="grades2"
                required
                type="number"
                disabled={!includeNeutralWatch}
                label={returnLabel(2)}
                error={!!getErrors()?.grades?.[2]}
                helperText={getErrors()?.grades?.[2]?.message}
                {...register(`controlSettings.${fieldIndex}.additionalSettings.grades.2`, {
                  valueAsNumber: true,
                  onChange: () => {
                    updateValues();
                  },
                })}
              />
              <TextInput
                hidden={!includeNeutralWatch}
                disabled={includeNeutralWatch}
                type="number"
                id="grades3"
                label={returnLabel(3)}
                value={returnValue(2)}
              />
            </>
          ) : (
            <TextInput
              id="grades2"
              type="number"
              disabled={!includeNeutralWatch}
              label={returnLabel(2)}
              value={returnValue(1)}
            />
          )}
        </>
      ) : null}
    </PanelWithHeader>
  );
};

export default ScoreAdditionalSettings;
