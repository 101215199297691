import { SingleCheckbox, TextInput, When } from '@airelogic/form-management/components';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';

interface Props {
  fieldIndex: number;
}

const GlobalAlertMessage = ({ fieldIndex }: Props) => {
  const { register, control, watch } = useFormContext<FormValues>();

  const useDefaultAlertWatch = watch(
    `controlSettings.${fieldIndex}.validationSettings.defaultAlertMessage.useGlobalAlert`,
  );

  return (
    <PanelWithHeader title="Default Alert Message">
      <SingleCheckbox
        label="Use global default alert"
        control={control}
        name={`controlSettings.${fieldIndex}.validationSettings.defaultAlertMessage.useGlobalAlert`}
      />
      <When condition={!useDefaultAlertWatch}>
        <TextInput
          type="text"
          label="Default Alert Message"
          {...register(
            `controlSettings.${fieldIndex}.validationSettings.defaultAlertMessage.alertMessage`,
          )}
        />
      </When>
    </PanelWithHeader>
  );
};

export default GlobalAlertMessage;
