import { CalculatedValueIcon } from '../Assets';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = [
  'string',
  'boolean',
  'email',
  'integer',
  'decimal',
  'date',
  'time',
  'datetime',
];

export const calculatedValueControl: ControlDefinition = {
  displayName: 'Calculated Value',
  icon: CalculatedValueIcon,
  type: 'CALCULATED_VALUE',
  supportedDataTypes: dataTypes,
  supportsInitialValue: true,
};
