import { Button, ButtonProps } from '@mui/material';

import { NavLinkProps, NavLink as RouterLink } from 'react-router-dom';
import { useStyles } from './Styles';

type Props = ButtonProps<'a'> & Omit<NavLinkProps, 'color'>;

const NavLinkButton = (props: Props) => {
  const { classes, cx } = useStyles({
    color: props.color,
  });

  return (
    <Button component={RouterLink} {...props} className={cx(props.className, classes.link)}>
      {props.children}
    </Button>
  );
};

export default NavLinkButton;
