/* eslint-disable react/prop-types */
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { useSessionStorage } from '@uidotdev/usehooks';
import { MRT_ColumnDef, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMemo } from 'react';
import { ExistingForm } from './ExistingForm';
import Actions from './FormsTableActions';
import { useGetForms } from './FormsTableQueries';
import PreviousVersionsTable from './PreviousFormVersionsTable';

const formTypeLookup: Record<string, string> = {
  wizard: 'Single-Pass',
  document: 'Clinician',
};

const createSessionStorageKey = (itemKey: string) => `formsTable.v2.${itemKey}`;

const Table = () => {
  const { data, isLoading, isRefetching, refetch, isError } = useGetForms();

  const [globalFilter, setGlobalFilter] = useSessionStorage(
    createSessionStorageKey('globalFilter'),
    '',
  );

  const columns = useMemo<MRT_ColumnDef<ExistingForm>[]>(
    () => [
      {
        header: 'Key',
        accessorKey: 'key',
        minSize: 200,
        size: 200,
      },
      {
        header: 'Version',
        accessorKey: 'version',
        size: 100,
        maxSize: 100,
      },
      {
        header: 'Title',
        accessorKey: 'title',
        size: 250,
      },
      {
        header: 'Form type',
        accessorKey: 'layout',
        Cell: ({ cell }) => {
          return <>{formTypeLookup[cell.getValue<string>()]}</>;
        },
        size: 100,
        maxSize: 100,
      },
      {
        header: 'Delivery status',
        accessorKey: 'deliveryStatus',
        size: 100,
        maxSize: 150,
      },
      {
        header: 'Classification',
        accessorKey: 'classification',
        size: 100,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data: data ?? [],
    enableColumnActions: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnOrdering: false,
    enableHiding: false,
    enableMultiSort: false,
    enableGrouping: false,
    initialState: {
      showGlobalFilter: true,
      sorting: [{ id: 'key', desc: false }],
    },
    state: {
      globalFilter,
      isLoading,
      showProgressBars: isRefetching,
      showAlertBanner: isError,
    },
    defaultColumn: {
      enableColumnFilter: false,
      enableGlobalFilter: true,
    },
    mrtTheme: () => ({
      baseBackgroundColor: '#fff',
    }),
    muiTopToolbarProps: {
      sx: (theme) => ({
        padding: theme.spacing(1),
      }),
    },
    muiSearchTextFieldProps: {
      variant: 'standard',
      inputProps: {
        'aria-label': 'Search',
      },
    },
    renderTopToolbarCustomActions: () => <Typography variant="h6">Forms</Typography>,
    renderToolbarInternalActions: () => (
      <Tooltip arrow title="Refresh Data">
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => <Actions existingForm={row.original} isLatestVersion={true} />,
    onGlobalFilterChange: setGlobalFilter,
    muiExpandButtonProps: ({ row }) => ({
      sx: {
        display: row.original.version === 1 ? 'none' : 'flex', //Bit of a bodge
      },
    }),
    positionExpandColumn: 'last',
    muiDetailPanelProps: {
      sx: (theme) => ({
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        backgroundColor: '#EDF0F2',
      }),
    },
    enableExpandAll: false,
    globalFilterFn: 'contains',
    renderDetailPanel: ({ row }) =>
      row.original.version !== 1 ? <PreviousVersionsTable formKey={row.original.key} /> : undefined,
    getRowCanExpand: (row) => row.original.version !== 1,
  });

  return <MaterialReactTable table={table} />;
};

export default Table;
