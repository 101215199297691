import { TextInput, When } from '@airelogic/form-management/components';
import { useFormContext } from 'react-hook-form';
import { getControlDefinitionByType } from '../../../Controls/Controls';
import { FormValues } from '../../../Form';
import ExtendableContent from '../../PanelWithHeader';

interface Props {
  fieldIndex: number;
}

const Formulas = ({ fieldIndex }: Props) => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext<FormValues>();

  const controlType = getValues(`controlSettings.${fieldIndex}.type`);
  const formulaOverrides = getControlDefinitionByType(controlType).formulaOverrides;

  const getErrors = () => {
    return errors?.controlSettings?.[fieldIndex]?.formulaSettings;
  };

  return (
    <ExtendableContent title="Formulas">
      <When condition={formulaOverrides?.allowCalculatedValue !== false}>
        <TextInput
          type="text"
          label="Calculated Value"
          multiline={true}
          minRows={3}
          {...register(`controlSettings.${fieldIndex}.formulaSettings.calculatedValue`)}
          helperText={getErrors()?.calculatedValue?.message ?? 'XPath Expression'}
          error={getErrors()?.calculatedValue !== undefined}
        />
      </When>
      <When condition={formulaOverrides?.allowInitialValue !== false}>
        <TextInput
          type="text"
          label="Initial Value"
          multiline={true}
          minRows={3}
          {...register(`controlSettings.${fieldIndex}.formulaSettings.initialValue`)}
          helperText={getErrors()?.initialValue?.message ?? 'XPath Expression'}
          error={getErrors()?.initialValue !== undefined}
        />
      </When>
      <When condition={formulaOverrides?.allowVisibility !== false}>
        <TextInput
          type="text"
          label="Visibility"
          multiline={true}
          minRows={3}
          {...register(`controlSettings.${fieldIndex}.formulaSettings.visibility`)}
          helperText={getErrors()?.visibility?.message ?? 'Boolean XPath Expression'}
          error={getErrors()?.visibility !== undefined}
        />
      </When>
      <When condition={formulaOverrides?.allowReadOnly !== false}>
        <TextInput
          type="text"
          label="Read-Only"
          multiline={true}
          minRows={3}
          {...register(`controlSettings.${fieldIndex}.formulaSettings.readOnly`)}
          helperText={getErrors()?.readOnly?.message ?? 'Boolean XPath Expression'}
          error={getErrors()?.readOnly !== undefined}
        />
      </When>
    </ExtendableContent>
  );
};

export default Formulas;
