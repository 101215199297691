import { z } from 'zod';
import { allControls } from './Controls';
import { validationSchema as commonSettingsSchema } from './DefaultSettings';

const schemas = allControls.map((x) =>
  z
    .object({
      id: z.string(),
      type: z.literal(x.type),
      additionalSettings:
        x.additionalSettings?.validationSchema ?? z.union([z.null(), z.undefined()]),
    })
    .extend(commonSettingsSchema.shape),
);

//DiscriminatedUnion requires a tuple
const [a, b, ...rest] = schemas;

export const validationSchema = z.discriminatedUnion('type', [a, b, ...rest]);

//For some reason if we use the below certain additional settings arent picked up e.g. multiSelect on dynamic dropdown
//export type ControlSettings = z.infer<typeof validationSchema>;
