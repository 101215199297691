import { lazyRetry } from '@airelogic/common';
import { isDocumentRtl } from '@airelogic/forms';
import createCache from '@emotion/cache';
import { PropsWithChildren, Suspense } from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import { TssCacheProvider } from 'tss-react';
import PreviewErrorBoundary from './PreviewErrorBoundary';

const SinglePass = lazyRetry(() => import('./SinglePass'));
const ClinicianFacing = lazyRetry(() => import('./ClinicianFacing'));

const tssCache = createCache({
  key: 'tss-forms',
  stylisPlugins: isDocumentRtl() ? [rtlPlugin] : [],
});

const PreviewWrapper = ({ children }: PropsWithChildren<unknown>) => {
  if (isDocumentRtl()) {
    document.dir = 'rtl';
  }

  return <TssCacheProvider value={tssCache}>{children}</TssCacheProvider>;
};

export const ClinicianPreview = () => {
  return (
    <PreviewErrorBoundary>
      <Suspense fallback={<div></div>}>
        <PreviewWrapper>
          <ClinicianFacing />
        </PreviewWrapper>
      </Suspense>
    </PreviewErrorBoundary>
  );
};

export const SinglePassPreview = () => {
  return (
    <PreviewErrorBoundary>
      <Suspense fallback={<div></div>}>
        <PreviewWrapper>
          <SinglePass />
        </PreviewWrapper>
      </Suspense>
    </PreviewErrorBoundary>
  );
};
