import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IconButton, IconButtonProps, Theme, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  arrowButton: {
    color: 'white',
    background: '#3463FD',
    marginLeft: '4px',
    marginRight: '4px',
    height: '35px',
    width: '35px',
    padding: theme.spacing(0.5),
    marginTop: 'auto',
    '&:hover': {
      background: theme.palette.secondary.dark,
    },
  },
  icon: {
    fontSize: '30px',
  },
}));

interface Props extends Omit<IconButtonProps, 'component' | 'className' | 'children'> {
  tooltipText?: string;
}

const UpIcon = (props: Props) => {
  const { classes } = useStyles();
  const { tooltipText = '', ...rest } = props;

  return (
    <Tooltip title={tooltipText}>
      <IconButton component="span" className={classes.arrowButton} {...rest}>
        <ArrowUpwardIcon className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
};

export default UpIcon;
