export const MustNotBeEmptyText = 'Must not be empty';

export const MustBePositive = 'Must be greater than 0';

export const MustNotContainWhitespace = 'Must not contain whitespace';

export const cannotStartWithNumberMessage = 'Cannot start with a number';

export const invalidResourceKeyMessage =
  'Can only include letters, numbers, hyphens and underscores';

export const invalidXmlNodeNameMessage = `Must be a valid xml node name`;
