import { IconButton } from '@airelogic/form-management/components';
import BuildIcon from '@mui/icons-material/Build';
import { Grid2 as Grid, Typography } from '@mui/material';

import { useFormContext } from 'react-hook-form';
import { FormValues } from '../Form';
import { useAppDispatch } from '../StateManagement/hooks';
import { deselectComponent } from '../StateManagement/layoutSlice';
import { useStyles } from './PropertiesBar.Styles';

const PropertiesBar = () => {
  const { classes, cx } = useStyles();
  const dispatch = useAppDispatch();

  const {
    formState: { errors },
    watch,
  } = useFormContext<FormValues>();

  const title = watch('properties.blockTitle');

  const handleSpannerClick = () => {
    dispatch(deselectComponent());
  };

  return (
    <Grid container className={classes.outerGrid}>
      <Grid size="grow" display="flex" justifyContent="space-between" className={classes.surface}>
        <Typography variant="h6">{title}</Typography>
        <IconButton
          onClick={handleSpannerClick}
          tooltipText="Edit Building Block Properties"
          className={cx({
            [classes.spannerButtonError]: !!errors?.properties,
          })}
        >
          <BuildIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default PropertiesBar;
