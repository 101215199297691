import {
  ActionMenu,
  ActionMenuItem,
  ConfirmationDialog,
  SmallMenuIcon,
} from '@airelogic/form-management/components';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { useAppDispatch } from '../../StateManagement/hooks';
import {
  addRepeatIteration,
  deleteRepeatIteration,
  moveRepeatIteration,
} from '../../StateManagement/layoutSlice';
import { RepeatingGrid } from '../../StateManagement/types';

interface MenuProps {
  iterationId: string;
  repeatingGrid: RepeatingGrid;
  canAddIteration: boolean;
  canDeleteIteration: boolean;
}

const RepeatedGridIterationOps = ({
  iterationId,
  repeatingGrid,
  canAddIteration,
  canDeleteIteration,
}: MenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemoveIteration = () => {
    if (canDeleteIteration) {
      dispatch(deleteRepeatIteration({ gridId: repeatingGrid.id, iterationId }));
      setAnchorEl(null);
    }
  };

  const handleAddIterationAbove = () => {
    addIteration('above');
    setAnchorEl(null);
  };

  const handleAddIterationBelow = () => {
    addIteration('below');
    setAnchorEl(null);
  };

  const addIteration = (direction: 'above' | 'below') => {
    if (canAddIteration) {
      dispatch(
        addRepeatIteration({
          gridId: repeatingGrid.id,
          insertPosition: { direction, relativeIterationId: iterationId },
        }),
      );
      setAnchorEl(null);
    }
  };

  const handleMoveIterationUp = () => {
    moveIteration('up');
    setAnchorEl(null);
  };

  const handleMoveIterationDown = () => {
    moveIteration('down');
    setAnchorEl(null);
  };

  const moveIteration = (direction: 'up' | 'down') => {
    dispatch(moveRepeatIteration({ iterationId, gridId: repeatingGrid.id, direction }));
  };

  const confirmDelete = () => {
    setShowConfirmDelete(false);
    handleRemoveIteration();
  };

  const hideConfirmDelete = () => {
    setShowConfirmDelete(false);
  };

  return (
    <>
      <SmallMenuIcon
        aria-label="Edit Repeated Grid"
        onClick={handleClick}
        data-testid={`iterationOpsMenu-${iterationId}`}
      />
      <ActionMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <ActionMenuItem
          onClick={handleMoveIterationUp}
          text="Move Iteration Up"
          icon={<ArrowUpwardIcon />}
        ></ActionMenuItem>
        <ActionMenuItem
          onClick={handleMoveIterationDown}
          text="Move Iteration Down"
          icon={<ArrowDownwardIcon />}
        ></ActionMenuItem>
        <ActionMenuItem
          disabled={!canAddIteration}
          onClick={handleAddIterationAbove}
          text="Add Iteration Above"
          icon={<ArrowUpwardIcon />}
        ></ActionMenuItem>
        <ActionMenuItem
          disabled={!canAddIteration}
          onClick={handleAddIterationBelow}
          text="Add Iteration Below"
          icon={<ArrowDownwardIcon />}
        ></ActionMenuItem>
        <ActionMenuItem
          disabled={!canDeleteIteration}
          onClick={() => {
            setAnchorEl(null);
            setShowConfirmDelete(true);
          }}
          text="Delete"
          icon={<DeleteIcon />}
        ></ActionMenuItem>
      </ActionMenu>
      <ConfirmationDialog
        confirmationText={'Are you sure you want to delete this iteration?'}
        open={showConfirmDelete}
        handleCancel={hideConfirmDelete}
        handleConfirm={confirmDelete}
      />
    </>
  );
};
export default RepeatedGridIterationOps;
