import { FormControl, FormControlProps, FormHelperText, FormLabel, Paper } from '@mui/material';
import imageCompression from 'browser-image-compression';
import { DeleteIcon, ImagePreview, When } from '../';
interface Props extends Pick<FormControlProps, 'required' | 'error'> {
  id?: string;
  label?: string;
  onChange: (value: string) => void;
  value: string;
  onDelete: () => void;
  helperText?: string;
}

const compressImageToDataUrl = async (file: File) => {
  const compressed = await imageCompression(file, {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
  });

  return await imageCompression.getDataUrlFromFile(compressed);
};

const ImageUpload = ({
  id = 'image-upload',
  label,
  onChange,
  value,
  onDelete,
  helperText,
  required,
  error,
}: Props) => {
  const onChangePicture = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File = (e.target.files as FileList)[0];

    if (file) {
      const compressedImage = await compressImageToDataUrl(file);
      onChange(compressedImage);
    }
  };

  return (
    <FormControl error={error} required={required}>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <When condition={value === ''}>
        <input
          id={id}
          type="file"
          accept="image/*"
          onChange={onChangePicture}
          aria-describedby={`${id}-helper-text`}
          aria-invalid={error}
        />
        <FormHelperText id={`${id}-helper-text`}>{helperText}</FormHelperText>
      </When>

      <When condition={value !== ''}>
        <Paper variant="outlined">
          <ImagePreview imageBase64={value} />
        </Paper>
        <DeleteIcon tooltipText="Delete image" onClick={onDelete} />
      </When>
    </FormControl>
  );
};

export default ImageUpload;
