import { z } from 'zod';
import { DropdownIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import {
  validationSchema as ReferenceDataSchema,
  itemsSuperRefine,
  presetInlineReferenceDataItems,
  superRefine,
} from './InlineReferenceData';
import { schema as selectDefaultsSchema } from './SelectDefaults';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = [
  'string',
  'boolean',
  'email',
  'integer',
  'decimal',
  'date',
  'time',
  'datetime',
];

export const refinedReferenceDataSchema = ReferenceDataSchema.superRefine(superRefine);

export const validationSchema = z
  .object({
    multiSelect: z.boolean(),
    items: refinedReferenceDataSchema.array().min(1).superRefine(itemsSuperRefine),
  })
  .merge(selectDefaultsSchema);

export type AdditionalSettings = z.infer<typeof validationSchema>;
export type DropdownInlineRefData = z.infer<typeof refinedReferenceDataSchema>;

export const dropdownControl: ControlDefinition<AdditionalSettings> = {
  displayName: 'Dropdown',
  icon: DropdownIcon,
  type: 'DROPDOWN',
  supportedDataTypes: dataTypes,
  additionalSettings: {
    validationSchema,
    defaults: {
      style: 'default',
      multiSelect: false,
      withOther: false,
      items: presetInlineReferenceDataItems,
      choiceFilterXPath: '',
    },
  },
  supportsInitialValue: true,
};
