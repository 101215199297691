import { nanoid, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  canDeleteSection,
  getAllSectionIds,
  getGridById,
  getGridIdsInSection,
  getSelectedCell,
  isSelectedSection,
} from '../localSelectors';
import { ISection, IState } from '../types';
import {
  addGridToSection,
  deleteGridsInSection,
  getFirstCellInGrid,
  getLastCellInGrid,
  GridTestingOptionals,
} from './gridReducers';
import {
  clearSelectedComponent,
  getNextSelectionIndex,
  updateCellSelection,
} from './selectionsReducer';

const createSection = (sectionId?: string): ISection => {
  const id = sectionId ?? nanoid();
  return {
    id: id,
  };
};

interface DeleteSection {
  sectionId: string;
}

interface MoveSection {
  sectionId: string;
  direction: 'up' | 'down';
}

interface AddSection extends GridTestingOptionals {
  sectionId?: string;
}

export const sectionReducers = {
  addSection: (state: IState, action: PayloadAction<AddSection>) => {
    state.layoutSelections.selectedComponent = undefined;
    const selectedCell = getSelectedCell(state);
    const selectedSection = selectedCell
      ? getGridById(state, selectedCell.gridId).sectionId
      : undefined;

    const section = createSection(action.payload.sectionId);

    if (selectedSection) {
      state.sections.allIds.splice(
        state.sections.allIds.indexOf(selectedSection) + 1, //insert after
        0,
        section.id,
      );
    } else {
      state.sections.allIds.push(section.id);
    }
    addGridToSection(state, { sectionId: section.id, repeat: false, ...action?.payload });
  },
  deleteSection: (state: IState, action: PayloadAction<DeleteSection>) => {
    if (canDeleteSection(state)) {
      const selectedCell = getSelectedCell(state);
      if (
        selectedCell &&
        getGridById(state, selectedCell.gridId).sectionId === action.payload.sectionId
      ) {
        const sectionIds = getAllSectionIds(state);
        const selectionIndexes = getNextSelectionIndex(sectionIds, action.payload.sectionId);
        const nextSelectionSectionId = sectionIds[selectionIndexes.nextSelectionIndex];
        const gridIds = getGridIdsInSection(state, nextSelectionSectionId);
        const cell =
          selectionIndexes.nextSelectionIndex < selectionIndexes.currentIndex
            ? getLastCellInGrid(getGridById(state, _.last(gridIds)!))
            : getFirstCellInGrid(getGridById(state, _.first(gridIds)!));

        updateCellSelection(state, cell);
      }

      if (isSelectedSection(state, action.payload.sectionId)) {
        clearSelectedComponent(state);
      }

      state.sections.allIds = state.sections.allIds.filter((s) => s !== action.payload.sectionId);
      deleteGridsInSection(state, action.payload.sectionId);
    }
  },
  moveSection: (state: IState, action: PayloadAction<MoveSection>) => {
    switch (action.payload.direction) {
      case 'down':
        moveSectionDown(state, action.payload.sectionId);
        break;
      case 'up':
        moveSectionUp(state, action.payload.sectionId);
    }
  },
};

const moveSectionDown = (state: IState, sectionId: string) => {
  const currentSectionIndex = state.sections.allIds.indexOf(sectionId);
  const numberOfSections = state.sections.allIds.length;
  if (numberOfSections < 1 || currentSectionIndex === numberOfSections - 1) {
    return;
  }
  const nextSectionId = state.sections.allIds.splice(currentSectionIndex, 1)[0];
  state.sections.allIds.splice(currentSectionIndex + 1, 0, nextSectionId);
};

const moveSectionUp = (state: IState, sectionId: string) => {
  const currentSectionIndex = state.sections.allIds.indexOf(sectionId);
  const numberOfSections = state.sections.allIds.length;
  if (numberOfSections <= 1 || currentSectionIndex === 0) {
    return;
  }
  const previousSectionId = state.sections.allIds.splice(currentSectionIndex, 1)[0];
  state.sections.allIds.splice(currentSectionIndex - 1, 0, previousSectionId);
};
