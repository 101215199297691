import { ActionMenu, ActionMenuItem, SmallMenuIcon } from '@airelogic/form-management/components';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';

interface MenuProps {
  choiceIndex: number;
  removeChoice(): void;
  moveChoiceUp(): void;
  moveChoiceDown(): void;
  insertAbove(): void;
  insertBelow(): void;
  isLastRow: boolean;
  disableDelete?: boolean;
}

const ChoiceOptions = ({
  choiceIndex,
  removeChoice,
  moveChoiceUp,
  moveChoiceDown,
  insertAbove,
  insertBelow,
  isLastRow,
  disableDelete,
}: MenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddChoiceAbove = () => {
    insertAbove();
    setAnchorEl(null);
  };

  const handleAddChoiceBelow = () => {
    insertBelow();
    setAnchorEl(null);
  };

  const handleMoveChoiceUp = () => {
    moveChoiceUp();
    setAnchorEl(null);
  };

  const handleMoveChoiceDown = () => {
    moveChoiceDown();
    setAnchorEl(null);
  };

  return (
    <>
      <SmallMenuIcon
        aria-label="Choice options"
        onClick={handleClick}
        data-testid={`choiceOps-${choiceIndex}`}
      />
      <ActionMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        aria-label="Choice options menu"
      >
        <ActionMenuItem
          onClick={handleAddChoiceAbove}
          text="Insert above"
          icon={<ArrowUpwardIcon />}
        ></ActionMenuItem>
        <ActionMenuItem
          onClick={handleAddChoiceBelow}
          text="Insert below"
          icon={<ArrowDownwardIcon />}
        ></ActionMenuItem>
        <ActionMenuItem
          disabled={choiceIndex === 0}
          onClick={handleMoveChoiceUp}
          text="Move up"
          icon={<ArrowUpwardIcon />}
        ></ActionMenuItem>
        <ActionMenuItem
          disabled={isLastRow}
          onClick={handleMoveChoiceDown}
          text="Move down"
          icon={<ArrowDownwardIcon />}
        ></ActionMenuItem>
        <ActionMenuItem
          disabled={disableDelete}
          onClick={removeChoice}
          text="Delete"
          icon={<DeleteIcon />}
        ></ActionMenuItem>
      </ActionMenu>
    </>
  );
};

export default ChoiceOptions;
