import { RichText, SelectInput, When } from '@airelogic/form-management/components';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';

import { AdditionalSettings } from '../../../Controls/YesNo';

interface Props {
  fieldIndex: number;
}

const stopConditionOptions = [
  {
    value: '',
    label: 'No Stop Condition',
  },
  {
    value: 'true',
    label: 'Stop if Yes Is Selected',
  },
  {
    value: 'false',
    label: 'Stop if No Is Selected',
  },
];

const pdfHighlightOptions = [
  {
    value: '',
    label: 'No PDF Highlight',
  },
  {
    value: 'true',
    label: 'Highlight if Yes Is Selected',
  },
  {
    value: 'false',
    label: 'Highlight if No Is Selected',
  },
];

const YesNoField = ({ fieldIndex }: Props) => {
  const {
    register,
    watch,
    control,
    formState: { errors },
  } = useFormContext<FormValues>();

  const getErrors = () => {
    return errors?.controlSettings?.[fieldIndex]
      ?.additionalSettings as FieldErrors<AdditionalSettings>;
  };

  const stopCondition = watch(`controlSettings.${fieldIndex}.additionalSettings.stopCondition`);

  return (
    <PanelWithHeader title="Additional Settings">
      <SelectInput
        id="stopCondition"
        label="Stop Condition"
        required
        {...register(`controlSettings.${fieldIndex}.additionalSettings.stopCondition`)}
      >
        {stopConditionOptions.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </SelectInput>
      <When condition={stopCondition !== ''}>
        <Controller
          control={control}
          name={`controlSettings.${fieldIndex}.additionalSettings.stopConditionText`}
          render={({ field: { onChange, value } }) => (
            <RichText
              id="stop-condition-text"
              required
              label="Stop condition text"
              defaultValue={value}
              error={!!getErrors()?.stopConditionText}
              helperText={getErrors()?.stopConditionText?.message}
              onChange={onChange}
            />
          )}
        />
      </When>
      <SelectInput
        id="pdfHighlight"
        label="PDF Highlight"
        required
        {...register(`controlSettings.${fieldIndex}.additionalSettings.pdfHighlight`)}
      >
        {pdfHighlightOptions.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </SelectInput>
    </PanelWithHeader>
  );
};

export default YesNoField;
