import { ImageSelectInlineReferenceData } from '../../../../Controls/ImageSelect';
import { InlineReferenceData } from '../../../../Controls/InlineReferenceData';
import { RichTextSelectInlineReferenceData } from '../../../../Controls/RichTextSelect';

export const DropdownProperties = [
  'Label',
  'Value',
  'Is Stop Condition',
  'Filter groups',
  'Option is exclusive',
  'Deprecated',
  'Highlight answer in pdf',
];

export const DropdownMultiselectProperties = [
  'Label',
  'Value',
  'Is Stop Condition',
  'Filter groups',
  'Option is exclusive',
  'Deprecated',
];

export const RadioButtonsMultiSelectProperties = [
  'Label',
  'Value',
  'Is Stop Condition',
  'Filter groups',
  'Option is exclusive',
  'Deprecated',
];

export const RadioButtonsProperties = [
  'Label',
  'Value',
  'Is Stop Condition',
  'Filter groups',
  'Option is exclusive',
  'Deprecated',
  'Highlight answer in pdf',
];

export const CheckboxProperties = [
  'Label',
  'Value',
  'Is Stop Condition',
  'Filter groups',
  'Option is exclusive',
  'Deprecated',
];

export const ImageSelectProperties = [
  'Label',
  'Value',
  'Image',
  'Hint',
  'Is Stop Condition',
  'Filter groups',
  'Option is exclusive',
  'Deprecated',
];

export const RichTextSelectProperties = [
  'Label',
  'Value',
  'Description',
  'Is Stop Condition',
  'Filter groups',
  'Option is exclusive',
  'Deprecated',
  'Highlight answer in pdf',
];

export const blankChoice: InlineReferenceData = {
  label: '',
  value: '',
  stopCondition: false,
  stopConditionText: '',
  filterGroups: '',
  optionIsExclusive: false,
  deprecated: false,
  highlightAnswerInPDF: false,
};

export const blankSelectImageChoice: ImageSelectInlineReferenceData = {
  label: '',
  value: '',
  stopCondition: false,
  stopConditionText: '',
  filterGroups: '',
  optionIsExclusive: false,
  deprecated: false,
  highlightAnswerInPDF: false,
  imageBase64: '',
  hint: '',
};

export const blankRichTextSelectChoice: RichTextSelectInlineReferenceData = {
  label: '',
  value: '',
  stopCondition: false,
  stopConditionText: '',
  filterGroups: '',
  optionIsExclusive: false,
  deprecated: false,
  highlightAnswerInPDF: false,
  description: '',
};
