import { Divider, List, ListItem, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '../../../Form';

interface Props {
  settingsIndex: number;
}

const BasicSelectControl = ({ settingsIndex }: Props) => {
  const { watch } = useFormContext<FormValues>();
  const items = watch(`controlSettings.${settingsIndex}.additionalSettings.items`);

  return (
    <List component="ol" disablePadding>
      {items?.map((item, index) => {
        //Use index as value could be empty when editing causing conflicting keys
        return (
          <Fragment key={index}>
            <ListItem disableGutters disablePadding>
              <Typography variant="caption">{item.label}</Typography>
            </ListItem>
            <Divider />
          </Fragment>
        );
      })}
    </List>
  );
};

export default BasicSelectControl;
