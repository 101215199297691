import { AuthProvider } from '@airelogic/form-management/common';
import {
  ApplicationContextProvider,
  UserAbilityProvider,
} from '@airelogic/form-management/common/app-context';
import { createBrowserHistory } from 'history';
import { observer } from 'mobx-react';
import { LiveAnnouncer } from 'react-aria-live';
import { Router } from 'react-router';
import './App.css';
import Routing from './Routing';

export const history = createBrowserHistory();

const App = observer(() => {
  return (
    <Router history={history}>
      <ApplicationContextProvider>
        <AuthProvider>
          <UserAbilityProvider>
            <LiveAnnouncer>
              <Routing />
            </LiveAnnouncer>
          </UserAbilityProvider>
        </AuthProvider>
      </ApplicationContextProvider>
    </Router>
  );
});

export default App;
