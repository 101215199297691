import { Location } from 'history';
import { PropsWithChildren, useCallback, useEffect, useReducer } from 'react';
import { Prompt, useHistory } from 'react-router';
import { ConfirmationDialog } from '../';

interface Reset {
  type: 'reset';
}

interface Display {
  type: 'display';
  payload: {
    location: string;
  };
}

interface Confirm {
  type: 'confirm';
}

interface State {
  showConfirmationDialog: boolean;
  navigateToLocation: string;
  canNavigateAway: boolean;
}

const initialState: State = {
  showConfirmationDialog: false,
  navigateToLocation: '',
  canNavigateAway: false,
};

const reducer = (state: State, action: Reset | Display | Confirm): State => {
  switch (action.type) {
    case 'reset':
      return initialState;
    case 'display':
      return {
        showConfirmationDialog: true,
        canNavigateAway: false,
        navigateToLocation: action.payload.location,
      };
    case 'confirm':
      return {
        showConfirmationDialog: false,
        canNavigateAway: true,
        navigateToLocation: state.navigateToLocation,
      };
    default:
      return state;
  }
};

interface Props {
  when: boolean;
  confirmationText: string;
  negativeChoice: string;
}

const NavigatePrompt = ({
  children,
  when,
  confirmationText,
  negativeChoice,
}: PropsWithChildren<Props>) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.canNavigateAway) {
      history.push(state.navigateToLocation);
    }
  }, [history, state.canNavigateAway, state.navigateToLocation]);

  const onConfirmClose = useCallback(() => {
    dispatch({ type: 'confirm' });
  }, []);

  const onCancelClose = useCallback(() => {
    dispatch({ type: 'reset' });
  }, []);

  const onTryNavigateAway = (location: Location) => {
    if (location.pathname === '/error' || state.canNavigateAway) {
      return true;
    }
    dispatch({
      type: 'display',
      payload: {
        location: location.pathname,
      },
    });
    return false;
  };

  return (
    <>
      {children}
      <Prompt when={when} message={onTryNavigateAway} />
      <ConfirmationDialog
        confirmationText={confirmationText}
        negativeChoice={negativeChoice}
        open={state.showConfirmationDialog}
        handleCancel={onCancelClose}
        handleConfirm={onConfirmClose}
      />
    </>
  );
};

export default NavigatePrompt;
