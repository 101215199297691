import { IConstraintOption } from '../../../../Controls/types';

export const StringOps: IConstraintOption[] = [
  {
    label: 'Formula',
    kind: 'custom',
    type: '',
    fieldType: 'text',
  },
  {
    label: 'Maximum Length',
    kind: 'limit',
    type: 'max-length',
    fieldType: 'number',
  },
  {
    label: 'Minimum Length',
    kind: 'limit',
    type: 'min-length',
    fieldType: 'number',
  },
];
