export interface LargeDisplayValue {
  value: string;
  type: 'design payload' | 'base64';
}

export const tryGetLargeDisplayValue = (value: string | null): LargeDisplayValue | undefined => {
  if (value) {
    if (isDesignPayload(value)) return { value: value, type: 'design payload' };
    if (isBase64Image(value)) return { value: value, type: 'base64' };
  }
  return undefined;
};

export const isDesignPayload = (val: string) => {
  if (val !== undefined) {
    try {
      const jsonPayload = JSON.parse(val);
      if (Object.keys(jsonPayload)[0] === 'imgSrc') {
        return true;
      }
    } catch {
      // Empty
    }
  }
  return false;
};

export const isBase64Image = (val: string) => {
  const imageRegex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  return (
    val !== undefined &&
    typeof val === 'string' &&
    (imageRegex.test(val) || val.startsWith('data:image')) &&
    val.length > 300
  );
};
