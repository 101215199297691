import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  rowUp: {
    transform: 'rotate(90deg)',
  },

  rowDown: {
    transform: 'rotate(90deg)',
  },
}));
