import { Box, Theme, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

interface Props {
  title: string;
}

export const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    backgroundColor: '#EDF0F2',
    width: '100%',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(1),
  },
}));

const PanelWithHeader = ({ title, children }: PropsWithChildren<Props>) => {
  const { classes } = useStyles();

  return (
    <>
      <Box sx={{ display: 'flex' }} className={classes.header}>
        <Typography variant="subtitle1" variantMapping={{ subtitle1: 'h6' }}>
          {title}
        </Typography>
      </Box>
      <div className={classes.container}>{children}</div>
    </>
  );
};

export default PanelWithHeader;
