import { ButtonProps, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type Color = ButtonProps['color'];

//Annoyingly as we also have a bootstrap dependency we also get _reboot which messes with some of the styles
const getColor = (theme: Theme, propColor?: Color) => {
  switch (propColor) {
    case 'inherit':
      return 'inherit';
    case 'primary':
      return theme.palette.primary.contrastText;
    case 'secondary':
      return theme.palette.secondary.contrastText;
    default:
      return 'default';
  }
};

interface StylesPropType {
  color?: Color;
}

export const useStyles = makeStyles<StylesPropType>()((theme: Theme, { color }) => {
  return {
    link: {
      '&:hover': {
        color: getColor(theme, color),
      },
    },
    button: {
      color: '#3463FD',
      borderColor: '#3463FD',
      background: 'white',
      border: '1.5px solid',
      fontWeight: 'bold',
      textTransform: 'none',
      '&:hover': {
        color: '#FFFFFF',
        background: '#3463FD',
      },
    },
  };
});
