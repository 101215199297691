import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ExtractedBuildingBlock } from './StateManagement/types';

const useGetRehydrateData = (orbeonId: string) => {
  return useQuery({
    queryKey: ['buildingblock-definition', orbeonId],
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<ExtractedBuildingBlock>(
        `/internalapi/formbuilding/buildingblocks/rehydrate/${orbeonId}`,
        {
          signal,
        },
      );
      return data;
    },
  });
};

export default useGetRehydrateData;
