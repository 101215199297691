import { DataType } from '../../../Controls/DefaultSettings';
import { DateOps } from './ConstraintOptions/DateOps';
import { DecimalOps } from './ConstraintOptions/DecimalOps';
import { FormulaOps } from './ConstraintOptions/FormulaOps';
import { IntegerOps } from './ConstraintOptions/IntegerOps';
import { StringOps } from './ConstraintOptions/StringOps';

const ConstraintOptionsResolver = (datatype: DataType) => {
  switch (datatype) {
    case 'decimal':
      return DecimalOps;
    case 'integer':
      return IntegerOps;
    case 'date':
      return DateOps;
    case 'time':
      return FormulaOps;
    case 'boolean':
      return FormulaOps;
    case 'email':
    case 'string':
      return StringOps;
    default:
      return [];
  }
};

export default ConstraintOptionsResolver;
