import { Typography } from '@mui/material';

interface Props {
  displayName: string;
  environmentKey: string;
}

const convertEnvironmentKey = (environmentKey: string) => {
  switch (environmentKey) {
    case 'sandbox':
      return '(Sandbox)';
    case 'dev':
      return '(Development)';
    case 'stag':
      return '(Staging)';
    case 'prod':
      return '';

    default:
      return `(${environmentKey})`;
  }
};

const TenantDisplayName = ({ displayName, environmentKey }: Props) => {
  return (
    <Typography>
      {displayName} {convertEnvironmentKey(environmentKey)}
    </Typography>
  );
};

export default TenantDisplayName;
