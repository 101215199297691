import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  formInput: {
    padding: theme.spacing(2),
  },

  dialog: {
    margin: theme.spacing(0),
  },

  dialogPaper: {
    padding: theme.spacing(1),
  },

  scrollingPaper: {
    margin: theme.spacing(1),
    background: '#EDF0F2',
    borderColor: '#EDF0F2',
    maxHeight: 400,
    overflow: 'auto',
    padding: theme.spacing(2),
    minWidth: '400px',
  },

  paper: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },

  deleteIcon: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },

  list: {
    listStyleType: 'none',
    listStyle: 'none',
    marginLeft: 0,
    paddingLeft: 0,
  },
}));
