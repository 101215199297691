import { Route, RouteProps } from 'react-router-dom';
import { Can } from '../AppContext';

import { BoundCanProps } from '@casl/react';
import { Except } from 'type-fest';
import { useAuth } from '../Authentication';
import { FormManagementAbility } from '../Authorisation/AbilityBuilder';
import AccessDenied from './AccessDenied';

type Props = Pick<RouteProps, 'exact' | 'path'> & {
  can: Except<BoundCanProps<FormManagementAbility>, 'children', { requireExactProps: true }>;
  children?: React.ReactNode;
};

export const AuthorisedRoute = (props: Props) => {
  const { exact, path, children } = props;
  const { not, ...can } = props.can;

  const { user } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <Route path={path} exact={exact}>
      <Can {...can}>{children}</Can>
      <Can not {...can}>
        <AccessDenied />
      </Can>
    </Route>
  );
};
