import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { PropsWithChildren, createContext, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useApplicationContext } from '../AppContext';
import { clearLocalStorage } from '../LocalStorage';
import { AuthenticatedUser } from './AuthenticatedUser';
import { resolveLandingPage } from './LandingPageResolver';

export interface IAuthContext {
  user?: AuthenticatedUser;
  logout: () => Promise<void>;
}

export const AuthContext = createContext<IAuthContext>({
  logout: () => Promise.resolve(),
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

const use401Interceptor = () => {
  const { authMode } = useApplicationContext();
  const history = useHistory();

  useEffect(() => {
    const redirectToLogin = (returnUrl = '/') => {
      if (returnUrl === '/auth/login') {
        returnUrl = '/';
      }

      if (authMode === 'oidc') {
        history.push(`/auth/login?returnUrl=${returnUrl}`);
      }
    };

    const unAuthInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          clearLocalStorage();
          redirectToLogin(history.location.pathname);
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.response.eject(unAuthInterceptor);
    };
  }, [authMode, history]);
};

export const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { authMode } = useApplicationContext();
  const history = useHistory();
  use401Interceptor(); // This needs to be above useQuery

  const { data: user } = useQuery({
    queryKey: ['authenticated-user'],
    queryFn: async ({ signal }) => {
      const response = await axios.get<AuthenticatedUser>('/internalapi/authenticateduser', {
        signal,
      });
      return response.data;
    },
  });

  useEffect(() => {
    if (user) {
      const returnUrl = new URLSearchParams(history.location.search).get('returnUrl');

      if (returnUrl) {
        history.push(returnUrl);
      }

      if (history.location.pathname === '/') {
        history.push(resolveLandingPage(user.activities));
      }
    }
  }, [history, user]);

  const logout = async () => {
    clearLocalStorage();
    switch (authMode) {
      case 'oidc':
        window.location.assign(`/oidc/logout`);
        break;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
