import {
  MustNotBeEmptyText,
  MustNotContainWhitespace,
} from '@airelogic/form-management/common/validations';
import _ from 'lodash';
import { z } from 'zod';

export const validationSchema = z.object({
  label: z.string().min(1, { message: MustNotBeEmptyText }),
  value: z.string().min(1, { message: MustNotBeEmptyText }),
  stopCondition: z.boolean(),
  stopConditionText: z.string(),
  filterGroups: z.string(),
  optionIsExclusive: z.boolean(),
  deprecated: z.boolean(),
  highlightAnswerInPDF: z.boolean(),
});

export const superRefine = (val: InlineReferenceData, ctx: z.RefinementCtx) => {
  if (val.stopCondition) {
    if (['', '<p></p>', '<p><br></p>'].indexOf(val.stopConditionText) !== -1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: MustNotBeEmptyText,
        path: [`stopConditionText`],
      });
    }
  }

  if (val.label.length > 0) {
    if (!val.label.replace(/\s/g, '').length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: MustNotBeEmptyText,
        path: [`label`],
      });
    }
  }

  if (val.value.length > 0) {
    if (/\s/.test(val.value)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: MustNotContainWhitespace,
        path: [`value`],
      });
    }
  }
};

export const itemsSuperRefine = (items: InlineReferenceData[], ctx: z.RefinementCtx) => {
  const elements = items.map((element, index) => ({
    value: element.value,
    path: `${index}.value`,
  }));

  const duplicates = _(elements)
    .groupBy((x) => x.value)
    .pickBy((x) => x.length > 1)
    .values()
    .flatten();

  if (duplicates.size() > 0) {
    duplicates.forEach((dupe) => {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: [dupe.path],
        message: 'Value already exists',
      });
    });
  }
};

//Extend object in Richtext select, image select, etc
export type InlineReferenceData = z.infer<typeof validationSchema>;

export const presetInlineReferenceDataItems: InlineReferenceData[] = [
  {
    label: 'First choice',
    value: 'first-choice',
    stopCondition: false,
    stopConditionText: '',
    filterGroups: '',
    optionIsExclusive: false,
    deprecated: false,
    highlightAnswerInPDF: false,
  },
  {
    label: 'Second choice',
    value: 'second-choice',
    stopCondition: false,
    stopConditionText: '',
    filterGroups: '',
    optionIsExclusive: false,
    deprecated: false,
    highlightAnswerInPDF: false,
  },
  {
    label: 'Third choice',
    value: 'third-choice',
    stopCondition: false,
    stopConditionText: '',
    filterGroups: '',
    optionIsExclusive: false,
    deprecated: false,
    highlightAnswerInPDF: false,
  },
];
