import { When } from '@airelogic/form-management/components';
import { Box, Grid2 as Grid } from '@mui/material';

import { useState } from 'react';
import { RepeatingGrid } from '../../StateManagement/types';
import { useStyles } from './RepeatedGrid.styles';
import RepeatedGridIterationOps from './RepeatedGridIterationOps';
import Row from './Row';
import RowOptions from './RowOptions';

interface Props {
  repeatingGrid: RepeatingGrid;
  iterationId: string;
  rowIndex: number;
  rowId: string;
  canAddIteration: boolean;
  canDeleteIteration: boolean;
}

const RepeatIterationRow = ({
  repeatingGrid,
  iterationId,
  rowId,
  rowIndex,
  canAddIteration,
  canDeleteIteration,
}: Props) => {
  const { classes, cx } = useStyles();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Grid container onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <RowOptions gridId={repeatingGrid.id} rowId={rowId} display={isHovered} />
      <Box
        className={cx(classes.borderLeft, {
          [classes.borderBottom]: rowIndex === repeatingGrid.rowIds.length - 1,
        })}
      >
        <When condition={rowIndex === 0}>
          <RepeatedGridIterationOps
            iterationId={iterationId}
            repeatingGrid={repeatingGrid}
            canAddIteration={canAddIteration}
            canDeleteIteration={canDeleteIteration}
          />
        </When>
        <When condition={rowIndex !== 0}>
          <Box width={'24px'}>{/* Leaving space for repeat controls */}</Box>
        </When>
      </Box>
      <Box flex={1}>
        <Grid container>
          <Row gridId={repeatingGrid.id} rowId={rowId} repeatedIterationId={iterationId} />
        </Grid>
      </Box>
    </Grid>
  );
};

export default RepeatIterationRow;
