import { EditIcon, NewVersionIcon, When } from '@airelogic/form-management/components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ExistingReferenceData } from './Types';

const Actions = ({ referenceData }: { referenceData: ExistingReferenceData }) => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleEditClick = () => {
    history.push(`${path}/edit/${referenceData.key}/${referenceData.version}`);
  };

  const handleNewVersionClick = () => {
    history.push(`${path}/new/${referenceData.key}`);
  };

  return (
    <>
      <EditIcon
        aria-label={`edit-${referenceData.key}`}
        tooltipText={`Edit version ${referenceData.version}`}
        onClick={handleEditClick}
      ></EditIcon>
      <When condition={referenceData.isLatest}>
        <NewVersionIcon
          aria-label={`new-version-of-${referenceData.key}`}
          tooltipText={'Create new version'}
          onClick={handleNewVersionClick}
        ></NewVersionIcon>
      </When>
    </>
  );
};

export default Actions;
