import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    border: `2px solid #ccc`,
  },

  rootActive: {
    borderColor: `${theme.palette.primary.main}`,
  },

  sectionHeader: {
    backgroundColor: '#DBE0E4',
    padding: theme.spacing(1),
  },

  body: {
    backgroundColor: '#FFF',
    height: 'auto',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  editTitleField: {
    backgroundColor: '#DBE0E4',
    color: '#32444F',
    width: '100%',
  },

  sectionHeaderText: {
    cursor: 'pointer',
  },

  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
