import { Toast } from '@airelogic/form-management/components';
import { Button, Theme } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { usePostUpload } from './Service';

const useStyles = makeStyles()((theme: Theme) => ({
  uploadButton: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const UploadButton = () => {
  const { classes } = useStyles();

  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);

  const onBarClose = () => {
    setUploadFailed(false);
    setUploadSuccess(false);
  };

  const { mutate: upload } = usePostUpload();

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files === null) {
      return;
    }

    const file = files[0];
    if (file) {
      if (file.type !== 'text/xml') {
        return;
      }

      const reader = new FileReader();

      reader.onload = async () => {
        const xml = reader.result?.toString();
        if (xml === undefined) {
          return;
        }

        upload(
          { xml },
          {
            onSuccess: () => {
              setUploadSuccess(true);
            },
            onError: () => {
              setUploadFailed(true);
            },
          },
        );
      };

      reader.readAsText(file);
    }
  };

  return (
    <>
      <input
        id={'upload-new'}
        type="file"
        accept=".xml"
        onChange={onFileChange}
        aria-describedby={`Upload-new-helper-text`}
        style={{ display: 'none' }}
      />
      <label htmlFor={'upload-new'}>
        <Button
          variant="contained"
          color="primary"
          className={classes.uploadButton}
          component="span"
        >
          Upload
        </Button>
      </label>
      <Toast severity="success" open={uploadSuccess} onClose={onBarClose}>
        Upload success
      </Toast>
      <Toast severity="error" open={uploadFailed} onClose={onBarClose}>
        Upload failed
      </Toast>
    </>
  );
};

export default UploadButton;
