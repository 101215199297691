import { IconButton } from '@airelogic/form-management/components';
import { Grid2 as Grid, SvgIcon, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CopyIcon, CutIcon, PasteIcon } from '../Assets/index';
import { useClipboard } from './Clipboard.Hook';

const useStyles = makeStyles()((theme: Theme) => ({
  clipboardContainer: {
    padding: theme.spacing(1),
    backgroundColor: '#EDF0F2',
    width: '100%',
  },

  clipboardButtonDisabled: {
    filter: 'grayscale(100%)',
  },
}));

const Clipboard = () => {
  const { classes, cx } = useStyles();
  const { canPaste, canCutCopy, updateReduxClipboard, handlePaste } = useClipboard();

  return (
    <Grid container className={classes.clipboardContainer}>
      <Grid display="flex" justifyContent="center" size={4}>
        <IconButton
          tooltipText="Cut"
          className={cx({ [classes.clipboardButtonDisabled]: !canCutCopy })}
          onClick={() => updateReduxClipboard('cut')}
          disabled={!canCutCopy}
        >
          <SvgIcon component={CutIcon} />
        </IconButton>
      </Grid>
      <Grid display="flex" justifyContent="center" size={4}>
        <IconButton
          tooltipText="Copy"
          className={cx({ [classes.clipboardButtonDisabled]: !canCutCopy })}
          onClick={() => updateReduxClipboard('copy')}
          disabled={!canCutCopy}
        >
          <SvgIcon component={CopyIcon} />
        </IconButton>
      </Grid>
      <Grid display="flex" justifyContent="center" size={4}>
        <IconButton
          tooltipText="Paste"
          className={cx({ [classes.clipboardButtonDisabled]: !canPaste })}
          onClick={handlePaste}
          disabled={!canPaste}
        >
          <SvgIcon component={PasteIcon} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Clipboard;
