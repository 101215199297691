import { FormLayout, createPreviewUrl } from '@airelogic/form-management/common';
import { SelectInput, StyledButton, Toast } from '@airelogic/form-management/components';
import { Theme } from '@mui/material';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { ValidationFailed } from '../ErrorMessages';
import { FormValues } from '../Form';
import { store } from '../StateManagement/store';
import { useBlockPreviewService } from './service';

const options: Option[] = [
  {
    value: 'wizard',
    label: 'Single Pass',
  },
  {
    value: 'document',
    label: 'Clinician',
  },
];

interface Option {
  value: string;
  label: string;
}

interface Props {
  isSubmitting: boolean;
}

const BlockPreviewOptions = ({ isSubmitting }: Props) => {
  const { classes } = useStyles();
  const { trigger, getValues } = useFormContext<FormValues>();
  const previewService = useBlockPreviewService();

  const [previewType, setPreviewType] = useState<FormLayout>('wizard');
  const [failedBar, setFailedBar] = useState<boolean>(false);
  const [successBarOpen, setSuccessBarOpen] = useState(false);

  const onPreview = useCallback(async () => {
    const valid = await trigger();
    if (valid) {
      setSuccessBarOpen(true);
      await previewService({ state: store.getState().layout, settings: getValues() });
      const previewUrl = createPreviewUrl(previewType, 'preview-form', 1);
      window.open(previewUrl, '_blank');
    } else {
      setFailedBar(true);
    }
  }, [previewService, getValues, previewType, trigger]);

  return (
    <>
      <SelectInput
        label="Preview as"
        name="Preview building block"
        className={classes.previewType}
        disabled={isSubmitting}
        defaultValue={previewType}
        onChange={(e) => setPreviewType(e.target.value as FormLayout)}
      >
        {options.map((option: Option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </SelectInput>
      <StyledButton type="button" disabled={isSubmitting} onClick={onPreview}>
        Preview
      </StyledButton>
      <Toast severity="success" open={successBarOpen} onClose={() => setSuccessBarOpen(false)}>
        Preview will open shortly in a new tab
      </Toast>
      <Toast severity="error" open={failedBar} onClose={() => setFailedBar(false)}>
        {ValidationFailed}
      </Toast>
    </>
  );
};
export default BlockPreviewOptions;

const useStyles = makeStyles()((theme: Theme) => ({
  previewType: {
    marginRight: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: theme.palette.background.paper,
  },
}));
