import { MustNotBeEmptyText } from '@airelogic/form-management/common/validations';
import { z } from 'zod';
import { ScoreIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = ['decimal'];

export const ValueLessThanPrevious = 'Value must be less than the previous.';

export const validationSchema = z
  .object({
    graded: z.boolean(),
    grades: z.number().or(z.nan()).array(),
    includeNeutral: z.boolean(),
    reverseOrder: z.boolean(),
  })
  .superRefine((val, ctx) => {
    if (val.graded) {
      val.grades.forEach((element: number, index: number) => {
        if (isNaN(element)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['grades.' + index],
            message: MustNotBeEmptyText,
          });
        }
      });

      if (val.grades[1] >= val.grades[0]) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['grades.1'],
          message: ValueLessThanPrevious,
        });
      }

      if (val.includeNeutral) {
        if (val.grades[2] >= val.grades[1]) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['grades.2'],
            message: ValueLessThanPrevious,
          });
        }
      }
    }
  });

export type AdditionalSettings = z.infer<typeof validationSchema>;

export const scoreControl: ControlDefinition<AdditionalSettings> = {
  displayName: 'Score',
  icon: ScoreIcon,
  type: 'SCORE_FIELD',
  supportedDataTypes: dataTypes,
  additionalSettings: {
    validationSchema,
    defaults: {
      graded: false,
      grades: [],
      includeNeutral: false,
      reverseOrder: false,
    },
  },
  supportsInitialValue: false,
};
