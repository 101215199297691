import {
  ResourceKeyRegex,
  generateResourceKey,
} from '@airelogic/form-management/common/validations';
import { RichText, SingleCheckbox, TextInput, When } from '@airelogic/form-management/components';
import { TableCell, TableRow } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import ChoiceOptions from '../ChoiceOptions';
import { RefDataFormValues, ReferenceDataItem } from '../Types';

const blankRefData: ReferenceDataItem = {
  displayName: '',
  code: '',
  stopCondition: false,
  stopConditionText: '',
  optionIsExclusive: false,
  deprecated: false,
  filterGroups: '',
  isNew: true,
};

interface Props {
  index: number;
  itemsLength: number;
  onRemove(index: number): void;
  onAdd(): void;
  onMove(fromIndex: number, toIndex: number): void;
  onInsert(index: number, object: ReferenceDataItem): void;
}

const ReferenceDataPageRow = ({ index, itemsLength, onRemove, onMove, onInsert }: Props) => {
  const { classes, cx } = useStyles();

  const {
    getValues,
    setValue,
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext<RefDataFormValues>();

  const removeChoice = () => {
    onRemove(index);
  };

  const moveChoiceUp = () => {
    onMove(index, index - 1);
  };

  const moveChoiceDown = () => {
    onMove(index, index + 1);
  };

  const insertAbove = () => {
    onInsert(index, blankRefData);
  };

  const insertBelow = () => {
    onInsert(index + 1, blankRefData);
  };

  const checkAndUpdateCode = (value: string, itemIndex: number) => {
    const oldValue = getValues(`referenceData.${itemIndex}.code`);
    if (oldValue === '') {
      setValue(`referenceData.${itemIndex}.code`, generateResourceKey(value));
    }
  };

  const getError = (key: keyof ReferenceDataItem) => {
    return errors?.referenceData?.[index]?.[key];
  };

  const isNew = watch(`referenceData.${index}.isNew`);
  const stopConditionWatch = watch(`referenceData.${index}.stopCondition`);

  useEffect(() => {
    if (!stopConditionWatch) {
      setValue(`referenceData.${index}.stopConditionText`, '');
    }
  }, [stopConditionWatch, setValue, index]);

  return (
    <TableRow>
      <TableCell align="left" className={classes.borderRight}>
        <ChoiceOptions
          choiceIndex={index}
          removeChoice={removeChoice}
          moveChoiceUp={moveChoiceUp}
          moveChoiceDown={moveChoiceDown}
          insertAbove={insertAbove}
          insertBelow={insertBelow}
          isLastRow={index === itemsLength - 1}
          disableDelete={!isNew || (index === 0 && itemsLength === 1)}
        />
      </TableCell>
      <TableCell align="left" className={classes.borderRight}>
        <TextInput
          label="Label"
          id={`referenceData.${index}.displayName`}
          error={getError('displayName') !== undefined}
          helperText={getError('displayName')?.message}
          {...register(`referenceData.${index}.displayName`, {
            required: { value: true, message: 'Must not be empty' },
            pattern: { value: /.*\S.*/, message: 'Must not be empty' },
            onBlur: (e) => checkAndUpdateCode(e.target.value, index),
          })}
        />
      </TableCell>
      <TableCell align="left" className={classes.borderRight}>
        <TextInput
          label="Code"
          id={`referenceData.${index}.code`}
          error={getError('code') !== undefined}
          helperText={getError('code')?.message}
          {...register(`referenceData.${index}.code`, {
            required: { value: true, message: 'Must not be empty' },
            pattern: isNew
              ? {
                  value: ResourceKeyRegex,
                  message: 'Invalid Key',
                }
              : undefined,
          })}
          disabled={!isNew}
        />
      </TableCell>
      <TableCell align="center" className={cx(classes.borderRight, classes.checkboxCell)}>
        <SingleCheckbox
          label="Stop condition"
          displayLabel={false}
          control={control}
          name={`referenceData.${index}.stopCondition`}
        />
        <When condition={stopConditionWatch === true}>
          <div>
            <Controller
              control={control}
              name={`referenceData.${index}.stopConditionText`}
              rules={{ required: { value: stopConditionWatch, message: 'Must not be empty' } }}
              render={({ field: { onChange, value } }) => (
                <RichText
                  defaultValue={value}
                  error={getError('stopConditionText') !== undefined}
                  helperText={getError('stopConditionText')?.message}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </When>
      </TableCell>
      <TableCell align="left" className={classes.borderRight}>
        <TextInput
          label="Filter groups"
          id={`referenceData.${index}.filterGroups`}
          error={getError('filterGroups') !== undefined}
          helperText={getError('filterGroups')?.message}
          {...register(`referenceData.${index}.filterGroups`)}
          type="text"
        />
      </TableCell>
      <TableCell align="center" className={cx(classes.checkboxCell, classes.borderRight)}>
        <SingleCheckbox
          label="Exclusive"
          displayLabel={false}
          control={control}
          name={`referenceData.${index}.optionIsExclusive`}
        />
      </TableCell>
      <TableCell align="center" className={cx(classes.checkboxCell, classes.borderRight)}>
        <SingleCheckbox
          label="Deprecated"
          displayLabel={false}
          control={control}
          name={`referenceData.${index}.deprecated`}
        />
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles()(() => ({
  borderRight: {
    borderRight: '1px solid #ccc',
    paddingTop: 0,
    paddingBottom: 0,
  },

  checkboxCell: {
    width: '50px',
  },
}));

export default ReferenceDataPageRow;
