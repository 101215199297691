import { GeoLocatorIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = ['string'];

export const geoLocatorControl: ControlDefinition = {
  displayName: 'Geolocator',
  icon: GeoLocatorIcon,
  type: 'GEOLOCATOR',
  supportedDataTypes: dataTypes,
  supportsInitialValue: false,
};
