import { makeStyles } from 'tss-react/mui';
import { When } from '../When/When';

interface Props {
  imageBase64: string;
  maxHeight?: number;
}

interface StylesProps {
  maxHeight: number;
}

const useStyles = makeStyles<StylesProps>()((_, { maxHeight }) => ({
  img: {
    maxWidth: '100%',
    maxHeight: maxHeight,
  },
}));

const ImagePreview = ({ imageBase64, maxHeight = 250 }: Props) => {
  const { classes: styles } = useStyles({ maxHeight });

  return (
    <When condition={imageBase64 !== ''}>
      <img className={styles.img} alt="preview" src={imageBase64} />
    </When>
  );
};

export default ImagePreview;
