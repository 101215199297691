import { advancedAnnotatedImageControl } from './AdvancedAnnotatedImage';
import { annotatedImageControl } from './AnnotatedImage';
import { calculatedValueControl } from './CalculatedValue';
import { checkBoxesControl } from './Checkboxes';
import { dateControl } from './Date';
import { dateTimeControl } from './DateTime';
import { dropdownControl } from './Dropdown';
import { dynamicDropdownControl } from './DynamicDropdown';
import { emailControl } from './Email';
import { explanatoryTextControl } from './ExplanatoryText';
import { geoLocatorControl } from './GeoLocator';
import { imageSelectControl } from './ImageSelect';
import { imageUploadControl } from './ImageUpload';
import { lazyDropdownControl } from './LazyDropdown';
import { numberControl } from './Number';
import { partialDateControl } from './PartialDate';
import { passwordControl } from './Password';
import { plainTextAreaControl } from './PlainTextArea';
import { radioButtonsControl } from './RadioButtons';
import { richtextControl } from './RichText';
import { richTextSelectControl } from './RichTextSelect';
import { scoreControl } from './Score';
import { scribbleControl } from './Scribble';
import { serverSideCalculationControl } from './ServerSideCalculation';
import { signatureControl } from './Signature';
import { sliderControl } from './Slider';
import { splitDateControl } from './SplitDate';
import { staticImageControl } from './StaticImage';
import { textControl } from './Text';
import { timeControl } from './Time';
import { yesNoControl } from './YesNo';
import { youtubeControl } from './Youtube';
import { ControlType } from './types';

export const allControls = [
  annotatedImageControl,
  calculatedValueControl,
  checkBoxesControl,
  dateControl,
  dateTimeControl,
  dropdownControl,
  dynamicDropdownControl,
  emailControl,
  explanatoryTextControl,
  geoLocatorControl,
  imageSelectControl,
  imageUploadControl,
  lazyDropdownControl,
  numberControl,
  passwordControl,
  plainTextAreaControl,
  radioButtonsControl,
  richtextControl,
  richTextSelectControl,
  scoreControl,
  scribbleControl,
  serverSideCalculationControl,
  signatureControl,
  sliderControl,
  splitDateControl,
  staticImageControl,
  textControl,
  timeControl,
  yesNoControl,
  youtubeControl,
  advancedAnnotatedImageControl,
  partialDateControl,
] as const;

export const getControlDefinitionByType = (type: ControlType) => {
  const control = allControls.find((c) => c.type === type);
  if (control) {
    return control;
  }

  throw new Error(`Control: ${type} has not been correctly registered`);
};
