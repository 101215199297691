import { ImageUpload, TextInput } from '@airelogic/form-management/components';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { AdditionalSettings } from '../../../Controls/StaticImage';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';
import OmitFromSubmission from './OmitFromSubmission';

interface Props {
  fieldIndex: number;
}

const StaticImageAdditionalSettings = ({ fieldIndex }: Props) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<FormValues>();

  const getErrors = () => {
    return errors?.controlSettings?.[fieldIndex]?.additionalSettings as
      | FieldErrors<AdditionalSettings>
      | undefined;
  };

  return (
    <PanelWithHeader title="Additional Settings">
      <Controller
        control={control}
        name={`controlSettings.${fieldIndex}.additionalSettings.imageBase64`}
        render={({ field: { onChange, value } }) => (
          <ImageUpload
            onDelete={() => onChange('')}
            value={value || ''}
            onChange={onChange}
            error={getErrors()?.imageBase64 !== undefined}
            helperText={getErrors()?.imageBase64?.message}
            required={true}
            data-testid="image-upload"
            label="Image"
          />
        )}
      />
      <TextInput
        type="text"
        label="Image description"
        {...register(`controlSettings.${fieldIndex}.additionalSettings.altText`)}
      />
      <OmitFromSubmission fieldIndex={fieldIndex} />
    </PanelWithHeader>
  );
};

export default StaticImageAdditionalSettings;
