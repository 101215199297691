import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, IconButtonProps, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  menuButton: {
    color: '#33444E',
    '&:hover': {
      color: 'black',
    },
  },
}));

type Props = Omit<IconButtonProps, 'component' | 'className' | 'children'>;

const MenuIcon = (props: Props) => {
  const { classes } = useStyles();

  return (
    <IconButton component="span" className={classes.menuButton} {...props}>
      <MoreVertIcon />
    </IconButton>
  );
};

export default MenuIcon;
