import { IconButton } from '@airelogic/form-management/components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FieldArray, FieldArrayPath, FieldArrayWithId, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { ControlType } from '../../../Controls/types';
import { ControlSettings, FormValues } from '../../../Form';
import PropertiesResolver from './Properties/PropertiesResolver';
import ReferenceDataTableRow from './ReferenceDataTableRow';

const useStyles = makeStyles()(() => ({
  border: {
    border: '1px solid #ccc',
    borderRadius: 4,
  },

  borderRight: {
    borderRight: '1px solid #ccc',
  },

  opsCell: {
    width: '3%',
  },

  headerRow: {
    backgroundColor: '#DBE0E4',
  },

  table: {
    height: 'auto',
    overflowY: 'auto',
    border: '1px solid #ccc',
    borderRadius: 4,
  },
}));

interface Props<
  TFieldArrayName extends FieldArrayPath<ControlSettings> = FieldArrayPath<ControlSettings>,
> {
  controlFieldIndex: number;
  controlType: ControlType;
  remove(index: number): void;
  addChoice(): void;
  move(fromIndex: number, toIndex: number): void;
  insert(
    index: number,
    object:
      | FieldArray<ControlSettings, TFieldArrayName>
      | FieldArray<ControlSettings, TFieldArrayName>[],
  ): void;
  fields: FieldArrayWithId<ControlSettings, TFieldArrayName>[];
}

const ReferenceDataTable = ({
  controlFieldIndex,
  controlType,
  remove,
  addChoice,
  move,
  insert,
  fields,
}: Props) => {
  const { classes, cx } = useStyles();

  const { watch } = useFormContext<FormValues>();
  const multiSelectWatch =
    watch(`controlSettings.${controlFieldIndex}.additionalSettings.multiSelect`) ?? false;

  const tableFields = PropertiesResolver(controlType, multiSelectWatch);

  return (
    <TableContainer className={classes.table}>
      <Table stickyHeader aria-label="choices-table">
        <TableHead>
          <TableRow key="header" className={classes.headerRow}>
            <TableCell key="add-choice" className={cx(classes.opsCell, classes.borderRight)}>
              <IconButton onClick={addChoice} tooltipText="Add choice">
                <AddCircleIcon />
              </IconButton>
            </TableCell>
            {tableFields.map((field: string) => {
              return (
                <TableCell size="small" key={field} align="left" className={classes.borderRight}>
                  {field}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((field, index: number) => {
            return (
              <ReferenceDataTableRow
                key={field.id}
                controlFieldIndex={controlFieldIndex}
                itemsLength={fields.length}
                itemFieldIndex={index}
                onRemove={remove}
                onAdd={addChoice}
                onInsert={insert}
                onMove={move}
                controlType={controlType}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReferenceDataTable;
