import { MustNotBeEmptyText } from '@airelogic/form-management/common/validations';
import { z } from 'zod';
import { AnnotatedImageIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

export const validationSchema = z
  .object({
    allowUserUpload: z.boolean(),
    imageBase64: z.string(),
  })
  .superRefine((val, ctx) => {
    if (val.allowUserUpload === false && val.imageBase64.trim() === '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['imageBase64'],
        message: MustNotBeEmptyText,
      });
    }
  });

const dataTypes: DataType[] = ['base64Binary'];

export type AdditionalSettings = z.infer<typeof validationSchema>;

export const annotatedImageControl: ControlDefinition<AdditionalSettings> = {
  displayName: 'Annotated Image',
  icon: AnnotatedImageIcon,
  type: 'ANNOTATED_IMAGE',
  supportedDataTypes: dataTypes,
  formulaOverrides: {
    allowCalculatedValue: false,
    allowInitialValue: false,
    allowReadOnly: false,
  },
  additionalSettings: {
    validationSchema,
    defaults: {
      allowUserUpload: false,
      imageBase64: '',
    },
  },
  supportsInitialValue: false,
};
