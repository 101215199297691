import { SingleCheckbox, TextInput, When } from '@airelogic/form-management/components';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '../../Form';
import ExtendableContent from '../PanelWithHeader';
import { useNameValidationDeps } from '../useNameValidationDeps';

interface Props {
  fieldIndex: number;
}

const ContentSettings = ({ fieldIndex }: Props) => {
  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<FormValues>();

  const nameValidationDeps = useNameValidationDeps();
  const useCustomIterationName = watch(
    `repeatSettings.${fieldIndex}.contentSettings.useCustomIterationName`,
  );

  const numberOrNull = (value: string) => {
    return value === null || value === '' ? null : +value;
  };

  useEffect(() => {
    if (!useCustomIterationName) {
      setValue(`repeatSettings.${fieldIndex}.contentSettings.customIterationName`, '');
    }
  }, [useCustomIterationName]);

  return (
    <ExtendableContent title="Content Settings">
      <TextInput
        label="Minimum iterations"
        type={'number'}
        error={!!errors.repeatSettings?.[fieldIndex]?.contentSettings?.minimumRepetitions}
        helperText={
          errors.repeatSettings?.[fieldIndex]?.contentSettings?.minimumRepetitions?.message
        }
        {...register(`repeatSettings.${fieldIndex}.contentSettings.minimumRepetitions`, {
          setValueAs: numberOrNull,
        })}
      />
      <TextInput
        label="Maximum iterations"
        type={'number'}
        error={!!errors.repeatSettings?.[fieldIndex]?.contentSettings?.maximumRepetitions}
        helperText={
          errors.repeatSettings?.[fieldIndex]?.contentSettings?.maximumRepetitions?.message
        }
        {...register(`repeatSettings.${fieldIndex}.contentSettings.maximumRepetitions`, {
          setValueAs: numberOrNull,
        })}
      />
      <TextInput
        required
        label="No items message"
        error={!!errors.repeatSettings?.[fieldIndex]?.contentSettings?.noItemsMessage}
        helperText={errors.repeatSettings?.[fieldIndex]?.contentSettings?.noItemsMessage?.message}
        {...register(`repeatSettings.${fieldIndex}.contentSettings.noItemsMessage`)}
      />
      <SingleCheckbox
        label="Use custom iteration name"
        control={control}
        name={`repeatSettings.${fieldIndex}.contentSettings.useCustomIterationName`}
      />
      <When condition={useCustomIterationName}>
        <TextInput
          label="Custom iteration name"
          error={!!errors.repeatSettings?.[fieldIndex]?.contentSettings?.customIterationName}
          helperText={
            errors.repeatSettings?.[fieldIndex]?.contentSettings?.customIterationName?.message
          }
          {...register(`repeatSettings.${fieldIndex}.contentSettings.customIterationName`, {
            deps: nameValidationDeps,
          })}
        />
      </When>
    </ExtendableContent>
  );
};

export default ContentSettings;
