import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    borderLeft: '7px solid #ffffff00',
    marginBottom: theme.spacing(2),
  },

  blockError: {
    borderLeftColor: theme.palette.error.main,
  },
}));
