import { Checkbox, FormControlLabel, Theme, Typography } from '@mui/material';
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { SetRequired } from 'type-fest';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends SetRequired<UseControllerProps<TFieldValues, TName>, 'control' | 'name'> {
  label: string;
  displayLabel?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  noMargin: {
    margin: '0px',
  },
}));

const SingleCheckbox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: Props<TFieldValues, TName>,
) => {
  const { label, displayLabel = true, ...rest } = props;
  const { classes } = useStyles();

  return (
    <FormControlLabel
      control={
        <Controller
          {...rest}
          render={({ field }) => (
            <Checkbox
              color="primary"
              disableRipple
              inputProps={{
                'aria-label': displayLabel ? undefined : label,
              }}
              {...field}
              onChange={(e) => field.onChange(e.target.checked)}
              checked={field.value}
            />
          )}
        />
      }
      label={<Typography variant="body1">{displayLabel ? label : undefined}</Typography>}
      className={displayLabel ? '' : classes.noMargin}
    />
  );
};

export default SingleCheckbox;
