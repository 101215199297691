import { ControlType } from '../../../../Controls/types';
import {
  CheckboxProperties,
  DropdownMultiselectProperties,
  DropdownProperties,
  ImageSelectProperties,
  RadioButtonsMultiSelectProperties,
  RadioButtonsProperties,
  RichTextSelectProperties,
} from './Properties';

const PropertiesResolver = (controlType: ControlType, multiSelect: boolean) => {
  switch (controlType) {
    case 'IMAGE_SELECT':
      return ImageSelectProperties;
    case 'DROPDOWN':
      if (multiSelect) {
        return DropdownMultiselectProperties;
      }
      return DropdownProperties;
    case 'RADIO_BUTTON':
      if (multiSelect) {
        return RadioButtonsMultiSelectProperties;
      }
      return RadioButtonsProperties;
    case 'RICH_TEXT_SELECT':
      return RichTextSelectProperties;
    case 'CHECKBOX':
      return CheckboxProperties;
    default:
      throw Error(`${controlType} not supported.`);
  }
};

export default PropertiesResolver;
