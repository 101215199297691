import { AbilityBuilder, MongoAbility, createMongoAbility } from '@casl/ability';
import { AuthenticatedUser } from '../Authentication';

export type ManagementAbilities =
  | ['preview' | 'create' | 'publish' | 'changeDeliveryStatus', 'Form']
  | ['create', 'BuildingBlock']
  | ['create', 'ReferenceDataSet'];

export type FormManagementAbility = MongoAbility<ManagementAbilities>;

export const defineAbilitiesForUser = (user?: AuthenticatedUser) => {
  const { can, build } = new AbilityBuilder<FormManagementAbility>(createMongoAbility);

  if (!user) {
    return build();
  }

  for (const activity of user.activities) {
    switch (activity) {
      case 'Manage:Form':
        can('create', 'Form');
        can('preview', 'Form');
        can('create', 'BuildingBlock');
        can('create', 'ReferenceDataSet');
        break;
      case 'Preview:Form':
        can('preview', 'Form');
        break;
      case 'Edit:FormDeliveryStatus':
        can('changeDeliveryStatus', 'Form');
        break;
    }
  }

  return build();
};
