import { createSelector } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';
import {
  canDeleteSection,
  getAllSectionIds,
  getLocalState,
  getSelectedSection,
  isSelectedSection,
} from '../localSelectors';

export const selectAllSectionIds = createSelector([getLocalState], getAllSectionIds, {
  memoizeOptions: {
    resultEqualityCheck: shallowEqual,
  },
});

export const selectCanDeleteSection = createSelector(getLocalState, canDeleteSection);

export const selectSelectedSection = createSelector(getLocalState, getSelectedSection);

export const makeIsSelectedSection = () =>
  createSelector([getLocalState, (_, id: string) => id], isSelectedSection);
