import {
  CreateNewBuildingBlock,
  EditExistingBuildingBlock,
  store,
} from '@airelogic/form-management/buildingblock-designer';
import { AuthorisedRoute } from '@airelogic/form-management/common';
import { Provider } from 'react-redux';
import { Route, useRouteMatch } from 'react-router';
import Switch from '../../ErrorHandlingSwitch';
import BuildingBlocks from './Page';

const Routing = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} exact>
        <BuildingBlocks />
      </Route>
      <Provider store={store}>
        <AuthorisedRoute
          exact
          path={`${match.path}/designer/new`}
          can={{ I: 'create', a: 'BuildingBlock' }}
        >
          <CreateNewBuildingBlock />
        </AuthorisedRoute>
        <AuthorisedRoute
          path={`${match.path}/designer/edit/:orbeonId/`}
          can={{ I: 'create', a: 'BuildingBlock' }}
        >
          <EditExistingBuildingBlock />
        </AuthorisedRoute>
      </Provider>
    </Switch>
  );
};

export default Routing;
