import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useCallback } from 'react';
import { FormValues } from '../Form';
import { transform } from '../SaveService/transform';
import { IState } from '../StateManagement/types';

type Data = {
  state: IState;
  settings: FormValues;
};

const useBlockPreviewService = () => {
  const { mutateAsync } = useMutation({
    mutationFn: async (data: Data) => {
      try {
        const response = await axios.post(
          '/internalapi/formbuilding/buildingblocks/previewBlock',
          transform('preview-block', data.state, data.settings),
        );
        return response.status === 200;
      } catch {
        return false;
      }
    },
  });

  return useCallback(async (data: Data) => mutateAsync(data), [mutateAsync]);
};

export { useBlockPreviewService };
