import { createSelector } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';
import {
  canAddColumnToGrid,
  canDeleteColumnInGrid,
  canDeleteGrid,
  canDeleteRowInGrid,
  canSplitGrid,
  getAllGrids,
  getCellsInRow,
  getGridById,
  getGridIdsInSection,
  getLocalState,
  getSelectedGrid,
  isSelectedGrid,
} from '../localSelectors';
import { RootState } from '../store';
import { Grid, IControl } from '../types';
import { selectAllControls } from './ControlSelector';

const selectGridById = createSelector(
  [getLocalState, (_: RootState, gridId: string) => gridId],
  getGridById,
);

export const makeSelectColumnsForGrid = () =>
  createSelector([selectGridById], (grid) => grid.columnIds);

export const makeSelectRowsForGrid = () => createSelector([selectGridById], (grid) => grid.rowIds);

export const makeCanDeleteRowInGrid = () => createSelector([selectGridById], canDeleteRowInGrid);

export const makeCanAddColumnToGrid = () => createSelector([selectGridById], canAddColumnToGrid);

export const makeCanDeleteColumnInGrid = () =>
  createSelector([selectGridById], canDeleteColumnInGrid);

const selectAllGrids = createSelector(getLocalState, getAllGrids);

export const makeSelectControlCountInSection = () =>
  createSelector(
    [selectAllGrids, selectAllControls, (_: RootState, sectionId: string) => sectionId],
    (grids, components, sectionId) =>
      grids
        .filter((g) => g.sectionId === sectionId)
        .reduce(
          (runningTotal, gridId) => runningTotal + selectControlCountInGrid(gridId, components),
          0,
        ),
  );

export const makeSelectCellsInRow = () =>
  createSelector(
    [
      getLocalState,
      (state: RootState, gridId: string) => gridId,
      (state: RootState, gridId: string, rowId: string) => rowId,
    ],
    (state, gridId, rowId) => getCellsInRow(state, gridId, rowId),
  );

export const makeSelectOrderedGridsInSection = () =>
  createSelector(
    [getLocalState, (_: RootState, sectionId: string) => sectionId],
    getGridIdsInSection,
    {
      memoizeOptions: {
        resultEqualityCheck: shallowEqual,
      },
    },
  );

const selectComponentCountInRow = (components: IControl[], rowId: string) =>
  components.filter((c) => c.cell.rowId === rowId).length;

export const makeSelectComponentCountInRow = () =>
  createSelector(
    [selectAllControls, (_: RootState, rowId: string) => rowId],
    selectComponentCountInRow,
  );

export const makeSelectControlCountInColumn = () =>
  createSelector(
    [selectAllControls, (_: RootState, columnId: string) => columnId],
    (controls, columnId) => controls.filter((c) => c.cell.columnId === columnId).length,
  );

const selectControlCountInGrid = (grid: Grid, components: IControl[]) =>
  grid.rowIds.reduce(
    (runningTotal, rowId) => runningTotal + selectComponentCountInRow(components, rowId),
    0,
  );

export const makeSelectControlCountInGrid = () =>
  createSelector([selectGridById, selectAllControls], selectControlCountInGrid);

export const makeSelectRepeatingGridIndex = () =>
  createSelector(
    [(_: RootState, gridId: string) => gridId, selectAllRepeatingGridIds],
    (gridId, allRepeatingGridIds) => allRepeatingGridIds.findIndex((id) => id === gridId),
    {
      memoizeOptions: {
        resultEqualityCheck: shallowEqual,
      },
    },
  );

export const makeIsRepeatingGrid = () =>
  createSelector(
    [(_: RootState, gridId: string) => gridId, selectAllRepeatingGridIds],
    (gridId, allRepeatingGridIds) => allRepeatingGridIds.findIndex((id) => id === gridId) !== -1,
    {
      memoizeOptions: {
        resultEqualityCheck: shallowEqual,
      },
    },
  );

export const makeSelectCanDeleteGrid = () =>
  createSelector([getLocalState, (_: RootState, gridId: string) => gridId], canDeleteGrid);

export const selectAllRepeatingGridIds = createSelector(
  [selectAllGrids],
  (grids) => grids.filter((g) => g.isRepeated === true).map((g) => g.id),
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  },
);

export const selectSelectedRepeat = createSelector(getLocalState, getSelectedGrid);

export const makeSelectGridById = () => selectGridById;

export const makeIsSelectedGrid = () =>
  createSelector([getLocalState, (_, id: string) => id], isSelectedGrid);

export const makeSelectCanSplitGridSelector = () =>
  createSelector(
    [
      getLocalState,
      (state: RootState, gridId: string) => gridId,
      (state: RootState, gridId: string, rowId: string) => rowId,
    ],
    (state, gridId, rowId) => canSplitGrid(state, gridId, rowId),
  );
