import { Avatar, Divider, List, ListItem, ListItemAvatar, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ImageSelectInlineReferenceData } from '../../../Controls/ImageSelect';
import { FormValues } from '../../../Form';

interface Props {
  settingsIndex: number;
}

const ImageSelect = ({ settingsIndex }: Props) => {
  const { watch } = useFormContext<FormValues>();
  const items = watch(
    `controlSettings.${settingsIndex}.additionalSettings.items`,
  ) as ImageSelectInlineReferenceData[];

  const allowedLength = 50;
  const trimContent = (content: string) => {
    if (content.length > allowedLength) {
      return content.substring(0, allowedLength) + '...';
    }
    return content;
  };

  return (
    <List component="ol" disablePadding>
      {items?.map((item, index) => {
        return (
          //Use index as value could be empty when editing causing conflicting keys
          <ListItem key={index} disableGutters>
            <ListItemAvatar>
              <Avatar src={item.imageBase64} alt={`${settingsIndex}.${item.label}`} />
            </ListItemAvatar>
            <Typography variant="caption">{trimContent(item.hint)}</Typography>
            <Divider />
          </ListItem>
        );
      })}
    </List>
  );
};

export default ImageSelect;
