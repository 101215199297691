import { SplitDateIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

const dataTypes: DataType[] = ['date'];

export const partialDateControl: ControlDefinition = {
  displayName: 'Partial Date',
  icon: SplitDateIcon,
  type: 'PARTIAL_DATE',
  supportedDataTypes: dataTypes,
  supportsInitialValue: true,
};
