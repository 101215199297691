import { createContext, PropsWithChildren, useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export type AuthMode = 'oidc' | 'demo';

export interface IAppDetails {
  readonly authMode?: AuthMode;
}

const defaultAppDetails: IAppDetails = {
  authMode: 'oidc',
};

export const AppContext = createContext<IAppDetails>(defaultAppDetails);

export const useApplicationContext = () => {
  return useContext(AppContext);
};

export const ApplicationContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { data, status } = useQuery({
    queryKey: ['application-context'],
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<IAppDetails>('/internalapi/context', { signal });
      return data;
    },
  });

  if (status === 'success') {
    return (
      <AppContext.Provider
        value={{
          ...data,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }

  return null;
};
