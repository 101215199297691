import { createSlice } from '@reduxjs/toolkit';
import { controlReducers } from './Reducers/controlReducers';
import { gridReducers } from './Reducers/gridReducers';
import rehydrateReducer from './Reducers/rehydrateReducer';
import { repeatReducers } from './Reducers/repeatReducers';
import { sectionReducers } from './Reducers/sectionReducers';
import { selectionReducers } from './Reducers/selectionsReducer';
import { IState } from './types';

const initialState: IState = {
  sections: {
    allIds: [],
  },
  grids: {
    byId: {},
    allIds: [],
  },
  controls: [],
  layoutSelections: {},
};

const layoutSlice = createSlice({
  name: 'layoutReducers',
  initialState: initialState,
  reducers: {
    rehydrate: rehydrateReducer,
    ...gridReducers,
    ...controlReducers,
    ...sectionReducers,
    ...selectionReducers,
    ...repeatReducers,
  },
});

export const {
  updateSelectedComponent,
  moveSection,
  rehydrate,
  moveRepeatIteration,
  deleteRepeatIteration,
  addRepeatIteration,
  deleteColumn,
  deleteRow,
  addColumnToGrid,
  addGrid,
  addRowToGrid,
  deleteGrid,
  deleteControl,
  deleteSection,
  addSection,
  updateCellSelection,
  updateHoveredCell,
  updateClipboard,
  deselectComponent,
  clearNavigateToErrors,
  addControl,
  pasteControl,
  increaseCellSpan,
  decreaseCellSpan,
  splitGrid,
  moveGrid,
} = layoutSlice.actions;

export default layoutSlice;
