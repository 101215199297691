import {
  SelectInput,
  SingleCheckbox,
  TextInput,
  When,
} from '@airelogic/form-management/components';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { getControlDefinitionByType } from '../../../Controls/Controls';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';

interface Props {
  fieldIndex: number;
}

const DataTypeComponent = ({ fieldIndex }: Props) => {
  const { control, register, watch } = useFormContext<FormValues>();

  const controlType = watch(`controlSettings.${fieldIndex}.type`);
  const { supportedDataTypes } = getControlDefinitionByType(controlType);

  const useDefaultAlertWatch = watch(
    `controlSettings.${fieldIndex}.validationSettings.dataTypeSettings.useDefaultAlert`,
  );

  return (
    <PanelWithHeader title="Data Type">
      <When condition={supportedDataTypes.length > 1}>
        <SelectInput
          {...register(`controlSettings.${fieldIndex}.validationSettings.dataTypeSettings.type`)}
          label="Data type"
          required
        >
          {supportedDataTypes.map((dt) => (
            <option value={dt} key={dt}>
              {_.capitalize(dt)}
            </option>
          ))}
        </SelectInput>
      </When>
      <SingleCheckbox
        label="Use default alert"
        control={control}
        name={`controlSettings.${fieldIndex}.validationSettings.dataTypeSettings.useDefaultAlert`}
      />
      <When condition={!useDefaultAlertWatch}>
        <TextInput
          type="text"
          label="Custom Alert Message"
          {...register(
            `controlSettings.${fieldIndex}.validationSettings.dataTypeSettings.alertMessage`,
          )}
        />
      </When>
    </PanelWithHeader>
  );
};

export default DataTypeComponent;
