import { When } from '@airelogic/form-management/components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SyncIcon from '@mui/icons-material/Sync';
import { Box, Button, LinearProgress, Paper, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import BuildingBlock from '../BuildingBlockEdit/BuildingBlockEdit';
import {
  BuildingBlockVersionData,
  FormSchema,
  createEmptyBuildingBlock,
} from '../FormBuildingInterfaces';
import { useStyles } from './PageEdit.styles';

interface Props {
  index: number;
  buildingBlocksData: BuildingBlockVersionData[];
  refreshBlocks: () => Promise<void>;
  isRefreshingBlocks: boolean;
}

const PageEdit = ({ index, buildingBlocksData, refreshBlocks, isRefreshingBlocks }: Props) => {
  const { classes } = useStyles();
  const { control } = useFormContext<FormSchema>();
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: `pages.${index}.buildingBlocks`,
  });

  const onAppendBuildingBlock = () => {
    append(createEmptyBuildingBlock());
  };

  const onMoveBuildingBlockUp = useCallback(
    (index: number) => {
      if (fields.length > 1 && index > 0) {
        move(index, index - 1);
      }
    },
    [fields.length, move],
  );

  const onMoveBuildingBlockDown = useCallback(
    (index: number) => {
      if (fields.length > 1 && index < fields.length - 1) {
        move(index, index + 1);
      }
    },
    [fields.length, move],
  );

  const onRemoveBuildingBlock = useCallback(
    (index: number) => {
      if (fields.length > 1) {
        remove(index);
      }
    },
    [fields.length, remove],
  );

  const onRefeshBlocksClick = async () => {
    await refreshBlocks();
  };

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box display="flex" flexDirection="row">
        <Typography variant="h5" component="h2" className={classes.headerText}>
          Building Blocks
        </Typography>
        <Button
          startIcon={<SyncIcon />}
          onClick={onRefeshBlocksClick}
          color="primary"
          disabled={isRefreshingBlocks}
        >
          Refresh available blocks
        </Button>
      </Box>
      <Box className={classes.progressBarBox}>
        <When condition={isRefreshingBlocks ?? false}>
          <LinearProgress className={classes.linearProgressBar} />
        </When>
      </Box>
      <Paper elevation={0} className={classes.scrollingPaper}>
        <ol className={classes.blockList} aria-label="Building blocks">
          {fields.map((item, innerIndex) => {
            return (
              <li key={item.id}>
                <BuildingBlock
                  index={index}
                  innerIndex={innerIndex}
                  isLastBB={fields.length === 1}
                  buildingBlocksData={buildingBlocksData}
                  onMoveDown={onMoveBuildingBlockDown}
                  onMoveUp={onMoveBuildingBlockUp}
                  onRemove={onRemoveBuildingBlock}
                />
              </li>
            );
          })}
        </ol>
      </Paper>
      <Box className={classes.addBBPaper}>
        <Button startIcon={<AddCircleIcon />} onClick={onAppendBuildingBlock} color="primary">
          Add Building Block
        </Button>
      </Box>
    </Paper>
  );
};

export default PageEdit;
