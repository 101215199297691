import { AuthorisedRoute } from '@airelogic/form-management/common';
import {
  ExistingReferenceDataPage,
  NewReferenceDataPage,
  ReferenceDataList,
} from '@airelogic/form-management/referencedata';
import { useRouteMatch } from 'react-router';
import Switch from '../../ErrorHandlingSwitch';

const Routing = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <AuthorisedRoute path={`${match.path}`} exact can={{ I: 'create', a: 'ReferenceDataSet' }}>
        <ReferenceDataList />
      </AuthorisedRoute>

      <AuthorisedRoute
        path={`${match.path}/new`}
        exact
        can={{ I: 'create', a: 'ReferenceDataSet' }}
      >
        <NewReferenceDataPage />
      </AuthorisedRoute>

      <AuthorisedRoute path={`${match.path}/new/:key`} can={{ I: 'create', a: 'ReferenceDataSet' }}>
        <ExistingReferenceDataPage />
      </AuthorisedRoute>

      <AuthorisedRoute
        path={`${match.path}/edit/:key/:version`}
        can={{ I: 'create', a: 'ReferenceDataSet' }}
      >
        <ExistingReferenceDataPage />
      </AuthorisedRoute>
    </Switch>
  );
};

export default Routing;
