import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  addBBPaper: {
    minHeight: 50,
  },

  scrollingPaper: {
    paddingBottom: theme.spacing(0),
    background: '#EDF0F2',
    maxHeight: 700,
    overflow: 'auto',
    padding: theme.spacing(2),
  },

  helpIcon: {
    color: '#889AA5',
  },

  paper: {
    padding: theme.spacing(2),
  },

  headerText: {
    marginRight: 'auto',
  },

  blockList: {
    listStyle: 'none',
    paddingLeft: 0,
  },

  linearProgressBar: {
    top: '5px',
  },

  progressBarBox: {
    height: '4px',
  },
}));
