import {
  BaseballCapSwitch,
  DropdownArrow,
  InputTooltip,
  SelectInput,
  TextInput,
  When,
} from '@airelogic/form-management/components';
import { Box, FormControl, Grid2 as Grid, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormSchema, deliveryStatuses } from '../FormBuildingInterfaces';

import { Can } from '@airelogic/form-management/common/app-context';
import { generateResourceKey } from '@airelogic/form-management/common/validations';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import FormContextDialog from '../FormContext/FormContextDialog';
import { EditorMode } from '../FormEdit/FormEdit';
import DeliveryStatusTooltipContent from './DeliveryStatusTooltipContent';
import { useStyles } from './MetadataEdit.styles';

interface Props {
  editorMode: EditorMode;
  formVersion: number | undefined;
  classifications: string[];
}

const MetadataEdit = ({ editorMode, formVersion, classifications }: Props) => {
  const { classes, cx } = useStyles();
  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<FormSchema>();
  const [expanded, setExpanded] = useState<boolean>(false);

  const formDocClasses = classifications.map((c: string) => ({
    value: c,
    label: c,
    inputValue: c,
  }));

  const layoutWatch = useWatch({
    control,
    name: `metadata.layout`,
  });

  const docClassFilter = createFilterOptions<{
    label: string;
    value: string;
    inputValue: string;
  }>();

  const hasErrors = () => {
    if (
      !expanded &&
      (errors?.metadata?.classification !== undefined ||
        errors.persistenceSettings !== undefined ||
        errors.pdfSettings !== undefined ||
        errors?.metadata?.deliveryStatus !== undefined)
    ) {
      setExpanded(true);
    }
    if (
      errors.metadata !== undefined ||
      errors.persistenceSettings !== undefined ||
      errors.pdfSettings !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isExistingForm = () => {
    if (editorMode !== 'new') {
      return true;
    } else return false;
  };

  const onFormNameBlur = () => {
    const key = getValues('metadata.key');
    if (key === '' || key === undefined) {
      setValue('metadata.key', generateResourceKey(getValues('metadata.title')));
    }
  };

  return (
    <Paper
      data-testid="metadata"
      className={cx(classes.paper, { [classes.metadataError]: hasErrors() })}
      elevation={3}
    >
      <Box display="flex" flexDirection="row">
        <Typography variant="h5" component="h2" className={classes.headerText}>
          Configuration
        </Typography>
        <When condition={editorMode === 'edit'}>
          <Typography
            noWrap
            align="right"
            display="inline"
            variant="h6"
            component="h2"
            className={classes.versionText}
          >
            You are currently editing version:{' '}
            <span className={classes.versionTextBold}>{formVersion}</span>
          </Typography>
        </When>
      </Box>
      <Grid container>
        <Grid size="auto">
          <FormControl margin="dense" sx={{ marginRight: 2 }}>
            <DropdownArrow
              expanded={expanded}
              handleChange={setExpanded}
              tooltipText={'additional form configuration options'}
            />
          </FormControl>
        </Grid>
        <Grid size="grow">
          <Grid container spacing={2}>
            <Grid size="grow">
              <TextInput
                error={errors?.metadata?.title !== undefined}
                helperText={errors?.metadata?.title?.message}
                required
                {...register('metadata.title')}
                label="Form name"
                autoFocus={getValues('metadata.title') === ''}
                onBlur={onFormNameBlur}
                type="text"
              />
            </Grid>
            <Grid size="grow">
              <SelectInput
                required
                {...register('metadata.layout')}
                label="Form type"
                disabled={isExistingForm()}
                tooltip={
                  isExistingForm()
                    ? 'The type of the form cannot be changed.'
                    : 'Single-Pass forms are stateless and only designed to be used once. The user cannot save their progress and return later.' +
                      'Clinician forms are more densely presented and contain a save button; they are more geared towards use by NHS staff. '
                }
              >
                <option key={'clinician'} value={'clinician'}>
                  Clinician
                </option>
                <option key={'single-pass'} value={'single-pass'}>
                  Single-Pass
                </option>
              </SelectInput>
            </Grid>
            <Grid size="grow">
              <TextInput
                required
                error={Boolean(errors?.metadata?.key)}
                helperText={errors?.metadata?.key?.message}
                {...register('metadata.key')}
                disabled={isExistingForm()}
                label="Form key"
                type="text"
                tooltip={
                  isExistingForm()
                    ? 'The key cannot be changed once created.'
                    : 'Identifies this specific form (e.g.  "asthma-review" or "claim"). '
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size="grow">
              <Controller
                control={control}
                name={'metadata.classification'}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    freeSolo
                    onChange={(e, data) => {
                      field.onChange(data ?? { label: '', value: '', inputValue: '' });
                    }}
                    clearOnBlur
                    size="small"
                    handleHomeEndKeys
                    options={formDocClasses}
                    filterOptions={(options, params) => {
                      const filtered = docClassFilter(options, params);

                      // Suggest the creation of a new value
                      if (params.inputValue !== '') {
                        filtered.push({
                          value: params.inputValue,
                          label: `Add "${params.inputValue}"`,
                          inputValue: params.inputValue,
                        });
                      }

                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.label;
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option.label}</li>
                    )}
                    renderInput={(params) => (
                      <TextInput
                        {...params}
                        required
                        error={Boolean(errors.metadata?.classification)}
                        helperText={errors.metadata?.classification?.inputValue?.message}
                        label="Classification"
                        fullWidth={true}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputTooltip
                                className={classes.autoCompleteTooltip}
                                title={
                                  'The category this form falls into. Can be used to organise and more easily find your form.'
                                }
                              />
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Can I="changeDeliveryStatus" a="Form">
              <Grid size="grow">
                <SelectInput
                  required
                  {...register('metadata.deliveryStatus')}
                  label="Delivery status"
                  tooltip={<DeliveryStatusTooltipContent />}
                >
                  {deliveryStatuses.map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                </SelectInput>
              </Grid>
            </Can>
          </Grid>
          <When condition={expanded}>
            <Grid container spacing={2}>
              <Grid size="grow">
                <SelectInput {...register('pdfSettings.style')} label="PDF style" required>
                  <option value="none">None</option>
                  <option value="default">Default</option>
                  <option value="faxable">Faxable</option>
                </SelectInput>
              </Grid>
              <When condition={getValues('pdfSettings.style') !== 'none'}>
                <Grid size="grow">
                  <Controller
                    control={control}
                    name={'pdfSettings.displayPageTitles'}
                    render={({ field }) => (
                      <BaseballCapSwitch
                        label="PDF display page titles"
                        {...field}
                      ></BaseballCapSwitch>
                    )}
                  />
                </Grid>
                <Grid size="grow">
                  <Controller
                    control={control}
                    name={'pdfSettings.displaySectionTitles'}
                    render={({ field }) => (
                      <BaseballCapSwitch
                        label="PDF display section titles"
                        {...field}
                      ></BaseballCapSwitch>
                    )}
                  />
                </Grid>
              </When>
            </Grid>
            <When condition={layoutWatch === 'clinician'}>
              <Grid container spacing={2}>
                <Grid size="grow">
                  <Controller
                    control={control}
                    name={'persistenceSettings.allowDrafts'}
                    render={({ field }) => (
                      <BaseballCapSwitch label="Allow saving drafts" {...field}></BaseballCapSwitch>
                    )}
                  />
                </Grid>
                <Grid size="grow">
                  <Controller
                    control={control}
                    name={'persistenceSettings.allowDiscard'}
                    render={({ field }) => (
                      <BaseballCapSwitch label="Allow cancelling" {...field}></BaseballCapSwitch>
                    )}
                  />
                </Grid>
                <Grid size="grow">
                  <Controller
                    control={control}
                    name={'persistenceSettings.allowAmend'}
                    render={({ field }) => (
                      <BaseballCapSwitch label="Allow amendments" {...field}></BaseballCapSwitch>
                    )}
                  />
                </Grid>
                <Grid size="grow">
                  <TextInput
                    required
                    error={Boolean(errors.persistenceSettings?.autoSaveInterval)}
                    helperText={
                      errors.persistenceSettings?.autoSaveInterval
                        ? errors.persistenceSettings.autoSaveInterval.message
                        : ''
                    }
                    {...register('persistenceSettings.autoSaveInterval', { valueAsNumber: true })}
                    label="Auto save interval"
                    type="number"
                    tooltip={
                      'How often (in seconds) the form should autosave itself. A value of 0 will disable autosave.'
                    }
                  />
                </Grid>
              </Grid>
            </When>
          </When>
          <Grid container spacing={2}>
            <Grid size="grow">
              <FormContextDialog />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MetadataEdit;
