import { When } from '@airelogic/form-management/components';
import { ButtonBase, Theme } from '@mui/material';
import { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  AdditionalSettingsIcon,
  BasicSettingsIcon,
  FormulasIcon,
  ValidationAlertsIcon,
} from '../Assets';

export type TabKeys = 'basicSettings' | 'validation' | 'formula' | 'additionalSettings';

interface Props {
  selectedTab: TabKeys;
  onTabChange: (value: TabKeys) => void;
  displayValidationTab?: boolean;
  displayAdditionalSettingsTab?: boolean;
  tabErrors: Map<TabKeys, boolean>;
}

const HeaderTabs = ({
  displayValidationTab = false,
  displayAdditionalSettingsTab = false,
  selectedTab,
  onTabChange,
  tabErrors,
}: Props) => {
  const { classes, cx } = useStyles();

  useEffect(() => {
    if (displayAdditionalSettingsTab) return;
    if (selectedTab === 'additionalSettings') {
      onTabChange('basicSettings');
    }
  }, [selectedTab, displayAdditionalSettingsTab, onTabChange]);

  return (
    <div className={classes.headerTabs} role="tablist">
      <ButtonBase
        aria-label="basic settings"
        aria-selected={selectedTab === 'basicSettings'}
        className={cx(classes.tab, classes.tabWithicon, {
          [classes.activeTab]: selectedTab === 'basicSettings',
          [classes.tabWithError]: tabErrors.get('basicSettings'),
        })}
        onClick={() => onTabChange('basicSettings')}
      >
        <BasicSettingsIcon />
      </ButtonBase>
      <When condition={displayValidationTab}>
        <ButtonBase
          aria-label="validation"
          aria-selected={selectedTab === 'validation'}
          className={cx(classes.tab, classes.tabWithicon, {
            [classes.activeTab]: selectedTab === 'validation',
            [classes.tabWithError]: tabErrors.get('validation'),
          })}
          onClick={() => onTabChange('validation')}
        >
          <ValidationAlertsIcon />
        </ButtonBase>
      </When>
      <ButtonBase
        aria-label="formulas"
        aria-selected={selectedTab === 'formula'}
        className={cx(classes.tab, classes.tabWithicon, {
          [classes.activeTab]: selectedTab === 'formula',
          [classes.tabWithError]: tabErrors.get('formula'),
        })}
        onClick={() => onTabChange('formula')}
      >
        <FormulasIcon />
      </ButtonBase>
      <When condition={displayAdditionalSettingsTab}>
        <ButtonBase
          aria-label="additional settings"
          aria-selected={selectedTab === 'additionalSettings'}
          className={cx(classes.tab, classes.tabWithicon, {
            [classes.activeTab]: selectedTab === 'additionalSettings',
            [classes.tabWithError]: tabErrors.get('additionalSettings'),
          })}
          onClick={() => onTabChange('additionalSettings')}
        >
          <AdditionalSettingsIcon />
        </ButtonBase>
      </When>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  headerTabs: {
    display: 'flex',
    backgroundColor: 'white',
    cursor: 'pointer',
    maxHeight: 48,
  },
  tab: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    padding: 10,
  },
  activeTab: {
    backgroundColor: '#DBE0E4',
  },
  tabWithicon: {
    fill: '#768994',
  },
  tabWithError: {
    fill: theme.palette.error.main,
  },
}));

export default HeaderTabs;
