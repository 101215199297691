import { SingleCheckbox } from '@airelogic/form-management/components';
import { Alert, Link } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '../../../Form';
import PanelWithHeader from '../../PanelWithHeader';

interface Props {
  fieldIndex: number;
}

const AutotranslateOptions = ({ fieldIndex }: Props) => {
  const { control } = useFormContext<FormValues>();

  return (
    <PanelWithHeader title="Additional Settings">
      <SingleCheckbox
        label="Auto translate"
        control={control}
        name={`controlSettings.${fieldIndex}.additionalSettings.translatable`}
      />
      <Alert severity="info">
        When enabled, the text entered into this control will be translated by Google translate upon
        submission. You can view their data usage FAQ{' '}
        <Link
          href="https://cloud.google.com/translate/data-usage"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </Link>
        . This functionality will only work on single pass forms.
        <br></br>
        <b>
          To prevent disruptions, activating or deactivating this option mandates updating the
          version of the current building block in use.
        </b>
      </Alert>
    </PanelWithHeader>
  );
};

export default AutotranslateOptions;
