import { createPreviewUrl } from '@airelogic/form-management/common';
import { Can } from '@airelogic/form-management/common/app-context';
import { EditIcon, NewVersionIcon, PreviewIcon, When } from '@airelogic/form-management/components';
import { Box } from '@mui/material';
import { useHistory } from 'react-router';
import { ExistingForm } from './ExistingForm';

const Actions = ({
  existingForm,
  isLatestVersion,
}: {
  existingForm: ExistingForm;
  isLatestVersion: boolean;
}) => {
  const history = useHistory();

  const handleEditClick = () => {
    history.push(`/formbuilding/forms/edit/${existingForm.key}/${existingForm.version}`);
  };

  const handleNewVersionClick = () => {
    history.push(`/formbuilding/forms/newversion/${existingForm.key}`);
  };

  const handlePreviewClick = () => {
    const previewLink = createPreviewUrl(
      existingForm.layout,
      existingForm.key,
      existingForm.version,
    );
    window.open(previewLink, '_blank');
  };
  return (
    <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
      <Can I="preview" a="Form">
        <PreviewIcon
          aria-label={`Preview ${existingForm.key} at version ${existingForm.version}`}
          tooltipText={`Preview '${existingForm.title}' version ${existingForm.version}`}
          onClick={handlePreviewClick}
        />
      </Can>
      <Can I="create" a="Form">
        <EditIcon
          aria-label={`Edit ${existingForm.key} at version ${existingForm.version}`}
          tooltipText={`Edit version ${existingForm.version}`}
          onClick={handleEditClick}
        />
        <When condition={isLatestVersion}>
          <NewVersionIcon
            aria-label={`Create new version of ${existingForm.key} from version ${existingForm.version}`}
            tooltipText={'Create new version'}
            onClick={handleNewVersionClick}
          />
        </When>
      </Can>
    </Box>
  );
};

export default Actions;
