import { TextInput } from '@airelogic/form-management/components';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '../../Form';
import ExtendableContent from '../PanelWithHeader';

interface Props {
  fieldIndex: number;
}

const SectionFormulas = ({ fieldIndex }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormValues>();

  return (
    <ExtendableContent title="Formulas">
      <TextInput
        type="text"
        label="Visibility"
        multiline={true}
        minRows={3}
        error={errors?.sectionSettings?.[fieldIndex]?.formulaSettings?.visibility !== undefined}
        helperText={
          errors?.sectionSettings?.[fieldIndex]?.formulaSettings?.visibility?.message ??
          'Boolean XPath Expression'
        }
        {...register(`sectionSettings.${fieldIndex}.formulaSettings.visibility`)}
      />
      <TextInput
        type="text"
        label="Read-Only"
        multiline={true}
        minRows={3}
        error={errors?.sectionSettings?.[fieldIndex]?.formulaSettings?.readOnly !== undefined}
        helperText={
          errors?.sectionSettings?.[fieldIndex]?.formulaSettings?.readOnly?.message ??
          'Boolean XPath Expression'
        }
        {...register(`sectionSettings.${fieldIndex}.formulaSettings.readOnly`)}
      />
    </ExtendableContent>
  );
};

export default SectionFormulas;
