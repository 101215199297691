import { optionalXPathExpressionSchema, xmlNameSchema } from '@airelogic/form-management/common';
import { z } from 'zod';
import { NewSectionIcon } from '../Assets/index';
import { FormStructureDefintition } from './types';

export const validationSchema = z
  .object({
    basicSettings: z.object({
      name: xmlNameSchema(),
      originalName: xmlNameSchema(),
      label: z.string(),
      visibilityOptions: z.object({
        hideFromPdf: z.boolean(),
        hideFromRead: z.boolean(),
        hideFromEdit: z.boolean(),
      }),
    }),
    formulaSettings: z.object({
      visibility: optionalXPathExpressionSchema,
      readOnly: optionalXPathExpressionSchema,
    }),
    displaySettings: z.object({
      sectionHighlighting: z.enum([
        '',
        'form-section-highlight',
        'form-section-warning',
        'form-section-success',
        'form-section-danger',
      ]),
    }),
  })
  .strict();

export type SectionSettings = z.infer<typeof validationSchema>;

export type SectionHighlightOption = z.infer<
  typeof validationSchema.shape.displaySettings.shape.sectionHighlighting
>;

export const sectionSettingsGenerator = (defaultName: string): SectionSettings => ({
  basicSettings: {
    name: defaultName,
    originalName: defaultName,
    label: 'Untitled Section',
    visibilityOptions: {
      hideFromPdf: false,
      hideFromRead: false,
      hideFromEdit: false,
    },
  },
  formulaSettings: {
    visibility: '',
    readOnly: '',
  },
  displaySettings: {
    sectionHighlighting: '',
  },
});

export const section: FormStructureDefintition = {
  displayName: 'Section',
  icon: NewSectionIcon,
  type: 'SECTION',
};
