import PropertiesPanel from '../PropertiesBar/PropertiesPanel';
import ControlSettingsPane from './ControlSettings/ControlSettingsPane';
import RepeatSettingsPane from './RepeatSettings/SettingsPane';
import SectionSettingsPane from './SectionSettings/SectionSettingsPane';

const SettingsPane = () => {
  return (
    <>
      <SectionSettingsPane />
      <ControlSettingsPane />
      <RepeatSettingsPane />
      <PropertiesPanel />
    </>
  );
};

export default SettingsPane;
