import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ExistingForm } from './ExistingForm';

export const queryKeys = {
  root: ['forms-table'] as const,
  previousVersions: () => [...queryKeys.root, 'previous-versions'] as const,
  previousVersion: (formKey: string) => [...queryKeys.previousVersions(), formKey] as const,
};

export const useGetForms = () => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: queryKeys.root,
    queryFn: async ({ signal }) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.previousVersions() });
      const { data } = await axios.get<ExistingForm[]>('/internalapi/formbuilding/forms', {
        signal,
        params: {
          'api-version': 2,
        },
      });

      return data;
    },
  });
};

export const useGetPreviousVersions = ({ formKey }: { formKey: string }) => {
  return useQuery({
    queryKey: queryKeys.previousVersion(formKey),
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<ExistingForm[]>(
        `/internalapi/formbuilding/forms/${formKey}/previous-versions`,
        {
          signal,
          params: {
            'api-version': 2,
          },
        },
      );
      return data;
    },
    staleTime: 2 * 60 * 1000,
    gcTime: 2 * 60 * 1000,
  });
};
