import { FieldArray, FieldArrayPath } from 'react-hook-form';
import { ControlType } from '../../../Controls/types';
import { FormValues } from '../../../Form';
import CheckboxRow from './Checkbox/CheckboxRow';
import BasicRow from './Dropdown-RadioButtons/BasicRow';
import ImageSelectRow from './ImageSelect/ImageSelectRow';
import RichTextSelectRow from './RichTextSelect/RichTextSelectRow';

interface Props<TFieldArrayName extends FieldArrayPath<FormValues> = FieldArrayPath<FormValues>> {
  controlFieldIndex: number;
  itemsLength: number;
  itemFieldIndex: number;
  onRemove(index: number): void;
  onAdd(): void;
  onMove(fromIndex: number, toIndex: number): void;
  onInsert(
    index: number,
    object: FieldArray<FormValues, TFieldArrayName> | FieldArray<FormValues, TFieldArrayName>[],
  ): void;
  controlType: ControlType;
}

const ReferenceDataTableRow = (props: Props) => {
  switch (props.controlType) {
    case 'DROPDOWN':
    case 'RADIO_BUTTON':
      return <BasicRow {...props} />;
    case 'IMAGE_SELECT':
      return <ImageSelectRow {...props} />;
    case 'RICH_TEXT_SELECT':
      return <RichTextSelectRow {...props} />;
    case 'CHECKBOX':
      return <CheckboxRow {...props} />;
    default:
      throw new Error(`Unsupport control type: ${props.controlType} `);
  }
};

export default ReferenceDataTableRow;
