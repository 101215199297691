import { createMongoAbility } from '@casl/ability';
import { createContextualCan, useAbility } from '@casl/react';
import { PropsWithChildren, createContext } from 'react';
import { useAuth } from '../Authentication';
import { FormManagementAbility, defineAbilitiesForUser } from './AbilityBuilder';

export const AbilityContext = createContext<FormManagementAbility>(createMongoAbility());

const Can = createContextualCan(AbilityContext.Consumer);

export const useAbilityContext = () => {
  return useAbility(AbilityContext);
};

const UserAbilityProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { user } = useAuth();

  return (
    <AbilityContext.Provider value={defineAbilitiesForUser(user)}>
      {children}
    </AbilityContext.Provider>
  );
};

export { Can, UserAbilityProvider };
