import { ErrorTemplate } from '@airelogic/form-management/common';

interface IProps {
  message: string;
}

const PreviewErrorPage = ({ message }: IProps) => {
  return <ErrorTemplate header={'There was an issue generating your form.'} message={message} />;
};

export default PreviewErrorPage;
