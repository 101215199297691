import { FieldPath } from 'react-hook-form';
import { useControlSettingsContext } from '../ControlSettingsContext';
import { FormValues } from '../Form';
import { useRepeatSettingsContext } from '../RepeatSettingsContext';
import { useSectionSettingsContext } from '../SectionSettingsContext';

export const useNameValidationDeps = (): FieldPath<FormValues>[] => {
  const sectionSettings = useSectionSettingsContext();
  const controlSettings = useControlSettingsContext();
  const { fields: repeatSettings } = useRepeatSettingsContext();

  return [
    ...(sectionSettings.map(
      (_, index) => `sectionSettings.${index}.basicSettings.name`,
    ) as FieldPath<FormValues>[]),
    ...(controlSettings.map(
      (_, index) => `controlSettings.${index}.basicSettings.name`,
    ) as FieldPath<FormValues>[]),
    ...repeatSettings.flatMap(
      (_, index) =>
        [
          `repeatSettings.${index}.basicSettings.name`,
          `repeatSettings.${index}.contentSettings.customIterationName`,
        ] as FieldPath<FormValues>[],
    ),
  ];
};
