import { ImagePreview } from '@airelogic/form-management/components';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '../../../Form';

interface Props {
  settingsIndex: number;
}

const StaticImage = ({ settingsIndex }: Props) => {
  const { watch } = useFormContext<FormValues>();
  const imageBase64 = watch(`controlSettings.${settingsIndex}.additionalSettings.imageBase64`)!;

  return <ImagePreview imageBase64={imageBase64} />;
};

export default StaticImage;
