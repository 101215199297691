import { IconButton } from '@airelogic/form-management/components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FieldArray, FieldArrayPath, FieldArrayWithId } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { RefDataFormValues } from '../Types';
import ReferenceDataPageRow from './ReferenceDataPageRow';

interface Props<
  TFieldArrayName extends FieldArrayPath<RefDataFormValues> = FieldArrayPath<RefDataFormValues>,
> {
  fields: FieldArrayWithId<RefDataFormValues, TFieldArrayName>[];
  remove(index: number): void;
  addItem(): void;
  move(fromIndex: number, toIndex: number): void;
  insert(
    index: number,
    object:
      | FieldArray<RefDataFormValues, TFieldArrayName>
      | FieldArray<RefDataFormValues, TFieldArrayName>[],
  ): void;
}

const tableFields = [
  'Label',
  'Value',
  'Is Stop Condition',
  'Filter groups',
  'Option is exclusive',
  'Deprecated',
];

const ReferenceDataPageTable = ({ fields, remove, addItem, insert, move }: Props) => {
  const { classes, cx } = useStyles();

  return (
    <TableContainer className={classes.table}>
      <Table stickyHeader aria-label="choices-table">
        <TableHead>
          <TableRow key="header" className={classes.headerRow}>
            <TableCell key="add-choice" className={cx(classes.opsCell, classes.borderRight)}>
              <IconButton onClick={() => addItem()} tooltipText="Add choice">
                <AddCircleIcon />
              </IconButton>
            </TableCell>
            {tableFields.map((field: string) => {
              return (
                <TableCell size="small" key={field} align="left" className={classes.borderRight}>
                  {field}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((field, index: number) => {
            return (
              <ReferenceDataPageRow
                key={field.id}
                itemsLength={fields.length}
                index={index}
                onRemove={remove}
                onAdd={addItem}
                onInsert={insert}
                onMove={move}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReferenceDataPageTable;

const useStyles = makeStyles()(() => ({
  border: {
    border: '1px solid #ccc',
    borderRadius: 4,
  },

  borderRight: {
    borderRight: '1px solid #ccc',
  },

  opsCell: {
    width: '3%',
  },

  headerRow: {
    backgroundColor: '#DBE0E4',
  },

  table: {
    height: 'auto',
    maxHeight: '60vh',
    overflowY: 'auto',
    border: '1px solid #ccc',
    borderRadius: 4,
  },
}));
